import * as React from "react";
import { Switch, Route } from "react-router-dom";
import Welcome from "./Welcome/Welcome";
import ValorAtual from "./ValorAtual/ValorAtual";
import ValorHistorico from "./ValorHistorico/ValorHistorico";
import ValoresHistoricos from "./ValoresHistoricos/ValoresHistoricos";
import ConfiguracoesConexao from "./ConfiguracoesConexao/ConfiguracoesConexao";
import Progress from "../shared/components/Progress/Progress";
import { useEffect } from "react";
import { criarTodasAsTabelasDePersistenciaDeDados } from "../utils/persistirDadosSheet";
import RecursoIndisponivel from "../shared/components/RecursoIndisponivel/RecursoIndisponivel";
import AtualizarPlanilha from "./AtualizarPlanilha/AtualizarPlanilha";
import { valorAtualValidarDadosEMapearResultados } from "./Funcionalidades/Validacoes/ValorAtualValidarDadosEMapearResultados";
import { aplicarValorAtual } from "./Funcionalidades/AplicarValorPlanilha/AplicarValorAtual";
import { valorHistoricoValidarDadosEMapearResultados } from "./Funcionalidades/Validacoes/ValorHistoricoValidarDadosEMapearResultados";
import { aplicarValorHistorico } from "./Funcionalidades/AplicarValorPlanilha/AplicarValorHistorico";
import { ILocalDadosFuncionalidade } from "../shared/interfaces/ILocalDadosFuncionalidade";
import { IFuncionalidadeTabelaWorksheet } from "../shared/interfaces/IFuncionalidadeTabelaWorksheet";
import { valoresHistoricosValidarDadosEMapearResultados } from "./Funcionalidades/Validacoes/ValoresHistoricosValidarDadosEMapearResultados";
import { aplicarValoresHistoricos } from "./Funcionalidades/AplicarValorPlanilha/AplicarValoresHistoricos";

export interface AppProps {
  title: string;
  isOfficeInitialized: boolean;
}

const nomeWorksheetPersistData: string = "AthenasHistorianPluginData";

const tabelas: IFuncionalidadeTabelaWorksheet = {
  valorAtual: {
    nomeWorksheetPersistData: nomeWorksheetPersistData,
    tableExportName: "ValorAtualDataPlugin",
    funcionalidade: "VALOR_ATUAL",
    colunas: ["tag", "localSaidaDado", "localSaidaTimestamp"],
    addressColunas: ["A", "B", "C"],
    styleTable: "TableStyleLight8",
    urlApiObtencaoDados: "get_last_value_tags",
  },
  valorHistorico: {
    nomeWorksheetPersistData: nomeWorksheetPersistData,
    tableExportName: "ValorHistoricoDataPlugin",
    funcionalidade: "VALOR_HISTORICO",
    colunas: [
      "tag",
      "timestamp",
      "localSaidaDado",
      "estrategia",
      "rangeEmHoras",
      "timestampInicio",
      "timestampFim",
      "funcaoDeAgregacao",
    ],
    addressColunas: ["E", "F", "G", "H", "I", "J", "K", "L"],
    styleTable: "TableStyleLight9",
    urlApiObtencaoDados: "get_historic_value_tags",
  },
  valoresHistoricos: {
    nomeWorksheetPersistData: nomeWorksheetPersistData,
    tableExportName: "ValoresHistoricosDataPlugin",
    funcionalidade: "VALORES_HISTORICOS",
    colunas: [
      "tag",
      "localSaidaTimestamp",
      "localSaidaDado",
      "timestampInicio",
      "timestampFim",
      "unidadeDeFrequencia",
      "frequencia",
      "funcaoDeAgregacao",
      "metodoDeInterpolacao",
    ],
    addressColunas: ["N", "O", "P", "Q", "R", "S", "T", "U", "V"],
    styleTable: "TableStyleLight10",
    urlApiObtencaoDados: "get_historic_values_tags",
  },
};

const dadosFuncionalidade: ILocalDadosFuncionalidade = {
  valorAtual: {
    tabelaDados: tabelas.valorAtual,
    validarDadosFuncionalidadeEMapearResultados: valorAtualValidarDadosEMapearResultados,
    applyValorFuncionalidadePlanilha: aplicarValorAtual,
  },
  valorHistorico: {
    tabelaDados: tabelas.valorHistorico,
    validarDadosFuncionalidadeEMapearResultados: valorHistoricoValidarDadosEMapearResultados,
    applyValorFuncionalidadePlanilha: aplicarValorHistorico,
  },
  valoresHistoricos: {
    tabelaDados: tabelas.valoresHistoricos,
    validarDadosFuncionalidadeEMapearResultados: valoresHistoricosValidarDadosEMapearResultados,
    applyValorFuncionalidadePlanilha: aplicarValoresHistoricos,
  },
};

export default function App({ title, isOfficeInitialized }: AppProps) {
  useEffect(() => {
    const criarTabelas = async () => {
      await criarTodasAsTabelasDePersistenciaDeDados(nomeWorksheetPersistData, [
        tabelas.valorAtual,
        tabelas.valorHistorico,
        tabelas.valoresHistoricos,
      ]).then();
    };
    criarTabelas().then();
  }, []);

  return (
    <>
      {!isOfficeInitialized ? (
        <Progress
          title={title}
          logo={require("./../../../assets/imagens/logo/logo-filled.png")}
          message="Carregue seu suplemento para ver o corpo do aplicativo."
        />
      ) : (
        <Switch>
          <Route exact path="/">
            <Welcome title={title} />
          </Route>
          <Route exact path="/valorAtual">
            <ValorAtual dadosFuncionalidade={dadosFuncionalidade.valorAtual} />
          </Route>
          <Route exact path="/valorHistorico">
            <ValorHistorico dadosFuncionalidade={dadosFuncionalidade.valorHistorico} />
          </Route>
          <Route exact path="/valoresHistoricos">
            <ValoresHistoricos dadosFuncionalidade={dadosFuncionalidade.valoresHistoricos} />
          </Route>
          <Route exact path="/configuracaoConexao">
            <ConfiguracoesConexao />
          </Route>
          <Route exact path="/atualizarPlanilha">
            <AtualizarPlanilha
              dadosFuncionalidades={[
                dadosFuncionalidade.valorAtual,
                dadosFuncionalidade.valorHistorico,
                dadosFuncionalidade.valoresHistoricos,
              ]}
            />
          </Route>
          <Route path="*">
            <RecursoIndisponivel />
          </Route>
        </Switch>
      )}
    </>
  );
}
