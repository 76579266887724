export enum FuncoesDeAgregacao {
  AVG = "AVG",
  MAX = "MAX",
  MIN = "MIN",
  SUM = "SUM",
  COUNT = "COUNT",
  STDDEV = "STDDEV",
  VARIANCE = "VARIANCE",
  MEDIAN = "MEDIAN",
  MODE = "MODE",
}
