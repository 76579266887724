import { BrandVariants, createLightTheme, Theme } from "@fluentui/react-components";

export function gerateThemeHistorian(): Theme {
  const historian: BrandVariants = {
    10: "#010403",
    20: "#0F1C16",
    30: "#132F24",
    40: "#153C2D",
    50: "#164B37",
    60: "#155942",
    70: "#13684C",
    80: "#0F7857",
    90: "#2F8566",
    100: "#499275",
    110: "#609F85",
    120: "#77AC95",
    130: "#8DB9A5",
    140: "#A3C7B6",
    150: "#B8D4C7",
    160: "#CEE2D9",
  };

  return {
    ...createLightTheme(historian),
  };
}
