import * as React from "react";
import { IItemTagValoresHistoricos } from "../../shared/interfaces/ItemTag";
import TaskPanelFunctionComponent from "../../shared/components/TaskPanelFunctionComponent/TaskPanelFunctionComponent";
import { IDadosFuncionalidade } from "../../shared/interfaces/IDadosFuncionalidade";
import { obterDadosHistorianFuncionalidade } from "../../utils/planilha";
import { IDadosConexaoHistorian } from "../../shared/interfaces/dadosConexaoHistorian";
import {
  ResponseValoresHistoricos,
  ValoresResponseSerie,
} from "../../shared/interfaces/responses/responseValoresHistoricos";
import { aplicarValoresHistoricos } from "../Funcionalidades/AplicarValorPlanilha/AplicarValoresHistoricos";
import { IResponsePreRemoveItem } from "../../shared/interfaces/IResponsePreRemoveItem";
import { convertMsData } from "../../utils";

interface Iprops {
  dadosFuncionalidade: IDadosFuncionalidade;
}

export default function ValorAtual({ dadosFuncionalidade }: Iprops) {
  function montarStringRegistroTableValoresHistoricos(registro: IItemTagValoresHistoricos): (string | number)[] {
    return [
      registro.tag,
      registro.localSaidaTimestamp,
      registro.localSaidaDado,
      convertMsData(registro.timestampInicio),
      convertMsData(registro.timestampFim),
      registro.unidadeDeFrequencia,
      registro.frequencia,
      registro.funcaoDeAgregacao,
      registro.metodoDeInterpolacao,
    ];
  }

  function montarNumberFormatRegistroTable(): string[] {
    return ["@", "@", "@", "dd/mm/yyyy hh:mm:ss", "dd/mm/yyyy hh:mm:ss", "@", "@", "@", "@"];
  }

  async function preRemoveItem(
    item: IItemTagValoresHistoricos,
    urlApiObtencaoDados: string,
    dadosConexaoHistorian: IDadosConexaoHistorian
  ): Promise<IResponsePreRemoveItem> {
    let resultadoTudoCerto = true;
    try {
      let resultadosObtidos: ResponseValoresHistoricos[] = (await obterDadosHistorianFuncionalidade(
        urlApiObtencaoDados,
        dadosConexaoHistorian,
        [item]
      )) as ResponseValoresHistoricos[];
      if (resultadosObtidos.length > 0) {
        let itemsMapeados: ValoresResponseSerie[] = resultadosObtidos[0].value.map(() => {
          return {
            value: "",
            timestamp: "",
          };
        });
        let resultado: ResponseValoresHistoricos = resultadosObtidos[0];
        resultado.value = itemsMapeados;
        let errosAoAplicar: string[] = await aplicarValoresHistoricos([resultado], () => false);
        resultadoTudoCerto = errosAoAplicar.length == 0;
      }
      return {
        error: !resultadoTudoCerto,
        msg: resultadoTudoCerto ? "" : "Falha ao realizar a remoção dos valoes aplicados na planilha",
      };
    } catch (e) {
      return {
        error: true,
        msg: resultadoTudoCerto ? "" : "Falha ao realizar a pre-remoção do item",
      };
    }
  }

  return (
    <TaskPanelFunctionComponent
      nomeWorksheetPersistData={dadosFuncionalidade.tabelaDados.nomeWorksheetPersistData}
      tableName={dadosFuncionalidade.tabelaDados.tableExportName}
      colunas={dadosFuncionalidade.tabelaDados.colunas}
      funcionalidade={dadosFuncionalidade.tabelaDados.funcionalidade}
      nomeFuncionalidade={"valores_historicos"}
      nomeFuncionalidadeExibicao={"Valores históricos"}
      mensagemCarregamentoDadosDaFuncionalidade={"Obtendo os valores históricos das tags listadas"}
      mensagemCarregamentoDadosDaFuncionalidadeSelecionado={"Obtendo os valores históricos da tag selecionada"}
      aplicarLabel={"Obter valores históricos todos os itens"}
      aplicarLabelSelecionado={"Obter valores históricos do item selecionado"}
      styleTable={dadosFuncionalidade.tabelaDados.styleTable}
      addressColunas={dadosFuncionalidade.tabelaDados.addressColunas}
      urlApiObtencaoDados={dadosFuncionalidade.tabelaDados.urlApiObtencaoDados}
      validarDadosFuncionalidadeEMapearResultados={dadosFuncionalidade.validarDadosFuncionalidadeEMapearResultados}
      montarStringRegistroTable={montarStringRegistroTableValoresHistoricos}
      montarNumberFormatRegistroTable={montarNumberFormatRegistroTable}
      valorApply={dadosFuncionalidade.applyValorFuncionalidadePlanilha}
      preRemoveItem={preRemoveItem}
    />
  );
}
