import ModalInformacao from "../ModalInformacao/ModalInformacao";
import * as React from "react";

export default function RecursoIndisponivel() {
  return (
    <div className="ms-Grid" dir="ltr">
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm12">
          <div>
            <ModalInformacao
              open={true}
              tipo="warning"
              tipoBoldPrefixoMensagem="Aviso: "
              mensagem="Recurso indisponível."
            />
          </div>
        </div>
      </div>
    </div>
  );
}
