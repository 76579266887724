import { CounterBadge, Tree, TreeItem, TreeItemLayout, TreeItemPersonaLayout } from "@fluentui/react-components";
import * as React from "react";
import {
  FluentIconsProps,
  Important16Regular,
  CheckmarkCircle16Filled,
  TextBulletListSquareWarning24Regular,
} from "@fluentui/react-icons";

const iconStylePropsRed: FluentIconsProps = {
  primaryFill: "red",
};

const iconStylePropsGreen: FluentIconsProps = {
  primaryFill: "green",
};

const AsideContent = ({ typeAside, messageCount }: { typeAside?: "danger" | "info"; messageCount?: number }) => (
  <>
    {typeAside == "danger" && <Important16Regular {...iconStylePropsRed} />}
    {typeAside == "danger" && messageCount && messageCount > 0 && (
      <CounterBadge count={messageCount} color="danger" size="small" />
    )}
    {typeAside == "info" && <CheckmarkCircle16Filled {...iconStylePropsGreen} />}
  </>
);

interface IProps {
  nome: string;
  erros: string[];
}

export function ItemListaFuncionalidade({ nome, erros }: IProps) {
  return (
    <>
      {erros.length > 0 ? (
        <TreeItem itemType="branch">
          <TreeItemLayout aside={<AsideContent typeAside="danger" messageCount={erros.length} />}>
            {nome}
          </TreeItemLayout>
          <Tree>
            {erros.map((valor, index) => {
              return (
                <TreeItem key={index} itemType="leaf" aria-description="Important">
                  <TreeItemPersonaLayout media={<TextBulletListSquareWarning24Regular />}>
                    {valor}
                  </TreeItemPersonaLayout>
                </TreeItem>
              );
            })}
          </Tree>
        </TreeItem>
      ) : (
        <TreeItem itemType="branch">
          <TreeItemLayout expandIcon={""} aside={<AsideContent typeAside="info" messageCount={null} />}>
            {nome}
          </TreeItemLayout>
        </TreeItem>
      )}
    </>
  );
}
