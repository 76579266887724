import * as React from "react";

import { Dialog, DialogSurface, DialogTitle, DialogBody, DialogContent, tokens } from "@fluentui/react-components";
import { InfoFilled, WarningFilled, DismissCircleFilled, CheckmarkCircleFilled } from "@fluentui/react-icons";
import { isNotNuloOuUndefined } from "../../../utils";

interface IProps {
  tipo: "warning" | "danger" | "info" | "success";
  tipoBoldPrefixoMensagem?: string;
  mensagem: string;
  open: boolean;
}

export default function ModalInformacao({ tipo, tipoBoldPrefixoMensagem, mensagem, open }: IProps) {
  return (
    <Dialog open={open}>
      <DialogSurface as={"div"} style={{ maxWidth: "250px" }}>
        <DialogBody>
          <DialogTitle style={{ textAlign: "center" }}>
            {(() => {
              switch (tipo) {
                case "info":
                  return <InfoFilled fontSize={"3em"} primaryFill={tokens.colorNeutralForeground2} />;
                case "warning":
                  return <WarningFilled fontSize={"3em"} primaryFill={tokens.colorPaletteYellowForeground2} />;
                case "danger":
                  return <DismissCircleFilled fontSize={"3em"} primaryFill={tokens.colorPaletteRedForeground3} />;
                case "success":
                  return <CheckmarkCircleFilled fontSize={"3em"} primaryFill={tokens.colorPaletteGreenForeground3} />;
                default:
                  return <InfoFilled fontSize={"3em"} primaryFill={tokens.colorNeutralForeground2} />;
              }
            })()}
          </DialogTitle>
          <DialogContent style={{ textAlign: "justify" }}>
            {isNotNuloOuUndefined(tipoBoldPrefixoMensagem) && <b>{tipoBoldPrefixoMensagem}</b>}
            {mensagem}
          </DialogContent>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
}
