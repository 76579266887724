import { Button, Checkbox, Field, Input, Radio, RadioGroup, Select, Spinner } from "@fluentui/react-components";
import React, { FormEvent, useState } from "react";
import ButtonGetDataBySelectWorkbook from "../../../../shared/components/ButtonGetDataBySelectWorkbook/ButtonGetDataBySelectWorkbook";
import { IItemTagValoresHistoricos } from "../../../../shared/interfaces/ItemTag";
import { v4 as uuidv4 } from "uuid";
import { IValidationField } from "../../../../shared/interfaces/ValidationField";
import { ValidacoesFormValoresHistoricos } from "../../../../utils/ValidacoesForm";
import { IDadosConexaoHistorian } from "../../../../shared/interfaces/dadosConexaoHistorian";
import { isNotNuloOuUndefined, isNuloOuUndefined } from "../../../../utils";
import { FuncoesDeAgregacao } from "../../enums/funcoesDeAgregacao";
import { UnidadeDeFrequencia } from "../../enums/unidadeDeFrequencia";
import { MetodoDeInterpolacao } from "../../enums/metodoDeInterpolacao";
import {
  optionsFuncoesDeAgregacao,
  optionsMetodoDeInterpolacao,
  optionsUnidadesDeFrequencia,
} from "../../options/options";

interface IProps {
  funcionalidade: string;
  itens: IItemTagValoresHistoricos[];
  editItem?: IItemTagValoresHistoricos;
  submitFunction: (item: IItemTagValoresHistoricos) => void;
  dadosConexao: IDadosConexaoHistorian;
  nomePlanilhaDePersistenciasDosDados: string;
}

export default function TagFormValoresHistoricos({
  funcionalidade,
  itens,
  editItem = null,
  submitFunction,
  dadosConexao,
  nomePlanilhaDePersistenciasDosDados,
}: IProps) {
  const [carregando, setCarregando] = useState<boolean>(false);
  const [tag, setTag] = useState<string>(isNotNuloOuUndefined(editItem) ? editItem.tag : "");
  const [localSaidaDado, setLocalSaida] = useState<string>(
    isNotNuloOuUndefined(editItem) ? editItem.localSaidaDado : ""
  );
  const [exportarTimestamp, setExportarTimestamp] = useState<boolean>(
    isNotNuloOuUndefined(editItem) ? editItem.exportarTimestamp : false
  );
  const [localSaidaTimestamp, setLocalSaidaTimestamp] = useState<string>(
    isNotNuloOuUndefined(editItem) ? editItem.localSaidaTimestamp : ""
  );
  const [timestampInicio, setTimestampInicio] = useState<string>(
    isNotNuloOuUndefined(editItem) ? editItem.timestampInicio : ""
  );
  const [timestampFim, setTimestampFim] = useState<string>(isNotNuloOuUndefined(editItem) ? editItem.timestampFim : "");
  const [agruparDadosPorPeriodo, setAgruparDadosPorPeriodo] = useState<boolean>(
    isNotNuloOuUndefined(editItem) ? editItem.agruparDadosPorPeriodo : false
  );

  const [unidadeDeFrequencia, setUnidadeDeFrequencia] = useState<string>(
    isNotNuloOuUndefined(editItem) ? editItem.unidadeDeFrequencia : ""
  );

  const [frequencia, setFrequencia] = useState<string>(isNotNuloOuUndefined(editItem) ? editItem.frequencia : "");

  const [funcaoDeAgregacao, setFuncaoDeAgregacao] = useState<string>(
    isNotNuloOuUndefined(editItem) ? editItem.funcaoDeAgregacao : ""
  );

  const [usarInterpolacao, setUsarInterpolacao] = useState<boolean>(
    isNotNuloOuUndefined(editItem) ? editItem.usarInterpolacao : false
  );

  const [metodoDeInterpolacao, setMetodoDeInterpolacao] = useState<string>(
    isNotNuloOuUndefined(editItem) ? editItem.metodoDeInterpolacao : ""
  );

  const [validationTag, setValidationTag] = useState<IValidationField>({
    validationState: "none",
    validationMessage: "",
  });
  const [validationlocalSaidaValor, setValidationLocalSaidaValor] = useState<IValidationField>({
    validationState: "none",
    validationMessage: "",
  });
  const [validationlocalSaidaTimetamp, setValidationLocalSaidaaTimetamp] = useState<IValidationField>({
    validationState: "none",
    validationMessage: "",
  });
  const [validationTimestampInicio, setValidationTimestampInicio] = useState<IValidationField>({
    validationState: "none",
    validationMessage: "",
  });
  const [validationTimestampFim, setValidationTimestampFim] = useState<IValidationField>({
    validationState: "none",
    validationMessage: "",
  });
  const [validationUnidadeDeFrequencia, setValidationUnidadeDeFrequencia] = useState<IValidationField>({
    validationState: "none",
    validationMessage: "",
  });
  const [validationFrequencia, setValidationFrequencia] = useState<IValidationField>({
    validationState: "none",
    validationMessage: "",
  });
  const [validationFuncaoDeAgregacao, setValidationFuncaoDeAgregacao] = useState<IValidationField>({
    validationState: "none",
    validationMessage: "",
  });
  const [validationMetodoDeInterpolacao, setValidationMetodoDeInterpolacao] = useState<IValidationField>({
    validationState: "none",
    validationMessage: "",
  });

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setCarregando(true);

    let resultado = await ValidacoesFormValoresHistoricos(
      tag,
      localSaidaDado,
      exportarTimestamp,
      localSaidaTimestamp,
      timestampInicio,
      timestampFim,
      agruparDadosPorPeriodo,
      unidadeDeFrequencia,
      frequencia,
      funcaoDeAgregacao,
      usarInterpolacao,
      metodoDeInterpolacao,
      itens,
      dadosConexao,
      nomePlanilhaDePersistenciasDosDados,
      editItem
    );
    setValidationTag(resultado.mensagensDeErroTag);
    setValidationLocalSaidaValor(resultado.mensagensDeErroLocalSaidaValor);
    setValidationLocalSaidaaTimetamp(resultado.mensagensDeErroLocalSaidaTimestamp);
    setValidationTimestampInicio(resultado.mensagensDeErroTimestampInicio);
    setValidationTimestampFim(resultado.mensagensDeErroTimestampFim);
    setValidationUnidadeDeFrequencia(resultado.mensagensDeErroUnidadeDeFrequencia);
    setValidationFrequencia(resultado.mensagensDeErroFrequencia);
    setValidationFuncaoDeAgregacao(resultado.mensagensDeErroFuncaoDeAgregacao);
    setValidationMetodoDeInterpolacao(resultado.mensagensDeErroMetodoDeInterpolacao);
    if (
      resultado.mensagensDeErroTag.validationState == "none" &&
      resultado.mensagensDeErroLocalSaidaValor.validationState == "none" &&
      resultado.mensagensDeErroLocalSaidaTimestamp.validationState == "none" &&
      resultado.mensagensDeErroTimestampInicio.validationState == "none" &&
      resultado.mensagensDeErroTimestampFim.validationState == "none" &&
      resultado.mensagensDeErroUnidadeDeFrequencia.validationState == "none" &&
      resultado.mensagensDeErroFrequencia.validationState == "none" &&
      resultado.mensagensDeErroFuncaoDeAgregacao.validationState == "none" &&
      resultado.mensagensDeErroMetodoDeInterpolacao.validationState == "none"
    ) {
      if (isNuloOuUndefined(editItem)) {
        submitFunction({
          funcionalidade: funcionalidade,
          id: uuidv4(),
          tag: tag,
          localSaidaDado: localSaidaDado,
          exportarTimestamp: exportarTimestamp,
          localSaidaTimestamp: localSaidaTimestamp,
          timestampInicio: timestampInicio,
          timestampFim: timestampFim,
          agruparDadosPorPeriodo: agruparDadosPorPeriodo,
          unidadeDeFrequencia: agruparDadosPorPeriodo ? unidadeDeFrequencia : "",
          frequencia: agruparDadosPorPeriodo ? frequencia : "",
          funcaoDeAgregacao: agruparDadosPorPeriodo ? funcaoDeAgregacao : "",
          usarInterpolacao: usarInterpolacao,
          metodoDeInterpolacao: agruparDadosPorPeriodo && usarInterpolacao ? metodoDeInterpolacao : "",
        });
      } else {
        let item: IItemTagValoresHistoricos = editItem;
        item.tag = tag;
        item.localSaidaDado = localSaidaDado;
        item.exportarTimestamp = exportarTimestamp;
        item.localSaidaTimestamp = localSaidaTimestamp;
        item.timestampInicio = timestampInicio;
        item.timestampFim = timestampFim;
        item.agruparDadosPorPeriodo = agruparDadosPorPeriodo;
        item.unidadeDeFrequencia = agruparDadosPorPeriodo ? unidadeDeFrequencia : "";
        item.frequencia = agruparDadosPorPeriodo ? frequencia : "";
        item.funcaoDeAgregacao = agruparDadosPorPeriodo ? funcaoDeAgregacao : "";
        item.usarInterpolacao = usarInterpolacao;
        item.metodoDeInterpolacao = agruparDadosPorPeriodo && usarInterpolacao ? metodoDeInterpolacao : "";
        submitFunction(item);
      }
    }
    setCarregando(false);
  }

  return (
    <form onSubmit={handleSubmit}>
      <Field
        label={"Tag"}
        required
        validationState={validationTag.validationState}
        validationMessage={validationTag.validationMessage}
      >
        <Input
          type={"text"}
          value={tag}
          onChange={(evento) => setTag(evento.target.value)}
          contentAfter={<ButtonGetDataBySelectWorkbook setarValor={setTag} />}
        ></Input>
      </Field>
      <Field
        label={"Timestamp de início"}
        required
        validationState={validationTimestampInicio.validationState}
        validationMessage={validationTimestampInicio.validationMessage}
      >
        <Input
          type={"datetime-local"}
          step="1"
          required
          value={timestampInicio}
          onChange={(evento) => setTimestampInicio(evento.target.value)}
        ></Input>
      </Field>
      <Field
        label={"Timestamp de fim"}
        required
        validationState={validationTimestampFim.validationState}
        validationMessage={validationTimestampFim.validationMessage}
      >
        <Input
          type={"datetime-local"}
          step="1"
          required
          value={timestampFim}
          onChange={(evento) => setTimestampFim(evento.target.value)}
        ></Input>
      </Field>
      <Field>
        <Checkbox
          className="noOutline"
          checked={exportarTimestamp}
          onChange={(_, data) => setExportarTimestamp(data.checked as boolean)}
          label="Exportar timestamp"
        />
      </Field>
      {exportarTimestamp && (
        <Field
          label={"Local de saída do timestamp"}
          required={exportarTimestamp}
          validationState={validationlocalSaidaTimetamp.validationState}
          validationMessage={validationlocalSaidaTimetamp.validationMessage}
        >
          <Input
            type={"text"}
            value={localSaidaTimestamp}
            onChange={(evento) => setLocalSaidaTimestamp(evento.target.value)}
            contentAfter={<ButtonGetDataBySelectWorkbook setarValor={setLocalSaidaTimestamp} tipoObtencao={"local"} />}
          ></Input>
        </Field>
      )}
      <Field
        label={"Local de saída do dado"}
        required
        validationState={validationlocalSaidaValor.validationState}
        validationMessage={validationlocalSaidaValor.validationMessage}
      >
        <Input
          type={"text"}
          value={localSaidaDado}
          onChange={(evento) => setLocalSaida(evento.target.value)}
          contentAfter={<ButtonGetDataBySelectWorkbook setarValor={setLocalSaida} tipoObtencao={"local"} />}
        ></Input>
      </Field>
      <Field>
        <Checkbox
          className="noOutline"
          checked={agruparDadosPorPeriodo}
          label="Agrupar dados por período"
          onChange={(_, data) => {
            let valor: boolean = data.checked as boolean;
            setUnidadeDeFrequencia(valor ? UnidadeDeFrequencia.MINUTES : "");
            setFuncaoDeAgregacao(valor ? FuncoesDeAgregacao.AVG : "");
            setAgruparDadosPorPeriodo(valor);
            setFrequencia(valor ? "1" : "");
          }}
        />
      </Field>
      {agruparDadosPorPeriodo && (
        <>
          <Field
            label={"Unidade de frequência"}
            validationState={validationUnidadeDeFrequencia.validationState}
            validationMessage={validationUnidadeDeFrequencia.validationMessage}
            required
          >
            <Select
              onChange={(_event, data) => {
                setUnidadeDeFrequencia(data.value);
              }}
              value={unidadeDeFrequencia}
              placeholder={"Selecione uma unidade de frequência"}
              required={agruparDadosPorPeriodo}
            >
              {optionsUnidadesDeFrequencia.map((value) => {
                return (
                  <option key={value.id} value={value.value}>
                    {value.name}
                  </option>
                );
              })}
            </Select>
          </Field>
          <Field
            label={"Frequência"}
            validationState={validationFrequencia.validationState}
            validationMessage={validationFrequencia.validationMessage}
            required
          >
            <Input
              type={"number"}
              step={1}
              min={1}
              value={frequencia}
              onChange={(_ev, data) => setFrequencia(data.value)}
              required={agruparDadosPorPeriodo}
            ></Input>
          </Field>
          <Field
            label={"Função de agregação"}
            validationState={validationFuncaoDeAgregacao.validationState}
            validationMessage={validationFuncaoDeAgregacao.validationMessage}
            required
          >
            <Select
              onChange={(_event, data) => {
                setFuncaoDeAgregacao(data.value);
              }}
              value={funcaoDeAgregacao}
              required={agruparDadosPorPeriodo}
            >
              {optionsFuncoesDeAgregacao.map((value) => {
                return (
                  <option key={value.id} value={value.value}>
                    {value.name}
                  </option>
                );
              })}
            </Select>
          </Field>
          <Field>
            <Checkbox
              className="noOutline"
              checked={usarInterpolacao}
              label="Usar interpolação"
              onChange={(_, data) => {
                let valor: boolean = data.checked as boolean;
                setMetodoDeInterpolacao(valor ? MetodoDeInterpolacao.LINEAR : "");
                setUsarInterpolacao(valor);
              }}
            />
          </Field>
          {usarInterpolacao && (
            <Field
              label={"Método de interpolação"}
              validationState={validationMetodoDeInterpolacao.validationState}
              validationMessage={validationMetodoDeInterpolacao.validationMessage}
              required
            >
              <RadioGroup value={metodoDeInterpolacao} onChange={(_, data) => setMetodoDeInterpolacao(data.value)}>
                {optionsMetodoDeInterpolacao.map((value) => {
                  return <Radio key={value.id} className="noOutline" value={value.value} label={value.name} />;
                })}
              </RadioGroup>
            </Field>
          )}
        </>
      )}
      <Field>
        <Button type="submit" style={{ marginTop: "10px", width: "100%" }} appearance="primary">
          {carregando ? (
            <Spinner appearance="inverted" size="tiny" />
          ) : isNuloOuUndefined(editItem) ? (
            "Adicionar"
          ) : (
            "Editar"
          )}
        </Button>
      </Field>
    </form>
  );
}
