import * as React from "react";
import ConexaoForm from "./components/ConexaoForm/ConexaoForm";
import TesteConexao from "./components/TesteConexao/TesteConexao";
import { get_url_api, getItemLocalStorage, isNotNuloOuUndefined, setItemLocalStorage } from "../../utils";
import Alert from "../../shared/components/Alert/Alert";
import axios from "axios";
import { useLayoutEffect, useState } from "react";
import { IDadosConexaoHistorian } from "../../shared/interfaces/dadosConexaoHistorian";

interface IResponse {
  status: boolean;
  detail: string;
}

interface IResultadoConexaoHistorian {
  status: boolean;
  titulo: string;
  mensagem: string;
  tipo: "warning" | "danger" | "info" | "success";
}

export default function ConfiguracoesConexao() {
  const [carregando, setCarregando] = useState<boolean>(false);
  const [dadosConexaoHistorian, setDadosConexaoHistorian] = useState<null | IDadosConexaoHistorian>(null);
  const [resultadoHistorianConexao, setResultadoHistorianConexao] = useState<null | IResultadoConexaoHistorian>(null);

  function testarConexao(dadosConexao: IDadosConexaoHistorian): void {
    setResultadoHistorianConexao(null);
    axios
      .get<IResponse>(`${get_url_api()}/api/check_conection_from_historian`, {
        params: { host: dadosConexao.host, port: dadosConexao.porta },
      })
      .then((resposta) => {
        if (resposta.data.status) {
          setResultadoHistorianConexao({
            status: true,
            titulo: "Info",
            mensagem: resposta.data.detail,
            tipo: "success",
          });
          setItemLocalStorage("porta", dadosConexao.porta.toString());
          setItemLocalStorage("host", dadosConexao.host);

          setCarregando(false);
          setDadosConexaoHistorian(dadosConexao);
        } else {
          setResultadoHistorianConexao({
            status: false,
            titulo: "Aviso",
            mensagem: resposta.data.detail,
            tipo: "warning",
          });
          setCarregando(false);
        }
      })
      .catch(() => {
        setResultadoHistorianConexao({
          status: false,
          titulo: "Erro",
          mensagem: "Falha ao conectar no Historian",
          tipo: "danger",
        });
        setCarregando(false);
      });
  }

  useLayoutEffect(() => {
    let porta: string = getItemLocalStorage("porta");
    let host: string = getItemLocalStorage("host");
    if (isNotNuloOuUndefined(porta) && isNotNuloOuUndefined(host)) {
      setDadosConexaoHistorian({ host, porta: parseInt(porta) });
    }
  }, []);

  return (
    <div className="ms-Grid" dir="ltr">
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm12">
          <h3 style={{ textAlign: "center" }}>Configurações do servidor</h3>
        </div>
      </div>
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm12">
          <ConexaoForm
            carregando={carregando}
            setCarregando={setCarregando}
            dadosConexaoHistorian={dadosConexaoHistorian}
            testarConexao={testarConexao}
          />
        </div>
      </div>
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm12" style={{ position: "absolute", bottom: "0", marginBottom: "3em" }}>
          {carregando && <TesteConexao />}
        </div>
      </div>
      {isNotNuloOuUndefined(resultadoHistorianConexao) && (
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12" style={{ position: "absolute", bottom: "0", marginBottom: "3em" }}>
            <Alert
              mensagem={resultadoHistorianConexao.mensagem}
              tipo={resultadoHistorianConexao.tipo}
              titulo={resultadoHistorianConexao.titulo}
            />
          </div>
        </div>
      )}
    </div>
  );
}
