import Header from "./components/Header";
import HeroList from "./components/HeroList";
import { Button } from "@fluentui/react-components";
import { ChevronRight24Regular } from "@fluentui/react-icons";

import * as React from "react";

/* global Excel  */

interface Props {
  title: string;
}

interface Item {
  icon: string;
  primaryText: string;
}

export default function Welcome({ title }: Props) {
  const listItems: Item[] = [
    {
      icon: "Ribbon",
      primaryText: "Alcance mais com a integração do Office",
    },
    {
      icon: "Unlock",
      primaryText: "Desbloqueie recursos e funcionalidades",
    },
    {
      icon: "Design",
      primaryText: "Crie e visualize como um profissional",
    },
  ];

  async function click() {
    try {
      await Excel.run(async (context) => {
        /**
         * Insert your Excel code here
         */
        const range = context.workbook.getSelectedRange();

        // Read the range address
        range.load("address");

        // Update the fill color
        range.format.fill.color = "yellow";

        await context.sync();
      });
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="ms-welcome">
      <Header logo={require("./../../../../assets/imagens/logo/logo-filled.png")} title={title} message="Bem-vindo" />
      <HeroList message="Descubra o que os suplementos do Office podem fazer por você hoje!" items={listItems}>
        <p className="ms-font-l">
          Modifique os arquivos de origem e clique em <b>Run</b>.
        </p>
        <Button icon={<ChevronRight24Regular />} onClick={click}>
          Run
        </Button>
      </HeroList>
    </div>
  );
}
