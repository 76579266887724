import { IValorApplyPlanilha } from "../../../shared/interfaces/IValorApplyPlanilha";
import { IDadosEnderecoPlanilha } from "../../../shared/interfaces/IDadosEnderecoPlanilha";
import { validarEnderecoPlanilha } from "../../../utils/ValidarEnderecoPlaninha";
import { balanceamentoDeCargaAplicarValorPlanilha, isNotNuloOuUndefined } from "../../../utils";
import { ResponseValoresHistoricos } from "../../../shared/interfaces/responses/responseValoresHistoricos";
import moment from "moment/moment";

export async function aplicarValoresHistoricos(
  itens: ResponseValoresHistoricos[],
  getCancelar: () => boolean,
  usarTextTypeInDate: boolean = true
): Promise<string[]> {
  let itensMapeadosEndereco: IValorApplyPlanilha[] = [];
  let erros: string[] = [];
  for(let item of itens){
    if(getCancelar()) break;
    let enderecoDado: IDadosEnderecoPlanilha = {
      endereco: "",
      planilha: "",
      enderecoColuna: undefined,
      enderecoLinha: undefined,
    };
    let enderecoTimestamp: IDadosEnderecoPlanilha = {
      endereco: "",
      planilha: "",
      enderecoColuna: undefined,
      enderecoLinha: undefined,
    };
    let erroEnderecoDado: boolean = false;
    let erroEnderecoTimestamp: boolean = false;
    try {
      enderecoDado = validarEnderecoPlanilha(item.localSaidaDado);
    } catch (e) {
      erroEnderecoDado = true;
      erros.push(e.toString());
    }
    if (item.exportarTimestamp) {
      try {
        enderecoTimestamp = validarEnderecoPlanilha(item.localSaidaTimestamp);
      } catch (e) {
        erroEnderecoTimestamp = true;
        erros.push(e.toString());
      }
    }
    if (!erroEnderecoDado && !erroEnderecoTimestamp) {
      item.value.forEach((value, index) => {
        let dadosPlanilhaEnderecoSaidaDado: IDadosEnderecoPlanilha = {
          planilha: enderecoDado.planilha,
          endereco:
            isNotNuloOuUndefined(enderecoDado.enderecoColuna) && isNotNuloOuUndefined(enderecoDado.enderecoLinha)
              ? `${enderecoDado.enderecoColuna}${enderecoDado.enderecoLinha + index}`
              : enderecoDado.endereco,
        };

        itensMapeadosEndereco.push({
          valor: value.value,
          texto: false,
          dadosPlanilha: dadosPlanilhaEnderecoSaidaDado,
        });
        if (item.exportarTimestamp) {
          let dadosPlanilhaEnderecoSaidaTimestamp: IDadosEnderecoPlanilha = {
            planilha: enderecoTimestamp.planilha,
            endereco:
              isNotNuloOuUndefined(enderecoTimestamp.enderecoColuna) &&
              isNotNuloOuUndefined(enderecoTimestamp.enderecoLinha)
                ? `${enderecoTimestamp.enderecoColuna}${enderecoTimestamp.enderecoLinha + index}`
                : enderecoTimestamp.endereco,
          };

          itensMapeadosEndereco.push({
            valor:
              isNotNuloOuUndefined(value.timestamp) && value.timestamp !== "" && value.timestamp !== "Nenhum valor obtido"
                ? moment(value.timestamp).format("DD/MM/YYYY HH:mm:ss,SSS")
                : "Nenhum timestamp obtido",
            texto: usarTextTypeInDate,
            dadosPlanilha: dadosPlanilhaEnderecoSaidaTimestamp,
          });
        }
      });
    }
  }
  await balanceamentoDeCargaAplicarValorPlanilha(itensMapeadosEndereco, erros, getCancelar);
  return erros;
}
