import { ErroAddres } from "../../../shared/interfaces/IErroAddres";
import { IDadosConexaoHistorian } from "../../../shared/interfaces/dadosConexaoHistorian";
import {
  checkTagsExist,
  IResultadoTagsExistem,
  obterEnderecosDuplicados,
  verificarCampoDeTimestamp,
  verificarOcampoDeEndereco,
  verificarOcampoDeEnderecoTimestamp,
  verificarOcampoDeTag,
} from "../../../utils/ValidacoesImportacaoDeDados";
import { IResultadoMapeamento } from "../../../shared/interfaces/IResultadoMapeamento";
import { isNotNuloOuUndefined, mapearFormatStringToISO, obterDadosValidosEInvalidosESeusErros } from "../../../utils";
import { IItemTagAdressValoresHistoricos } from "../../ValoresHistoricos/interfaces/IItemTagAdressValoresHistoricos";
import { IItemTagValoresHistoricos } from "../../../shared/interfaces/ItemTag";
import { ValidarFormAgrupamentoDeDadosPorPeriodo, ValidarTimestampInicioFim } from "../../../utils/ValidacoesForm";

function verificarSeUtilizaAgrupamentoPorPeriodo(item: IItemTagAdressValoresHistoricos): boolean {
  return (
    (isNotNuloOuUndefined(item.unidadeDeFrequencia) && item.unidadeDeFrequencia.valor != "") ||
    (isNotNuloOuUndefined(item.frequencia) && item.frequencia.valor != "") ||
    (isNotNuloOuUndefined(item.funcaoDeAgregacao) && item.funcaoDeAgregacao.valor != "")
  );
}

function validarEMapearErrosAgrupamentoPorPeriodo(
  valoresMapeados: IItemTagValoresHistoricos,
  valoresBrutos: IItemTagAdressValoresHistoricos,
  erros: ErroAddres[]
) {
  const validacoes = ValidarFormAgrupamentoDeDadosPorPeriodo(
    valoresMapeados.agruparDadosPorPeriodo,
    valoresMapeados.unidadeDeFrequencia,
    valoresMapeados.frequencia,
    valoresMapeados.funcaoDeAgregacao,
    valoresMapeados.usarInterpolacao,
    valoresMapeados.metodoDeInterpolacao
  );

  if (validacoes.mensagensDeErroUnidadeDeFrequencia.validationState !== "none") {
    erros.push({
      id: valoresMapeados.id,
      errro: validacoes.mensagensDeErroUnidadeDeFrequencia.validationMessage,
      address: valoresBrutos.unidadeDeFrequencia.address,
    });
  }
  if (validacoes.mensagensDeErroFrequencia.validationState !== "none") {
    erros.push({
      id: valoresMapeados.id,
      errro: validacoes.mensagensDeErroFrequencia.validationMessage,
      address: valoresBrutos.frequencia.address,
    });
  }
  if (validacoes.mensagensDeErroFuncaoDeAgregacao.validationState !== "none") {
    erros.push({
      id: valoresMapeados.id,
      errro: validacoes.mensagensDeErroFuncaoDeAgregacao.validationMessage,
      address: valoresBrutos.funcaoDeAgregacao.address,
    });
  }
  if (validacoes.mensagensDeErroMetodoDeInterpolacao.validationState !== "none") {
    erros.push({
      id: valoresMapeados.id,
      errro: validacoes.mensagensDeErroMetodoDeInterpolacao.validationMessage,
      address: valoresBrutos.metodoDeInterpolacao.address,
    });
  }
}

export async function valoresHistoricosValidarDadosEMapearResultados(
  dadosConexaoHistorian: IDadosConexaoHistorian,
  valores: IItemTagAdressValoresHistoricos[],
  nomePlanilhaDePersistencia: string
): Promise<IResultadoMapeamento> {
  let itens: IItemTagValoresHistoricos[] = [];
  let erros: ErroAddres[] = [];
  let enderecos: string[] = valores.map((value) => value.localSaidaDado.valor);
  let enderecosTimestamps: string[] = valores
    .map((value) => value.localSaidaTimestamp.valor)
    .filter((value) => value != "");
  let tags: string[] = valores.map((value) => value.tag.valor);
  let resultadoCheckTagsExist: IResultadoTagsExistem = await checkTagsExist(dadosConexaoHistorian, tags);
  let enderecosDuplicados: string[] = obterEnderecosDuplicados(enderecos);
  let enderecosDuplicadosTimestamps: string[] = obterEnderecosDuplicados(enderecosTimestamps);

  for (const valor of valores) {
    let valorItemMapeado: IItemTagValoresHistoricos = {
      id: valor.id,
      funcionalidade: valor.funcionalidade,
      tag: valor.tag.valor,
      localSaidaDado: valor.localSaidaDado.valor,
      exportarTimestamp: isNotNuloOuUndefined(valor.localSaidaTimestamp.valor) && valor.localSaidaTimestamp.valor != "",
      localSaidaTimestamp: valor.localSaidaTimestamp.valor,
      timestampInicio: mapearFormatStringToISO(valor.timestampInicio.valor),
      timestampFim: mapearFormatStringToISO(valor.timestampFim.valor),
      agruparDadosPorPeriodo: verificarSeUtilizaAgrupamentoPorPeriodo(valor),
      unidadeDeFrequencia: valor.unidadeDeFrequencia.valor,
      frequencia: valor.frequencia.valor.trim(),
      funcaoDeAgregacao: valor.funcaoDeAgregacao.valor,
      usarInterpolacao:
        verificarSeUtilizaAgrupamentoPorPeriodo(valor) &&
        isNotNuloOuUndefined(valor.metodoDeInterpolacao) &&
        valor.metodoDeInterpolacao.valor != "",
      metodoDeInterpolacao: valor.metodoDeInterpolacao.valor,
    };
    itens.push(valorItemMapeado);
    verificarOcampoDeTag(
      valor.tag.valor,
      erros,
      valor.id,
      valor.tag.address,
      "tag",
      resultadoCheckTagsExist.errosAoVerificarTagsExistem,
      resultadoCheckTagsExist.tagsDisponiveis
    );
    await verificarOcampoDeEndereco(
      valor.localSaidaDado.valor,
      erros,
      valor.id,
      valor.localSaidaDado.address,
      "localSaidaDado",
      enderecosDuplicados,
      nomePlanilhaDePersistencia
    );
    await verificarOcampoDeEnderecoTimestamp(
      valor.localSaidaTimestamp.valor,
      erros,
      valor.id,
      valor.localSaidaTimestamp.address,
      "localSaidaTimestamp",
      Array.from(new Set(enderecos)),
      enderecosDuplicadosTimestamps,
      nomePlanilhaDePersistencia
    );
    let campoValidoTimestampInicio: boolean = await verificarCampoDeTimestamp(
      mapearFormatStringToISO(valor.timestampInicio.valor),
      erros,
      valor.id,
      valor.timestampInicio.address,
      "timestampInicio"
    );
    let campoValidoTimestampFim: boolean = await verificarCampoDeTimestamp(
      mapearFormatStringToISO(valor.timestampFim.valor),
      erros,
      valor.id,
      valor.timestampFim.address,
      "timestampFim"
    );
    if (campoValidoTimestampInicio && campoValidoTimestampFim) {
      let mensagensDeErroTimestamps = ValidarTimestampInicioFim(
        mapearFormatStringToISO(valor.timestampInicio.valor),
        mapearFormatStringToISO(valor.timestampFim.valor)
      );
      if (mensagensDeErroTimestamps.mensagensDeErroTimestampInicio.validationState !== "none") {
        erros.push({
          id: valor.id,
          errro: mensagensDeErroTimestamps.mensagensDeErroTimestampInicio.validationMessage,
          address: valor.timestampInicio.address,
        });
      }
      if (mensagensDeErroTimestamps.mensagensDeErroTimestampFim.validationState !== "none") {
        erros.push({
          id: valor.id,
          errro: mensagensDeErroTimestamps.mensagensDeErroTimestampFim.validationMessage,
          address: valor.timestampFim.address,
        });
      }
    }
    validarEMapearErrosAgrupamentoPorPeriodo(valorItemMapeado, valor, erros);
  }
  return obterDadosValidosEInvalidosESeusErros(itens, erros);
}
