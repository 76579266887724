/* global  Excel*/

import { isNuloOuUndefined } from "./index";

export async function verificarExistenciaDoWorksheetsDeExportacao(worksheetName: string) {
  await Excel.run(async (context) => {
    let sheets = context.workbook.worksheets;
    sheets.load("items/name");

    await context.sync();

    const lista: string[] = sheets.items.map((l) => l.name);
    await context.sync();

    if (!lista.includes(worksheetName)) {
      throw Error(`A worksheet ${worksheetName} não esta presente.`);
    } else {
      context.workbook.worksheets.getItem(worksheetName);
    }
    await context.sync();
  });
}

export async function verificarExistenciaDaTabelaDeExportacao(tableName: string) {
  await Excel.run(async (context) => {
    let tables = context.workbook.tables;
    tables.load("items/name");

    await context.sync();

    const lista: string[] = tables.items.map((l) => l.name);
    if (!lista.includes(tableName)) {
      throw Error(`A tabela ${tableName} não esta presente.`);
    }
  });
}

export async function obterDadosPersistidos<T>(
  funcionalidade: string,
  worksheetName: string,
  tableName: string,
  colunasDaTabela: string[],
  addressColunas: string[],
  mapeamentoValoresResultado: (
    funcionalidade: string,
    valores: string[][],
    colunas: string[],
    addressColunas: string[]
  ) => T[]
): Promise<T[]> {
  return await Excel.run(async (context) => {
    let sheet = context.workbook.worksheets.getItem(worksheetName);
    let expensesTable = sheet.tables.getItem(tableName);

    let headerRange = expensesTable.getHeaderRowRange().load("values");
    await context.sync();
    const colunas: string[] = headerRange.values.flatMap((v) => [...v]);
    const valoresPertencentes: string[] = colunas.filter((v) => colunasDaTabela.includes(v));

    if (colunas.length != colunasDaTabela.length || valoresPertencentes.length != colunasDaTabela.length) {
      throw Error(`As colunas da tabela não corresponde a da tabela padrão de exportação.`);
    } else {
      let bodyRange = expensesTable.getDataBodyRange().load(["text"]);
      await context.sync();
      let linhas: string[][] = bodyRange.text;
      if (linhas.length == 1 && linhas[0].every((element) => isNuloOuUndefined(element) || element === "")) {
        linhas = [[]];
      }
      return mapeamentoValoresResultado(funcionalidade, linhas, colunas, addressColunas);
    }
  });
}
