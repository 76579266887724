import App from "./pages/App";
import { AppContainer } from "react-hot-loader";
import { FluentProvider } from "@fluentui/react-components";
import * as React from "react";
import * as ReactDOM from "react-dom";
import "./taskpane.scss";
import { HashRouter } from "react-router-dom";
import { gerateThemeHistorian } from "./utils/gerateTheme";

/* global document, Office */

let isOfficeInitialized = false;

const lightTheme = gerateThemeHistorian();

const title = "Historian Excel Add-in";

const render = (Component: any) => {
  ReactDOM.render(
    <AppContainer>
      <FluentProvider theme={lightTheme} style={{ height: "100%" }}>
        <HashRouter>
          <Component title={title} isOfficeInitialized={isOfficeInitialized} />
        </HashRouter>
      </FluentProvider>
    </AppContainer>,
    document.getElementById("container")
  );
};

/* Render application after Office initializes */
Office.onReady(() => {
  isOfficeInitialized = true;
  render(App);
}).then();

if ((module as any).hot) {
  (module as any).hot.accept("./pages/App", () => {
    const NextApp = require("./pages/App").default;
    render(NextApp);
  });
}
