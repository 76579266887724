import * as React from "react";

/* global window, Excel*/
import { IDadosConexaoHistorian } from "../shared/interfaces/dadosConexaoHistorian";
import { v4 as uuidv4 } from "uuid";
import { IColorTitleTabela } from "../shared/interfaces/IColorTitleTabela";
import { IItemTagValorAtual, IItemTagValoresHistoricos, IItemTagValorHistorico } from "../shared/interfaces/ItemTag";
import { ErroAddres } from "../shared/interfaces/IErroAddres";
import { IResultadoMapeamento } from "../shared/interfaces/IResultadoMapeamento";
import moment from "moment-timezone";
import { IValoresAgrupadosPorPlanilha } from "../shared/interfaces/IValoresAgrupadosPorPlanilha";
import { IValorApplyPlanilha } from "../shared/interfaces/IValorApplyPlanilha";
import { ValorApply } from "./aplicarDadosPlanilha";
import { ISelectOption } from "../shared/interfaces/ISelectOption";

export function isNuloOuUndefined(valor: any): valor is undefined | null {
  return valor === null || valor === undefined;
}

export function isNotNuloOuUndefined<T>(valor: T | undefined | null): valor is T {
  return !isNuloOuUndefined(valor);
}

export function setItemLocalStorage(key: string, valor: string) {
  window.localStorage.setItem(key, valor);
}

export function getItemLocalStorage(key: string): string {
  return window.localStorage.getItem(key);
}

export function obterDadosDeConexaoHistorian(setarDados: React.Dispatch<React.SetStateAction<IDadosConexaoHistorian>>) {
  const porta: string = getItemLocalStorage("porta");
  const host: string = getItemLocalStorage("host");
  if (isNotNuloOuUndefined(porta) && isNotNuloOuUndefined(host)) {
    setarDados({ host, porta: parseInt(porta) });
  } else {
    setarDados(null);
  }
}

export function findDuplicates(arr: any[]) {
  return arr.filter((currentValue, currentIndex) => arr.indexOf(currentValue) !== currentIndex);
}

export function mapeamentoValoresEndereco<T>(
  funcionalidade: string,
  valores: string[][],
  colunas: string[],
  addressColunas: string[]
): T[] {
  let resultadoEndereco: T[] = [];
  let linha = 3;
  for (let valor of valores) {
    if (valor.length == colunas.length) {
      let linhaMapeadaEndereco = {};
      colunas.forEach((coluna, index) => {
        linhaMapeadaEndereco[coluna] = {
          valor: valor[index],
          address: `${addressColunas[index]}${linha}`,
        };
      });
      linhaMapeadaEndereco["id"] = uuidv4();
      linhaMapeadaEndereco["funcionalidade"] = funcionalidade;
      resultadoEndereco.push(linhaMapeadaEndereco as T);
    }
    linha += 1;
  }
  return resultadoEndereco;
}

export function obterColorTitleTabelaByStyleTable(
  styleTable:
    | "TableStyleLight8"
    | "TableStyleLight9"
    | "TableStyleLight10"
    | "TableStyleLight11"
    | "TableStyleLight12"
    | "TableStyleLight13"
    | "TableStyleLight14"
): IColorTitleTabela {
  switch (styleTable) {
    case "TableStyleLight8":
      return {
        backgroundColor: "#000000",
        fontColor: "#ffffff",
      };
    case "TableStyleLight9":
      return {
        backgroundColor: "#156082",
        fontColor: "#ffffff",
      };
    case "TableStyleLight10":
      return {
        backgroundColor: "#E97132",
        fontColor: "#ffffff",
      };
    case "TableStyleLight11":
      return {
        backgroundColor: "#a5a5a5",
        fontColor: "#ffffff",
      };
    case "TableStyleLight12":
      return {
        backgroundColor: "#ffc000",
        fontColor: "#ffffff",
      };
    case "TableStyleLight13":
      return {
        backgroundColor: "#5b9bd5",
        fontColor: "#ffffff",
      };
    case "TableStyleLight14":
      return {
        backgroundColor: "#70ad47",
        fontColor: "#ffffff",
      };
    default:
      return {
        backgroundColor: "#000000",
        fontColor: "#ffffff",
      };
  }
}

export function obterDadosValidosEInvalidosESeusErros(
  itens: (IItemTagValorAtual | IItemTagValorHistorico | IItemTagValoresHistoricos)[],
  erros: ErroAddres[]
): IResultadoMapeamento {
  let itensInvalidos = [];
  let itensValidos = itens;
  if (erros.length > 0) {
    let idErros = Array.from(new Set(erros.map((value) => value.id)));
    itensValidos = itens.filter((value) => isNuloOuUndefined(idErros.find((v) => v == value.id)));
    itensInvalidos = itens.filter((value) => isNotNuloOuUndefined(idErros.find((v) => v == value.id)));
  }
  return { todosOsItens: itens, itensValidos: itensValidos, itensInvalidos: itensInvalidos, erros: erros };
}

export function convertMsData(datatime: string) {
  let valorDatatime = moment(datatime).toDate();
  return 25569.0 + (valorDatatime.getTime() - valorDatatime.getTimezoneOffset() * 60 * 1000) / (1000 * 60 * 60 * 24);
}

export function convertMsDataToLacalDataString(excelDate: number): string {
  const unixTimestamp = (excelDate - 25569) * 86400; //as per the post above, convert Excel date to unix timestamp, assuming Mac/Windows Excel 2011 onwards
  const date = moment(new Date(unixTimestamp)); //Pass in unix timestamp instead of Excel date
  return date.format("DD-MMM-YYYY");
}

export function mapearFormatStringToISO(valor: string): string {
  if (valor !== "" && isNotNuloOuUndefined(valor)) {
    let valorReplace = valor.replaceAll(",", ".");
    try {
      const [data, tempo] = valorReplace.split(" ");
      const [dia, mes, ano] = data.split("/");
      return `${ano}-${mes}-${dia}T${tempo}`;
    } catch (_) {
      return valor;
    }
  } else {
    return valor;
  }
}

export function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function checkPlanilhasDisponiveis() {
  return await Excel.run(async (context) => {
    let worksheets = context.workbook.worksheets;
    worksheets.load("name");
    let nomes: string[] = [];
    return context.sync().then(function () {
      for (let i = 0; i < worksheets.items.length; i++) {
        nomes.push(worksheets.items[i].name);
      }
      return nomes;
    });
  });
}

export async function agruparValoresPorPlanilha(
  itensMapeadosEndereco: IValorApplyPlanilha[],
  erros: string[]
): Promise<IValoresAgrupadosPorPlanilha[]> {
  let planilhas: string[] = Array.from(new Set(itensMapeadosEndereco.map((value) => value.dadosPlanilha.planilha)));
  let planilhasDisponiveis: string[] = [];
  let valoresAgrupados: IValoresAgrupadosPorPlanilha[] = [];
  try {
    planilhasDisponiveis = await checkPlanilhasDisponiveis();
  } catch (e) {
    console.error(e);
    erros.push("Falha ao obter as Worksheets disponíveis");
    return valoresAgrupados;
  }
  planilhas.forEach((planilha) => {
    if (planilhasDisponiveis.includes(planilha)) {
      let valoresPlanilha: IValorApplyPlanilha[] = itensMapeadosEndereco.filter(
        (valor) => valor.dadosPlanilha.planilha == planilha
      );
      if (valoresPlanilha.length > 0) {
        let valoresText: IValorApplyPlanilha[] = valoresPlanilha.filter((value) => value.texto);
        if (valoresText.length > 0) {
          valoresAgrupados.push({ planilha: planilha, text: true, valores: valoresText });
        }
        let valoresGeral: IValorApplyPlanilha[] = valoresPlanilha.filter((value) => !value.texto);
        if (valoresGeral.length > 0) {
          valoresAgrupados.push({ planilha: planilha, text: false, valores: valoresGeral });
        }
        if (valoresText.length == 0 && valoresGeral.length == 0) {
          erros.push(`Falha no agrupamento dos dados por Planilha na planilha '${planilha}' por tipo de dado`);
        }
      } else {
        erros.push(`Falha no agrupamento dos dados por Planilha na planilha '${planilha}'`);
      }
    } else {
      erros.push(`A planilha ${planilha} não foi encontrada`);
    }
  });
  return valoresAgrupados;
}

export function chunkIntoN<T>(arr: T[], n: number): T[][] {
  const size = Math.ceil(arr.length / n);
  return Array.from({ length: n }, (_v, i) => arr.slice(i * size, i * size + size));
}

export async function balanceamentoDeCargaAplicarValorPlanilha(
  itensMapeadosEndereco: IValorApplyPlanilha[],
  erros: string[],
  getCancelar: () => boolean
) {
  let valoresAgrupadosPorPlanilha: IValoresAgrupadosPorPlanilha[] = await agruparValoresPorPlanilha(
    itensMapeadosEndereco,
    erros
  );
  if (valoresAgrupadosPorPlanilha.length > 0) {
    for (const value of valoresAgrupadosPorPlanilha) {
      if (getCancelar()) break;
      if (value.valores.length > 800) {
        let n: number = Math.ceil(value.valores.length / 800);
        let valoresApply: IValorApplyPlanilha[][] = chunkIntoN<IValorApplyPlanilha>(value.valores, n);
        for (let valores of valoresApply) {
          if (getCancelar()) break;
          if (valores.length > 0) {
            await ValorApply(value.planilha, valores, value.text, erros, getCancelar);
          }
        }
      } else {
        await ValorApply(value.planilha, value.valores, value.text, erros, getCancelar);
      }
    }
  }
}

export function obterLabelSelectPorValoresDisponiveis(valor: string, opcoes: ISelectOption[]) {
  const valorEncontrado: ISelectOption[] = opcoes.filter((value) => value.value === valor);
  if (valorEncontrado.length > 0) {
    return valorEncontrado[0].name;
  } else {
    return "Valor não disponível";
  }
}

export function get_url_api() {
  if (process.env.API != "") {
    return process.env.API;
  } else {
    let url = new URL(window.location.href);
    const pathname = url.pathname.split("/").filter((x) => x != "" && !x.endsWith(".html"));
    return pathname.length > 0 ? `${url.origin}/${pathname.join("/")}` : url.origin;
  }
}

export function get_url_websocket() {
  let url = process.env.WS != "" ? new URL(process.env.WS) : new URL(window.location.href);
  const scheme = url.protocol === "https:" ? "wss:" : "ws:";
  const pathname = url.pathname.split("/").filter((x) => x != "" && !x.endsWith(".html"));
  const host = pathname.length > 0 ? `${url.host}/${pathname.join("/")}` : url.host;
  return `${scheme}//${host}/ws`;
}
