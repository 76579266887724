import * as React from "react";

import {
  Dialog,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Button,
} from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";

interface IProps {
  title: string;
  contentMessage: string;
  contentConfirmMessageButtom: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  confirmFunction: () => void;
}

export default function ModalConfirmacao({
  title,
  contentMessage,
  contentConfirmMessageButtom,
  open,
  setOpen,
  confirmFunction,
}: IProps) {
  function handleConfirm() {
    confirmFunction();
    setOpen(false);
  }

  return (
    <Dialog open={open}>
      <DialogSurface as={"div"} style={{ maxWidth: "250px" }}>
        <DialogBody>
          <DialogTitle
            action={
              <Button
                onClick={() => setOpen(false)}
                appearance="subtle"
                aria-label="close"
                icon={<Dismiss24Regular />}
              />
            }
          >
            {title}
          </DialogTitle>
          <DialogContent>{contentMessage}</DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} appearance="secondary">
              Cancelar
            </Button>
            <Button onClick={handleConfirm} appearance="primary">
              {contentConfirmMessageButtom}
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
}
