import { IItemTagValorAtual, IItemTagValorHistorico, IItemTagValoresHistoricos } from "../shared/interfaces/ItemTag";
import { get_url_websocket } from "./index";

export interface IFiltros {
  funcionalidade: string;
  nomeFuncionalidade: string;
  host: string;
  porta: number;
  items: (IItemTagValorAtual | IItemTagValorHistorico | IItemTagValoresHistoricos)[];
  timezone: string;
}

export interface IDadoTagChannel {
  funcionalidade: string;
  onopen: () => void;
  onerror: () => void;
  onmessage: (dados: any) => void;
  oncancel: () => void;
}

export class DadoTagChannel {
  funcionalidade: string;

  etapas = new Array();
  websocket!: WebSocket;

  _pausado: boolean = false;

  cancelado: boolean = false;

  onopen: () => void;
  onerror: () => void;
  oncancel: () => void;
  onmessage: (dados: any) => void;

  constructor({ funcionalidade, onopen, onerror, onmessage, oncancel }: IDadoTagChannel) {
    (this.funcionalidade = funcionalidade), (this.onopen = onopen);
    this.onerror = onerror;
    this.oncancel = oncancel;
    this.onmessage = onmessage;
    this.connect(funcionalidade);
  }

  connect(nome: string) {
    const socketUrl = `${get_url_websocket()}/channel-valores-historicos?name=${nome}`;

    this.websocket = new WebSocket(socketUrl);

    this.websocket.onclose = (_) => {};

    this.websocket.onmessage = (event) => {
      let message = JSON.parse(event.data);
      if (message.cancelado || this.cancelado) {
        this.close();
        return;
      }
      if (message.ready) {
        this.next();
        return;
      }
      this.onmessage(message);

      if (message.hasData && !this.cancelado) {
        setTimeout(() => {
          this.next();
        }, 500);
        return;
      }
      this.close();
    };

    this.websocket.onopen = (_) => {
      this.onopen();
    };

    this.websocket.onerror = (_) => {
      this.onerror();
    };
  }

  close() {
    this.websocket.close();
  }

  next() {
    this.websocket.send(
      JSON.stringify({
        funcionalidade: this.funcionalidade,
        next: true,
      })
    );
  }
  buscar(filtros: IFiltros) {
    if (this.cancelado) return;
    if (this.websocket.readyState > this.websocket.OPEN) {
      this.websocket.close();
      this.connect(this.funcionalidade);
      return;
    }

    const message = this.formateDados(filtros);
    this.websocket.send(message);
  }

  cancelar() {
    if (this.cancelado) return;
    this.cancelado = true;
    this.websocket.send(
      JSON.stringify({
        funcionalidade: this.funcionalidade,
        cancelar: true,
      })
    );
    this.oncancel();
  }

  get pausado(): boolean {
    return this._pausado;
  }

  pausar() {
    this._pausado = true;
  }

  resumir() {
    this._pausado = false;
  }

  private formateDados(filtros: IFiltros): string {
    return JSON.stringify({
      funcionalidade: filtros.funcionalidade,
      nomeFuncionalidade: filtros.nomeFuncionalidade,
      host: filtros.host,
      port: filtros.porta,
      items: filtros.items,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
  }
}
