import { IItemTagValorAtual, IItemTagValoresHistoricos, IItemTagValorHistorico } from "../shared/interfaces/ItemTag";
import { IDadosConexaoHistorian } from "../shared/interfaces/dadosConexaoHistorian";
import { IValidationField } from "../shared/interfaces/ValidationField";
import axios from "axios";
import { get_url_api, isNotNuloOuUndefined, isNuloOuUndefined } from "./index";
import { IResponseTagExist } from "../shared/interfaces/IResponseTagExist";
import moment from "moment";
import { IMensagenDeErroTimestampInicioEFim } from "../shared/interfaces/IMensagenDeErroTimestampInicioEFim";
import {
  optionsFuncoesDeAgregacao,
  optionsMetodoDeInterpolacao,
  optionsUnidadesDeFrequencia,
} from "../pages/ValoresHistoricos/options/options";
import { ISelectOption } from "../shared/interfaces/ISelectOption";

/* global Excel  */

export async function VerificarSeEnderecoExisteNaPlanilha(endereco: string): Promise<IValidationField> {
  const re = new RegExp(/(.*)!([ A-Z]*[0-9]+)$/);
  let validRegex = re.test(endereco);
  if (!validRegex) {
    return { validationState: "error", validationMessage: "O endereço não esta num formato válido" };
  } else {
    const regexSplit = endereco.match(re);
    if (regexSplit.length == 3) {
      let planilha = regexSplit[1].replaceAll("'", "");
      let enderecoPlanilha = regexSplit[2];
      return await Excel.run(async (context) => {
        try {
          let sheet: Excel.Worksheet = context.workbook.worksheets.getItem(planilha);
          sheet.getRange(enderecoPlanilha).load("address");
          await context.sync();
          return { validationState: "none", validationMessage: "" };
        } catch (_) {
          return { validationState: "error", validationMessage: "Endereço não encontrado" };
        }
      })
        .then((result: IValidationField) => {
          return result;
        })
        .catch(() => {
          return { validationState: "error", validationMessage: "Falha ao verificar o endereço" };
        });
    } else {
      return { validationState: "error", validationMessage: "O endereço é inválido" };
    }
  }
}

async function ValidarTagExiste(tag: string, dadosConexao: IDadosConexaoHistorian): Promise<IValidationField> {
  let mensagensDeErroTag: IValidationField = { validationMessage: "", validationState: "none" };
  await axios
    .get<IResponseTagExist>(`${get_url_api()}/api/check_tag_exist_in_historian`, {
      params: { host: dadosConexao.host, port: dadosConexao.porta, tag: tag },
    })
    .then((resposta) => {
      if (!resposta.data.existe) {
        mensagensDeErroTag.validationState = "error";
        mensagensDeErroTag.validationMessage = "A tag '" + tag + "' não existe no Historian.";
      }
    })
    .catch(() => {
      mensagensDeErroTag.validationState = "error";
      mensagensDeErroTag.validationMessage = "Erro ao verificar existência da tag no Historian.";
    });
  return mensagensDeErroTag;
}

async function ValidarEnderecoLocalSaida(
  localSaidaDado: string,
  itens: (IItemTagValorAtual | IItemTagValorHistorico | IItemTagValoresHistoricos)[],
  nomePlanilhaDePersistenciasDosDados: string,
  editItem?: IItemTagValorAtual | IItemTagValorHistorico | IItemTagValoresHistoricos
): Promise<IValidationField> {
  let mensagensDeErroLocalSaida: IValidationField = { validationMessage: "", validationState: "none" };
  let enderecosLocalSaida = isNuloOuUndefined(editItem)
    ? itens.map((v) => v.localSaidaDado)
    : itens.filter((v) => v.id != editItem.id).map((v) => v.localSaidaDado);

  if (localSaidaDado.toLowerCase().includes(nomePlanilhaDePersistenciasDosDados.toLowerCase())) {
    mensagensDeErroLocalSaida.validationState = "error";
    mensagensDeErroLocalSaida.validationMessage =
      "Não pode ser utilizado o endereço da planilha de persistência do plugin";
  } else if (isNotNuloOuUndefined(enderecosLocalSaida.find((v) => v == localSaidaDado))) {
    mensagensDeErroLocalSaida.validationState = "error";
    mensagensDeErroLocalSaida.validationMessage = "Já existe um item usando esse local";
  } else {
    mensagensDeErroLocalSaida = await VerificarSeEnderecoExisteNaPlanilha(localSaidaDado);
  }
  return mensagensDeErroLocalSaida;
}

async function ValidarEnderecoLocalSaidaTimestamp(
  exportarTimestamp: boolean,
  localSaidaDado: string,
  localSaidaTimestamp: string | undefined,
  itens: IItemTagValorAtual[],
  nomePlanilhaDePersistenciasDosDados: string,
  editItem?: IItemTagValorAtual
): Promise<IValidationField> {
  let mensagensDeErroLocalSaidaTimestamp: IValidationField = { validationMessage: "", validationState: "none" };
  if (exportarTimestamp) {
    if (isNotNuloOuUndefined(localSaidaTimestamp) && localSaidaTimestamp != "") {
      if (localSaidaTimestamp.toLowerCase().includes(nomePlanilhaDePersistenciasDosDados.toLowerCase())) {
        mensagensDeErroLocalSaidaTimestamp.validationState = "error";
        mensagensDeErroLocalSaidaTimestamp.validationMessage =
          "Não pode ser utilizado o endereço da planilha de persistência do plugin";
      } else if (localSaidaDado == localSaidaTimestamp) {
        mensagensDeErroLocalSaidaTimestamp.validationState = "error";
        mensagensDeErroLocalSaidaTimestamp.validationMessage =
          "O local de saída do valor de timestamp não pode ser igual ao valor do local de saida do valor da tag";
      } else {
        let enderecosDeSaidaUtilizados: string[] = isNuloOuUndefined(editItem)
          ? Array.from(new Set(itens.map((value) => value.localSaidaDado)))
          : Array.from(new Set(itens.filter((value) => value.id !== editItem.id).map((value) => value.localSaidaDado)));
        let enderecosLocalSaidaTimestamp = isNuloOuUndefined(editItem)
          ? itens.map((v) => v.localSaidaTimestamp).filter((value) => value != "" && isNotNuloOuUndefined(value))
          : itens
              .filter(
                (v) => v.id != editItem.id && v.localSaidaTimestamp != "" && isNotNuloOuUndefined(v.localSaidaTimestamp)
              )
              .map((v) => v.localSaidaTimestamp);
        if (isNotNuloOuUndefined(enderecosDeSaidaUtilizados.find((v) => v == localSaidaTimestamp))) {
          mensagensDeErroLocalSaidaTimestamp.validationState = "error";
          mensagensDeErroLocalSaidaTimestamp.validationMessage =
            "Já existe um item usando esse local para salvar um valor de timestamp";
        } else if (isNotNuloOuUndefined(enderecosLocalSaidaTimestamp.find((v) => v == localSaidaTimestamp))) {
          mensagensDeErroLocalSaidaTimestamp.validationState = "error";
          mensagensDeErroLocalSaidaTimestamp.validationMessage = "Já existe um item usando esse local";
        } else {
          mensagensDeErroLocalSaidaTimestamp = await VerificarSeEnderecoExisteNaPlanilha(localSaidaTimestamp);
        }
      }
    } else {
      mensagensDeErroLocalSaidaTimestamp.validationState = "error";
      mensagensDeErroLocalSaidaTimestamp.validationMessage = "O local de saída do valor de timestamp é obrigatório";
    }
  }
  return mensagensDeErroLocalSaidaTimestamp;
}

async function ValidarEnderecoLocalSaidaTimestampValoresHistoricos(
  exportarTimestamp: boolean,
  localSaidaDado: string,
  localSaidaTimestamp: string,
  itens: IItemTagValoresHistoricos[],
  nomePlanilhaDePersistenciasDosDados: string,
  editItem?: IItemTagValoresHistoricos
): Promise<IValidationField> {
  let mensagensDeErroLocalSaidaTimestamp: IValidationField = { validationMessage: "", validationState: "none" };

  if (exportarTimestamp) {
    if (isNotNuloOuUndefined(localSaidaTimestamp) && localSaidaTimestamp != "") {
      if (localSaidaTimestamp.toLowerCase().includes(nomePlanilhaDePersistenciasDosDados.toLowerCase())) {
        mensagensDeErroLocalSaidaTimestamp.validationState = "error";
        mensagensDeErroLocalSaidaTimestamp.validationMessage =
          "Não pode ser utilizado o endereço da planilha de persistência do plugin";
      } else if (localSaidaDado == localSaidaTimestamp) {
        mensagensDeErroLocalSaidaTimestamp.validationState = "error";
        mensagensDeErroLocalSaidaTimestamp.validationMessage =
          "O local de saída do valor de timestamp não pode ser igual ao valor do local de saida do valor da tag";
      } else {
        let enderecosDeSaidaUtilizados: string[] = isNuloOuUndefined(editItem)
          ? Array.from(new Set(itens.map((value) => value.localSaidaDado)))
          : Array.from(new Set(itens.filter((value) => value.id !== editItem.id).map((value) => value.localSaidaDado)));
        let enderecosLocalSaidaTimestamp = isNuloOuUndefined(editItem)
          ? itens.map((v) => v.localSaidaTimestamp).filter((value) => value != "")
          : itens
              .filter(
                (v) => v.id != editItem.id && v.localSaidaTimestamp != "" && isNotNuloOuUndefined(v.localSaidaTimestamp)
              )
              .map((v) => v.localSaidaTimestamp);
        if (isNotNuloOuUndefined(enderecosDeSaidaUtilizados.find((v) => v == localSaidaTimestamp))) {
          mensagensDeErroLocalSaidaTimestamp.validationState = "error";
          mensagensDeErroLocalSaidaTimestamp.validationMessage =
            "Já existe um item usando esse local para salvar um valor de timestamp";
        } else if (isNotNuloOuUndefined(enderecosLocalSaidaTimestamp.find((v) => v == localSaidaTimestamp))) {
          mensagensDeErroLocalSaidaTimestamp.validationState = "error";
          mensagensDeErroLocalSaidaTimestamp.validationMessage = "Já existe um item usando esse local";
        } else {
          mensagensDeErroLocalSaidaTimestamp = await VerificarSeEnderecoExisteNaPlanilha(localSaidaTimestamp);
        }
      }
    } else {
      mensagensDeErroLocalSaidaTimestamp.validationState = "error";
      mensagensDeErroLocalSaidaTimestamp.validationMessage = "O local de saída do valor de timestamp é obrigatório";
    }
  }

  return mensagensDeErroLocalSaidaTimestamp;
}

export function ValidarTimestamp(timestamp: string) {
  const horaAtual: moment.Moment = moment();
  let mensagensDeTimestamp: IValidationField = { validationMessage: "", validationState: "none" };
  let timestampMoment: moment.Moment = moment(timestamp);
  if (!timestampMoment.isValid()) {
    mensagensDeTimestamp.validationState = "error";
    mensagensDeTimestamp.validationMessage = "Formato de timestamp inválido, formato deve ser 'DD/MM/YYYY HH:mm:ss'";
  } else {
    if (timestampMoment > horaAtual) {
      mensagensDeTimestamp.validationState = "error";
      mensagensDeTimestamp.validationMessage =
        "O timestamp não deve ser posterior a " + horaAtual.format("DD/MM/YYYY HH:mm:ss");
    }
  }
  return mensagensDeTimestamp;
}

export function ValidarTimestampInicioFim(
  timestampInicio: string,
  timestampFim: string
): IMensagenDeErroTimestampInicioEFim {
  let mensagensDeErroTimestampInicio: IValidationField = ValidarTimestamp(timestampInicio);
  let mensagensDeErroTimestampFim: IValidationField = ValidarTimestamp(timestampFim);

  if (
    mensagensDeErroTimestampInicio.validationState == "none" &&
    mensagensDeErroTimestampFim.validationState == "none"
  ) {
    let timestampMomentInicio: moment.Moment = moment(timestampInicio);
    let timestampMomentFim: moment.Moment = moment(timestampFim);

    if (timestampMomentInicio.isSame(timestampMomentFim)) {
      mensagensDeErroTimestampInicio = {
        validationState: "error",
        validationMessage: "O valor do timestamp de início não deve ser igual ao valor do timestamp de fim",
      };
    } else if (timestampMomentInicio.isAfter(timestampMomentFim)) {
      mensagensDeErroTimestampInicio = {
        validationState: "error",
        validationMessage: "O valor do timestamp de início não deve ser posterior ao valor do timestamp de fim",
      };
    }
  }

  return {
    mensagensDeErroTimestampInicio: mensagensDeErroTimestampInicio,
    mensagensDeErroTimestampFim: mensagensDeErroTimestampFim,
  };
}

export async function ValidacoesForm(
  exportarTimestamp: boolean,
  tag: string,
  localSaidaDado: string,
  localSaidaTimestamp: string | undefined,
  itens: IItemTagValorAtual[],
  dadosConexao: IDadosConexaoHistorian,
  nomePlanilhaDePersistenciasDosDados: string,
  editItem?: IItemTagValorAtual
) {
  let mensagensDeErroTag: IValidationField = await ValidarTagExiste(tag, dadosConexao);
  let mensagensDeErroLocalSaida: IValidationField = await ValidarEnderecoLocalSaida(
    localSaidaDado,
    itens,
    nomePlanilhaDePersistenciasDosDados,
    editItem
  );
  let mensagensDeErroLocalSaidaTimestamp: IValidationField = await ValidarEnderecoLocalSaidaTimestamp(
    exportarTimestamp,
    localSaidaDado,
    localSaidaTimestamp,
    itens,
    nomePlanilhaDePersistenciasDosDados,
    editItem
  );
  return { mensagensDeErroTag, mensagensDeErroLocalSaida, mensagensDeErroLocalSaidaTimestamp };
}

export async function ValidacoesFormValorHistorico(
  tag: string,
  localSaidaDado: string,
  timestamp: string,
  valorCalculado: boolean,
  timestampInicio: string,
  timestampFim: string,
  funcaoDeAgregacao: string,
  itens: IItemTagValorHistorico[],
  dadosConexao: IDadosConexaoHistorian,
  nomePlanilhaDePersistenciasDosDados: string,
  editItem?: IItemTagValorHistorico
) {
  let mensagensDeErroTag: IValidationField = await ValidarTagExiste(tag, dadosConexao);
  let mensagensDeErroLocalSaida: IValidationField = await ValidarEnderecoLocalSaida(
    localSaidaDado,
    itens,
    nomePlanilhaDePersistenciasDosDados,
    editItem
  );
  let mensagensDeErroTimestamp: IValidationField = !valorCalculado
    ? ValidarTimestamp(timestamp)
    : { validationMessage: "", validationState: "none" };
  let mensagensDeErroTimestamps: IMensagenDeErroTimestampInicioEFim = valorCalculado
    ? ValidarTimestampInicioFim(timestampInicio, timestampFim)
    : {
        mensagensDeErroTimestampFim: { validationMessage: "", validationState: "none" },
        mensagensDeErroTimestampInicio: { validationMessage: "", validationState: "none" },
      };
  let mensagensDeErroFuncaoDeAgregacao: IValidationField = valorCalculado
    ? validarDadosPorOpcoes(
        funcaoDeAgregacao,
        optionsFuncoesDeAgregacao,
        "O campo de função de agregação é obrigatório",
        "As funções de agregação disponíveis são: "
      )
    : { validationMessage: "", validationState: "none" };

  return {
    mensagensDeErroTag: mensagensDeErroTag,
    mensagensDeErroLocalSaida: mensagensDeErroLocalSaida,
    mensagensDeErroTimestamp: mensagensDeErroTimestamp,
    mensagensDeErroTimestampInicio: mensagensDeErroTimestamps.mensagensDeErroTimestampInicio,
    mensagensDeErroTimestampFim: mensagensDeErroTimestamps.mensagensDeErroTimestampFim,
    mensagensDeErroFuncaoDeAgregacao: mensagensDeErroFuncaoDeAgregacao,
  };
}

export function validarDadosPorOpcoes(
  valor: string,
  opcoes: ISelectOption[],
  erroStringObrigatorio: string,
  erroStringValoresDisponiveis: string
): IValidationField {
  let mensagensDeErro: IValidationField = { validationMessage: "", validationState: "none" };
  if (isNuloOuUndefined(valor) || valor == "") {
    mensagensDeErro.validationState = "error";
    mensagensDeErro.validationMessage = erroStringObrigatorio;
  } else {
    const valoresDisponiveisUnidadeDeFrequencia: string[] = opcoes.map((value) => value.value);
    if (!valoresDisponiveisUnidadeDeFrequencia.includes(valor)) {
      mensagensDeErro.validationState = "error";
      mensagensDeErro.validationMessage =
        erroStringValoresDisponiveis + valoresDisponiveisUnidadeDeFrequencia.join(",");
    }
  }
  return mensagensDeErro;
}

export function ValidarFormAgrupamentoDeDadosPorPeriodo(
  agruparDadosPorPeriodo: boolean,
  unidadeDeFrequencia: string,
  frequencia: string,
  funcaoDeAgregacao: string,
  usarInterpolacao: boolean,
  metodoDeInterpolacao: string
) {
  let mensagensDeErroUnidadeDeFrequencia: IValidationField = { validationMessage: "", validationState: "none" };
  let mensagensDeErroFrequencia: IValidationField = { validationMessage: "", validationState: "none" };
  let mensagensDeErroFuncaoDeAgregacao: IValidationField = { validationMessage: "", validationState: "none" };
  let mensagensDeErroMetodoDeInterpolacao: IValidationField = { validationMessage: "", validationState: "none" };

  if (agruparDadosPorPeriodo) {
    if (isNuloOuUndefined(frequencia) || frequencia == "") {
      mensagensDeErroFrequencia.validationState = "error";
      mensagensDeErroFrequencia.validationMessage =
        "O campo de frequência é obrigatório e deve ser um número inteiro maior que 1";
    } else {
      try {
        const valorFrequenciaInteiro = parseInt(frequencia);
        if (valorFrequenciaInteiro <= 0) {
          mensagensDeErroFrequencia.validationState = "error";
          mensagensDeErroFrequencia.validationMessage = "A frequência deve ser um número inteiro maior que 1";
        }
      } catch {
        mensagensDeErroFrequencia.validationState = "error";
        mensagensDeErroFrequencia.validationMessage = "A frequência deve ser um número inteiro maior que 1";
      }
    }
    mensagensDeErroUnidadeDeFrequencia = validarDadosPorOpcoes(
      unidadeDeFrequencia,
      optionsUnidadesDeFrequencia,
      "O campo de unidade de frequência é obrigatório",
      "As unidades de frequência disponíveis são: "
    );

    mensagensDeErroFuncaoDeAgregacao = validarDadosPorOpcoes(
      funcaoDeAgregacao,
      optionsFuncoesDeAgregacao,
      "O campo de função de agregação é obrigatório",
      "As funções de agregação disponíveis são: "
    );

    if (usarInterpolacao) {
      mensagensDeErroMetodoDeInterpolacao = validarDadosPorOpcoes(
        metodoDeInterpolacao,
        optionsMetodoDeInterpolacao,
        "O campo de método de interpolação é obrigatório",
        "Os métodos de interpolação disponíveis são: "
      );
    }
  } else {
    if (isNotNuloOuUndefined(unidadeDeFrequencia) && unidadeDeFrequencia !== "") {
      mensagensDeErroUnidadeDeFrequencia.validationState = "error";
      mensagensDeErroUnidadeDeFrequencia.validationMessage =
        "O valor do campo 'unidadeDeFrequencia' deve ser vazio quando não for utilizado o agrupamento por período";
    }
    if (isNotNuloOuUndefined(frequencia) && frequencia !== "") {
      mensagensDeErroFrequencia.validationState = "error";
      mensagensDeErroFrequencia.validationMessage =
        "O valor do campo 'frequencia' deve ser vazio quando não for utilizado o agrupamento por período";
    }
    if (isNotNuloOuUndefined(funcaoDeAgregacao) && funcaoDeAgregacao !== "") {
      mensagensDeErroFuncaoDeAgregacao.validationState = "error";
      mensagensDeErroFuncaoDeAgregacao.validationMessage =
        "O valor do campo 'funcaoDeAgregacao' deve ser vazio quando não for utilizado o agrupamento por período";
    }
    if (isNotNuloOuUndefined(metodoDeInterpolacao) && metodoDeInterpolacao !== "") {
      mensagensDeErroMetodoDeInterpolacao.validationState = "error";
      mensagensDeErroMetodoDeInterpolacao.validationMessage =
        "O valor do campo 'metodoDeInterpolacao' deve ser vazio quando não for utilizado o agrupamento por período";
    }
  }

  return {
    mensagensDeErroUnidadeDeFrequencia,
    mensagensDeErroFrequencia,
    mensagensDeErroFuncaoDeAgregacao,
    mensagensDeErroMetodoDeInterpolacao,
  };
}

export async function ValidacoesFormValoresHistoricos(
  tag: string,
  localSaidaValor: string,
  exportarTimestamp: boolean,
  localSaidaTimestamp: string,
  timestampInicio: string,
  timestampFim: string,
  agruparDadosPorPeriodo: boolean,
  unidadeDeFrequencia: string,
  frequencia: string,
  funcaoDeAgregacao: string,
  usarInterpolacao: boolean,
  metodoDeInterpolacao: string,
  itens: IItemTagValoresHistoricos[],
  dadosConexao: IDadosConexaoHistorian,
  nomePlanilhaDePersistenciasDosDados: string,
  editItem?: IItemTagValoresHistoricos
) {
  let mensagensDeErroTag: IValidationField = await ValidarTagExiste(tag, dadosConexao);
  let mensagensDeErroLocalSaidaValor: IValidationField = await ValidarEnderecoLocalSaida(
    localSaidaValor,
    itens,
    nomePlanilhaDePersistenciasDosDados,
    editItem
  );
  let mensagensDeErroLocalSaidaTimestamp: IValidationField = await ValidarEnderecoLocalSaidaTimestampValoresHistoricos(
    exportarTimestamp,
    localSaidaValor,
    localSaidaTimestamp,
    itens,
    nomePlanilhaDePersistenciasDosDados,
    editItem
  );
  let mensagensDeErroTimestamps = ValidarTimestampInicioFim(timestampInicio, timestampFim);
  let mensagensDeErroAgrupamentoPorPeriodo = ValidarFormAgrupamentoDeDadosPorPeriodo(
    agruparDadosPorPeriodo,
    unidadeDeFrequencia,
    frequencia,
    funcaoDeAgregacao,
    usarInterpolacao,
    metodoDeInterpolacao
  );
  return {
    mensagensDeErroTag: mensagensDeErroTag,
    mensagensDeErroLocalSaidaValor: mensagensDeErroLocalSaidaValor,
    mensagensDeErroLocalSaidaTimestamp: mensagensDeErroLocalSaidaTimestamp,
    mensagensDeErroTimestampInicio: mensagensDeErroTimestamps.mensagensDeErroTimestampInicio,
    mensagensDeErroTimestampFim: mensagensDeErroTimestamps.mensagensDeErroTimestampFim,
    mensagensDeErroUnidadeDeFrequencia: mensagensDeErroAgrupamentoPorPeriodo.mensagensDeErroUnidadeDeFrequencia,
    mensagensDeErroFrequencia: mensagensDeErroAgrupamentoPorPeriodo.mensagensDeErroFrequencia,
    mensagensDeErroFuncaoDeAgregacao: mensagensDeErroAgrupamentoPorPeriodo.mensagensDeErroFuncaoDeAgregacao,
    mensagensDeErroMetodoDeInterpolacao: mensagensDeErroAgrupamentoPorPeriodo.mensagensDeErroMetodoDeInterpolacao,
  };
}
