export class CancelarState{
    cancelarValoresHistoricos: boolean = false;
    cancelarValorHistoricos: boolean = false;
    cancelarValorAtual: boolean = false;


    constructor(){}

    setCancelar = (nome: string, value: boolean) => {
        switch(nome){
            case "valor_atual": this.cancelarValorAtual = value; break;
            case "valor_historico": this.cancelarValorHistoricos = value; break;
            case "valores_historicos": this.cancelarValoresHistoricos = value; break;
        }
    }
    
    get = (nome): () => boolean => {
        switch(nome){
            case "valor_atual": return this.valorAtual;
            case "valor_historico": return this.valorHistorico;
            case "valores_historicos": return this.valoresHistoricos;
            default: return null
        }    
    }

    valoresHistoricos = (): boolean => {
        return this.cancelarValoresHistoricos;
    }
    
    valorHistorico = (): boolean => {
        return this.cancelarValorHistoricos;
    }

    valorAtual = (): boolean => {
        return this.cancelarValorAtual;
    }
}