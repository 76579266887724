import {
  Link,
  Toast,
  ToastBody,
  Toaster,
  ToastTitle,
  ToastTrigger,
  useId,
  useToastController,
} from "@fluentui/react-components";
import { useState } from "react";
import { IDadosConexaoHistorian } from "../../interfaces/dadosConexaoHistorian";
import { Carregamento } from "../../interfaces/carregamento";
import { DismissCircleFilled } from "@fluentui/react-icons";
import * as React from "react";
import BaseComponent from "../BaseComponent/BaseComponent";
import BaseCrudComponent from "../BaseCrudComponent/BaseCrudComponent";
import {
  addComentario,
  removeAllComentariosInWorksheetByFuncionalidade,
} from "../../../utils/adicionarComentarioExcel";

import { IItemTagValorAtual, IItemTagValoresHistoricos, IItemTagValorHistorico } from "../../interfaces/ItemTag";
import { criarWorksheet, verificarSeAtabelaFoiCriadaEAdicionarValores } from "../../../utils/persistirDadosSheet";
import { IResultadoMapeamento } from "../../interfaces/IResultadoMapeamento";
import { IItemTagAdressValorAtual } from "../../../pages/ValorAtual/interfaces/IItemTagAdressValorAtual";
import { IItemTagAdressValorHistorico } from "../../../pages/ValorHistorico/interfaces/IItemTagAdressValorHistorico";
import { ResponseValorHistorico } from "../../interfaces/responses/responseValorHistorico";
import { ResponseValorAtual } from "../../interfaces/responses/responseValorAtual";
import { obterDadosPlanilha } from "../../../utils/planilha";
import { ResponseValoresHistoricos } from "../../interfaces/responses/responseValoresHistoricos";
import { IResponsePreRemoveItem } from "../../interfaces/IResponsePreRemoveItem";
import { IItemTagAdressValoresHistoricos } from "../../../pages/ValoresHistoricos/interfaces/IItemTagAdressValoresHistoricos";
import { get_url_websocket, isNotNuloOuUndefined } from "../../../utils";
import { CancelarState } from "../../../utils/Cancelar";

interface IProps {
  nomeWorksheetPersistData: string;
  tableName: string;
  colunas: string[];
  funcionalidade: string;
  nomeFuncionalidade: string;
  nomeFuncionalidadeExibicao: string;
  mensagemCarregamentoDadosDaFuncionalidade: string;
  mensagemCarregamentoDadosDaFuncionalidadeSelecionado: string;
  aplicarLabel?: string;
  aplicarLabelSelecionado?: string;
  styleTable:
    | "TableStyleLight8"
    | "TableStyleLight9"
    | "TableStyleLight10"
    | "TableStyleLight11"
    | "TableStyleLight12"
    | "TableStyleLight13"
    | "TableStyleLight14";
  addressColunas: string[];
  urlApiObtencaoDados: string;
  validarDadosFuncionalidadeEMapearResultados: (
    dadosConexaoHistorian: IDadosConexaoHistorian,
    valores: (IItemTagAdressValorAtual | IItemTagAdressValorHistorico | IItemTagAdressValoresHistoricos)[],
    nomePlanilhaDePersistencia: string
  ) => Promise<IResultadoMapeamento>;
  montarStringRegistroTable: (
    registro: IItemTagValorAtual | IItemTagValorHistorico | IItemTagValoresHistoricos
  ) => (string | number)[];
  montarNumberFormatRegistroTable: () => string[];
  valorApply: (
    itens: (ResponseValorAtual | ResponseValorHistorico | ResponseValoresHistoricos)[],
    cancelar: () => boolean
  ) => Promise<string[]>;
  preRemoveItem: (
    item: IItemTagValorAtual | IItemTagValorHistorico | IItemTagValoresHistoricos,
    urlApiObtencaoDados?: string,
    dadosConexaoHistorian?: IDadosConexaoHistorian
  ) => Promise<IResponsePreRemoveItem>;
}

let websocket!: WebSocket;
let cancelar: CancelarState = new CancelarState();

export default function TaskPanelFunctionComponent({
  nomeWorksheetPersistData,
  tableName,
  colunas,
  funcionalidade,
  nomeFuncionalidade,
  nomeFuncionalidadeExibicao,
  mensagemCarregamentoDadosDaFuncionalidade,
  mensagemCarregamentoDadosDaFuncionalidadeSelecionado,
  aplicarLabel,
  aplicarLabelSelecionado,
  styleTable,
  addressColunas,
  urlApiObtencaoDados,
  validarDadosFuncionalidadeEMapearResultados,
  montarStringRegistroTable,
  montarNumberFormatRegistroTable,
  valorApply,
  preRemoveItem,
}: IProps) {
  const [subtitle, setSubtitle] = useState<string>("");
  const [mostrarCancelar, setMostrarCancelar] = useState<boolean>(false);
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const [dadosConexaoHistorian, setDadosConexaoHistorian] = useState<null | IDadosConexaoHistorian>(null);
  const [exibirReloadDados, setExibirReloadDados] = useState<boolean>(false);
  const [itensTag, setItensTag] = useState<(IItemTagValorAtual | IItemTagValorHistorico | IItemTagValoresHistoricos)[]>(
    []
  );
  const [selected, setSelected] = useState<
    IItemTagValorAtual | IItemTagValorHistorico | IItemTagValoresHistoricos | null
  >(itensTag[0]);
  const [exibirCarregamentoInicial, setExibirCarregamentoInicial] = useState<Carregamento>({
    carregando: true,
    mensagem: "Carregando dados",
  });
  const [exibirCarregamentoExecucaoDeFuncionalidade, setExibirCarregamentoExecucaoDeFuncionalidade] =
    useState<Carregamento>({
      carregando: false,
      mensagem: "",
    });

  function notificarErro(e: string) {
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>
                <DismissCircleFilled />
              </Link>
            </ToastTrigger>
          }
        >
          Erro
        </ToastTitle>
        <ToastBody>{e}</ToastBody>
      </Toast>,
      { intent: "error" }
    );
  }

  function recuperarDadosPlanilha(notificar: boolean = false) {
    setSubtitle("");
    setExibirCarregamentoInicial({ carregando: true, mensagem: "Obtendo dados" });
    setTimeout(async () => {
      try {
        let dadosPresentaNaPlanilha = await obterDadosPlanilha(
          funcionalidade,
          dadosConexaoHistorian,
          nomeWorksheetPersistData,
          tableName,
          colunas,
          addressColunas,
          validarDadosFuncionalidadeEMapearResultados
        );

        try {
          await removeAllComentariosInWorksheetByFuncionalidade(nomeWorksheetPersistData, funcionalidade);
        } catch (e) {
          if (notificar) {
            notificarErro("Falha ao remover todos os comentários já existentes");
          }
        }

        if (dadosPresentaNaPlanilha.erros.length > 0 && notificar) {
          let errosAoComentar = 0;
          try {
            for (const value of dadosPresentaNaPlanilha.erros) {
              await addComentario(nomeWorksheetPersistData, value.address, value.errro, funcionalidade);
            }
          } catch (e) {
            errosAoComentar += 1;
          }
          if (errosAoComentar > 0) {
            notificarErro("Não foi possivel adicionar o cometário de " + errosAoComentar + " erros.");
          }
          notificarErro(
            "Existêm erros nos itens a serem importados, por favor verifique os comentários presentes na tabela em AthenasHistorianPluginData."
          );
        }
        if (dadosPresentaNaPlanilha.itensValidos.length > 0) {
          setItensTag([...dadosPresentaNaPlanilha.itensValidos]);
          setSelected([...dadosPresentaNaPlanilha.itensValidos][0]);
        } else {
          setItensTag([]);
          setSelected(null);
        }
        setExibirCarregamentoInicial({ carregando: false, mensagem: "" });
        setExibirReloadDados(true);
      } catch (error) {
        if (notificar) {
          notificarErro(error.toString());
        }
        setExibirCarregamentoInicial({ carregando: false, mensagem: "" });
      }
    }, 1000);
  }

  function persistirDados(removerItensTabela: boolean = false) {
    setSubtitle("");
    setExibirCarregamentoExecucaoDeFuncionalidade({ mensagem: "Salvando", carregando: true });
    setTimeout(async () => {
      try {
        await criarWorksheet(nomeWorksheetPersistData);
        await verificarSeAtabelaFoiCriadaEAdicionarValores(
          funcionalidade,
          addressColunas,
          styleTable,
          itensTag,
          colunas,
          nomeWorksheetPersistData,
          tableName,
          montarStringRegistroTable,
          montarNumberFormatRegistroTable,
          removerItensTabela
        );
        setExibirCarregamentoExecucaoDeFuncionalidade({ mensagem: "", carregando: false });
        setExibirReloadDados(true);
      } catch (e) {
        notificarErro(e.toString());
        setExibirCarregamentoExecucaoDeFuncionalidade({ mensagem: "", carregando: false });
      }
    }, 1000);
  }
  async function obterDadosEAplicarValoresObtidosFuncionalidade() {
    await obterDadosCanal(itensTag, mensagemCarregamentoDadosDaFuncionalidade);
  }

  async function obterDadosEAplicarValoresObtidosFuncionalidadeItenSelecionado() {
    await obterDadosCanal([selected], mensagemCarregamentoDadosDaFuncionalidadeSelecionado);
  }
  async function obterDadosEAplicarValoresObtidosFuncionalidadeItenSelecionadoEmDemanda() {
    await obterDadosCanal(itensTag, mensagemCarregamentoDadosDaFuncionalidadeSelecionado);
  }

  async function obterDadosCanal(
    items: (IItemTagValorAtual | IItemTagValorHistorico | IItemTagValoresHistoricos)[],
    mensagemCarregamentoDados: string
  ) {
    const message = JSON.stringify({
      funcionalidade: nomeFuncionalidade,
      host: dadosConexaoHistorian.host,
      port: dadosConexaoHistorian.porta,
      items: items,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    await obterDadosWebsocket(message, mensagemCarregamentoDados);
  }

  function sendMessageWebsocket(websocket: WebSocket, message: string) {
    if (websocket.readyState === WebSocket.OPEN) {
      websocket.send(message);
    } else {
      setTimeout(() => {
        sendMessageWebsocket(websocket, message);
      }, 5000);
    }
  }

  async function obterDadosWebsocket(message: string, mensagemCarregamentoDados: string) {
    websocket = new WebSocket(`${get_url_websocket()}/channel-valores-historicos?name=${nomeFuncionalidade}`);
    if (cancelar.get(nomeFuncionalidade)()) {
      cancelar.setCancelar(nomeFuncionalidade, false);
    }
    setMostrarCancelar(true);

    setExibirCarregamentoExecucaoDeFuncionalidade({
      carregando: true,
      mensagem: mensagemCarregamentoDados,
    });

    websocket.onopen = () => {
      sendMessageWebsocket(websocket, message);
    };

    websocket.onmessage = async (event) => {
      if (cancelar.get(nomeFuncionalidade)()) {
        return;
      }

      let message = JSON.parse(event.data);

      if (message.ready) {
        sendMessageWebsocket(
          websocket,
          JSON.stringify({
            funcionalidade: nomeFuncionalidade,
            next: true,
          })
        );
        setSubtitle(`Baixados 0 de ${message.total} dados`);
        return;
      }

      let data = message.data;
      let recebido = message.received_count;
      let total_recebido = message.atual;

      try {
        if (isNotNuloOuUndefined(recebido)) {
          setSubtitle(`Aplicando ${recebido} dados`);
        }
        if (!exibirCarregamentoExecucaoDeFuncionalidade.carregando) {
          setExibirCarregamentoExecucaoDeFuncionalidade({
            carregando: true,
            mensagem: `Aplicando ${nomeFuncionalidadeExibicao.toLowerCase()}`,
          });
        }
        setTimeout(async () => {
          await aplicarFuncionalidade(data).then(() => {
            if (cancelar.get(nomeFuncionalidade)()) {
              return;
            }

            if (message.hasData) {
              setSubtitle(`Baixados ${total_recebido} de ${message.total} dados`);
              if (!exibirCarregamentoExecucaoDeFuncionalidade.carregando) {
                setExibirCarregamentoExecucaoDeFuncionalidade({
                  carregando: true,
                  mensagem: `Aplicando ${nomeFuncionalidadeExibicao.toLowerCase()}`,
                });
              }
              setMostrarCancelar(true);
              setTimeout(() => {
                sendMessageWebsocket(websocket, JSON.stringify({ funcionalidade: nomeFuncionalidade, next: true }));
              }, 1000);
            } else {
              setMostrarCancelar(false);
              setExibirCarregamentoExecucaoDeFuncionalidade({ carregando: false, mensagem: "" });
              setSubtitle("");
              websocket.close(1000);
            }
          });
        }, 1000);
      } catch (e) {
        setMostrarCancelar(false);
        setExibirCarregamentoExecucaoDeFuncionalidade({ carregando: false, mensagem: "" });
        setSubtitle("");
        notificarErro(`Não foi possivel obter o ${nomeFuncionalidadeExibicao.toLowerCase()}`);
      }
    };
    websocket.onerror = (ev) => {
      cancelar.setCancelar(nomeFuncionalidade, true);
      setMostrarCancelar(false);
      setExibirCarregamentoExecucaoDeFuncionalidade({ carregando: false, mensagem: "" });
      setSubtitle("");
      notificarErro(`Não foi possivel se conectar ao servidor`);
      console.error(ev);
    };
    websocket.onclose = (ev) => {
      if (ev && ev.code != 1000) {
        notificarErro(`Plugin foi desconectado do servidor. Tente novamente`);
        console.error(ev);
      }
      setMostrarCancelar(false);
      setExibirCarregamentoExecucaoDeFuncionalidade({ carregando: false, mensagem: "" });
      setSubtitle("");
    };
  }

  async function aplicarFuncionalidade(
    itens: (ResponseValorAtual | ResponseValorHistorico | ResponseValoresHistoricos)[]
  ) {
    let erros = await valorApply(itens, cancelar.get(nomeFuncionalidade));
    if (erros.length > 0) {
      erros.forEach((value) => notificarErro(value));
    }
  }

  function cancelarRequisicao() {
    if (websocket.readyState == websocket.OPEN) {
      cancelar.setCancelar(nomeFuncionalidade, true);

      setMostrarCancelar(false);
      setExibirCarregamentoExecucaoDeFuncionalidade({ carregando: false, mensagem: "" });
      setSubtitle("");
      websocket.close(1000);
    }
  }
  return (
    <>
      <BaseComponent
        setDadosConexaoHistorianOutput={setDadosConexaoHistorian}
        content={
          <BaseCrudComponent
            funcionalidade={funcionalidade}
            aplicarLabel={aplicarLabel}
            aplicarLabelSelecionado={aplicarLabelSelecionado}
            urlApiObtencaoDados={urlApiObtencaoDados}
            dadosConexaoHistorian={dadosConexaoHistorian}
            setItensTag={setItensTag}
            selected={selected}
            setSelected={setSelected}
            exibirReloadDados={exibirReloadDados}
            itensTag={itensTag}
            exibirCarregamentoInicial={exibirCarregamentoInicial}
            exibirCarregamentoExecucaoDeFuncionalidade={exibirCarregamentoExecucaoDeFuncionalidade}
            persistirDados={persistirDados}
            recuperarDadosPlanilha={recuperarDadosPlanilha}
            aplicar={obterDadosEAplicarValoresObtidosFuncionalidade}
            aplicarSelecionado={obterDadosEAplicarValoresObtidosFuncionalidadeItenSelecionado}
            aplicarEmDemanda={obterDadosEAplicarValoresObtidosFuncionalidadeItenSelecionadoEmDemanda}
            preRemoveItem={preRemoveItem}
            nomePlanilhaDePersistenciasDosDados={nomeWorksheetPersistData}
            notificarErro={notificarErro}
            cancelar={cancelarRequisicao}
            mostrarCancelar={mostrarCancelar}
            subtitle={subtitle}
          />
        }
      />
      <Toaster toasterId={toasterId} position={"top-end"} timeout={5000} />
    </>
  );
}
