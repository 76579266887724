import { IItemTagValoresHistoricos } from "../../../../shared/interfaces/ItemTag";
import { isNotNuloOuUndefined, obterLabelSelectPorValoresDisponiveis } from "../../../../utils";
import { Checkbox, Field, Input, Radio, RadioGroup } from "@fluentui/react-components";
import * as React from "react";
import {
  optionsFuncoesDeAgregacao,
  optionsMetodoDeInterpolacao,
  optionsUnidadesDeFrequencia,
} from "../../options/options";

interface IProps {
  item: IItemTagValoresHistoricos | null;
}

export function ListaFieldsValoresHistoricos({ item }: IProps) {
  return (
    <>
      {isNotNuloOuUndefined(item) && (
        <>
          <Field label={"Timestamp de início da consulta"}>
            <Input type={"datetime-local"} step="1" value={item.timestampInicio} disabled={true}></Input>
          </Field>
          <Field label={"Timestamp de fim da consulta"}>
            <Input type={"datetime-local"} step="1" value={item.timestampFim} disabled={true}></Input>
          </Field>
          <Field>
            <Checkbox className="noOutline" disabled checked={item.exportarTimestamp} label="Exportar timestamp" />
          </Field>
          {item.exportarTimestamp && (
            <Field label={"Local de saída do timestamp"}>
              <Input type={"text"} value={item.localSaidaTimestamp} disabled={true}></Input>
            </Field>
          )}
          <Field label={"Local de saída do dado"}>
            <Input type={"text"} value={item.localSaidaDado} disabled={true}></Input>
          </Field>
          <Field>
            <Checkbox
              className="noOutline"
              disabled
              checked={item.agruparDadosPorPeriodo}
              label="Agrupar dados por período"
            />
          </Field>
          {item.agruparDadosPorPeriodo && (
            <>
              <Field label={"Unidade de frequência"}>
                <Input
                  type={"text"}
                  value={obterLabelSelectPorValoresDisponiveis(item.unidadeDeFrequencia, optionsUnidadesDeFrequencia)}
                  disabled={true}
                ></Input>
              </Field>
              <Field label={"Frequência"}>
                <Input type={"number"} step={1} min={1} value={item.frequencia.toString()} disabled={true}></Input>
              </Field>
              <Field label={"Função de agregação"}>
                <Input
                  type={"text"}
                  value={obterLabelSelectPorValoresDisponiveis(item.funcaoDeAgregacao, optionsFuncoesDeAgregacao)}
                  disabled={true}
                ></Input>
              </Field>
              <Field>
                <Checkbox className="noOutline" disabled checked={item.usarInterpolacao} label="Usar interpolação" />
              </Field>
              {item.usarInterpolacao && (
                <Field label={"Método de interpolação"} required>
                  <RadioGroup value={item.metodoDeInterpolacao} disabled={true}>
                    {optionsMetodoDeInterpolacao.map((value) => {
                      return (
                        <Radio
                          key={value.id}
                          className="noOutline"
                          value={value.value}
                          label={value.name}
                          disabled={true}
                        />
                      );
                    })}
                  </RadioGroup>
                </Field>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
