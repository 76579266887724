/* global  Excel, Office */

import moment from "moment";
import { obterColorTitleTabelaByStyleTable } from "./index";
import { IItemTagValorAtual, IItemTagValoresHistoricos, IItemTagValorHistorico } from "../shared/interfaces/ItemTag";
import { ITabelaWorksheet } from "../shared/interfaces/ITabelaWorksheet";

export async function criarWorksheet(worksheetName: string) {
  await Excel.run(async (context) => {
    let sheets = context.workbook.worksheets;
    sheets.load("items/name");

    await context.sync();

    const lista: string[] = sheets.items.map((l) => l.name);
    if (!lista.includes(worksheetName)) {
      context.workbook.worksheets.add(worksheetName);
    }
    await context.sync();
  });
}

export async function removeLinhasDaTabela(context: Excel.RequestContext, tabela: Excel.Table) {
  let rangeValoresDaTabela = tabela.getDataBodyRange();
  rangeValoresDaTabela.load("values");
  await context.sync();

  const linhas: string[][] = rangeValoresDaTabela.values.map((l) => l.map((v) => v.toString()));
  const linhasFiltradas: string[][] = linhas.filter((l) => l[0] != "");

  if (linhasFiltradas.length > 0) {
    rangeValoresDaTabela.delete(Excel.DeleteShiftDirection.up);
  }
  await context.sync();
}

export async function verificarSeAtabelaFoiCriadaEAdicionarValores(
  funcionalidade: string,
  addressColunas: string[],
  styleTable:
    | "TableStyleLight8"
    | "TableStyleLight9"
    | "TableStyleLight10"
    | "TableStyleLight11"
    | "TableStyleLight12"
    | "TableStyleLight13"
    | "TableStyleLight14",
  registros: (IItemTagValorAtual | IItemTagValorHistorico | IItemTagValoresHistoricos)[],
  colunas: string[],
  worksheetName: string,
  tableExportName: string,
  funcaoMapeamentoString: (arg0: any) => (string | number)[],
  funcaoMapeamentoNumberFormat: () => string[],
  removerItensTabela: boolean = false
) {
  await Excel.run(async (context) => {
    let tabelasDaWorksheetDestino = context.workbook.worksheets.getItem(worksheetName).tables;
    tabelasDaWorksheetDestino.load("items/name");

    await context.sync();

    const listaDeNomeDeTodasAsTabelasNaWorksheetDeDestino: string[] = tabelasDaWorksheetDestino.items.map(
      (l) => l.name
    );

    let todasAsTabelasNoDocumento = context.workbook.tables;
    todasAsTabelasNoDocumento.load("items/name");

    await context.sync();

    const listaNomesTodasTabelasDocumento: string[] = todasAsTabelasNoDocumento.items.map((l) => l.name);

    if (!listaDeNomeDeTodasAsTabelasNaWorksheetDeDestino.includes(tableExportName)) {
      if (listaNomesTodasTabelasDocumento.includes(tableExportName)) {
        const tabelaComONomeWorksheetDestino = todasAsTabelasNoDocumento.getItem(tableExportName);
        tabelaComONomeWorksheetDestino.load("worksheet/name");
        await context.sync();
        tabelaComONomeWorksheetDestino.name =
          tabelaComONomeWorksheetDestino.worksheet.name + moment().valueOf().toString();
        await context.sync();
      }
      await criarTabelaTagsEAdicionarValores(
        funcionalidade,
        addressColunas,
        styleTable,
        registros,
        colunas,
        worksheetName,
        tableExportName,
        funcaoMapeamentoString,
        funcaoMapeamentoNumberFormat
      );
    } else if (registros.length > 0) {
      await adicionarValoresTabelaTags(
        registros,
        worksheetName,
        tableExportName,
        funcaoMapeamentoString,
        funcaoMapeamentoNumberFormat
      );
    } else if (removerItensTabela) {
      let tabela = tabelasDaWorksheetDestino.getItem(tableExportName);
      await removeLinhasDaTabela(context, tabela);
    }
    await context.sync();
  });
}

export async function criarTabelaTagsEAdicionarValores(
  funcionalidade: string,
  addressColunas: string[],
  styleTable:
    | "TableStyleLight8"
    | "TableStyleLight9"
    | "TableStyleLight10"
    | "TableStyleLight11"
    | "TableStyleLight12"
    | "TableStyleLight13"
    | "TableStyleLight14",
  registros: (IItemTagValorAtual | IItemTagValorHistorico | IItemTagValoresHistoricos)[],
  colunas: string[],
  worksheetName: string,
  tableExportName: string,
  funcaoMapeamentoString: (arg0: any) => (string | number)[],
  funcaoMapeamentoNumberFormat: () => string[]
) {
  await Excel.run(async (context) => {
    let sheet = context.workbook.worksheets.getItem(worksheetName);
    addTituloLineInTable(sheet, addressColunas, funcionalidade, styleTable);
    let expensesTable = sheet.tables.add(`${addressColunas[0]}2:${addressColunas[addressColunas.length - 1]}2`, true);
    expensesTable.name = tableExportName;
    expensesTable.style = styleTable;
    expensesTable.getRange().format.horizontalAlignment = Excel.HorizontalAlignment.center;

    expensesTable.getHeaderRowRange().values = [colunas];
    if (registros.length > 0) {
      const stringRegistros: (string | number)[][] = registros.map((r) => funcaoMapeamentoString(r));
      expensesTable.getRange().numberFormat = [["@"]];
      expensesTable.rows.add(undefined, stringRegistros);
      await context.sync();
      expensesTable.getDataBodyRange().numberFormat = registros.map(() => funcaoMapeamentoNumberFormat());
    }
    if (Office.context.requirements.isSetSupported("ExcelApi", "1.2")) {
      sheet.getUsedRange().format.autofitColumns();
      sheet.getUsedRange().format.autofitRows();
    }

    await context.sync();
  });
}

export async function adicionarValoresTabelaTags(
  registros: (IItemTagValorAtual | IItemTagValorHistorico | IItemTagValoresHistoricos)[],
  worksheetName: string,
  tableExportName: string,
  funcaoMapeamentoString: (arg0: any) => (string | number)[],
  funcaoMapeamentoNumberFormat: () => string[]
) {
  await Excel.run(async (context) => {
    let sheet = context.workbook.worksheets.getItem(worksheetName);
    let tabela = sheet.tables.getItem(tableExportName);

    await removeLinhasDaTabela(context, tabela);

    let stringTags: (string | number)[][] | undefined = registros.map((r) => funcaoMapeamentoString(r));
    if (stringTags.length > 0) {
      tabela.getRange().numberFormat = [["@"]];
      tabela.rows.add(undefined, stringTags, true);
      await context.sync();
      tabela.getDataBodyRange().numberFormat = registros.map(() => funcaoMapeamentoNumberFormat());
      if (Office.context.requirements.isSetSupported("ExcelApi", "1.2")) {
        sheet.getUsedRange().format.autofitColumns();
        sheet.getUsedRange().format.autofitRows();
      }
    }

    await context.sync();
  });
}

function addTituloLineInTable(
  sheet: Excel.Worksheet,
  addressColunas: string[],
  funcionalidade: string,
  styleTable:
    | "TableStyleLight8"
    | "TableStyleLight9"
    | "TableStyleLight10"
    | "TableStyleLight11"
    | "TableStyleLight12"
    | "TableStyleLight13"
    | "TableStyleLight14"
) {
  try {
    sheet.getRange(`${addressColunas[0]}1`).values = [[funcionalidade]];
    let rangeTituloTabela = sheet.getRange(`${addressColunas[0]}1:${addressColunas[addressColunas.length - 1]}1`);
    rangeTituloTabela.merge();
    let cores = obterColorTitleTabelaByStyleTable(styleTable);
    rangeTituloTabela.format.horizontalAlignment = Excel.HorizontalAlignment.center;
    rangeTituloTabela.format.fill.color = cores.backgroundColor;
    rangeTituloTabela.format.font.color = cores.fontColor;
    rangeTituloTabela.format.font.bold = true;
  } catch (e) {
    throw Error("Não foi possivel adicionar o titulo da tabela de " + funcionalidade + ":" + e.toString());
  }
}

export async function criarTodasAsTabelasDePersistenciaDeDados(
  worksheetName: string,
  listaDadosTabelas: ITabelaWorksheet[]
) {
  await criarWorksheet(worksheetName);
  for (const tabela of listaDadosTabelas) {
    try {
      await verificarSeAtabelaFoiCriadaEAdicionarValores(
        tabela.funcionalidade,
        tabela.addressColunas,
        tabela.styleTable,
        [],
        tabela.colunas,
        worksheetName,
        tabela.tableExportName,
        () => [],
        () => []
      );
    } catch (e) {
      console.error(`Falha ao criar a tabela da funcionalidade de ${tabela.funcionalidade}: ${e.toString()}`);
    }
  }
}
