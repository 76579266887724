import * as React from "react";
import { IItemTagValorHistorico } from "../../shared/interfaces/ItemTag";
import { ValorApplyUnicoValor } from "../../utils/aplicarDadosPlanilha";
import TaskPanelFunctionComponent from "../../shared/components/TaskPanelFunctionComponent/TaskPanelFunctionComponent";
import { IDadosFuncionalidade } from "../../shared/interfaces/IDadosFuncionalidade";
import { IResponsePreRemoveItem } from "../../shared/interfaces/IResponsePreRemoveItem";
import { convertMsData, isNotNuloOuUndefined } from "../../utils";

interface Iprops {
  dadosFuncionalidade: IDadosFuncionalidade;
}

export default function ValorHistorico({ dadosFuncionalidade }: Iprops) {
  function montarStringRegistroTableValorHistorico(registro: IItemTagValorHistorico): (string | number)[] {
    return [
      registro.tag,
      !registro.valorCalculado && isNotNuloOuUndefined(registro.timestamp) && registro.timestamp != ""
        ? convertMsData(registro.timestamp)
        : "",
      registro.localSaidaDado,
      registro.estrategia,
      registro.rangeEmHoras,
      registro.valorCalculado && isNotNuloOuUndefined(registro.timestampInicio) && registro.timestampInicio != ""
        ? convertMsData(registro.timestampInicio)
        : "",
      registro.valorCalculado && isNotNuloOuUndefined(registro.timestampFim) && registro.timestampFim != ""
        ? convertMsData(registro.timestampFim)
        : "",
      registro.funcaoDeAgregacao,
    ];
  }

  function montarNumberFormatRegistroTable(): string[] {
    return ["@", "dd/mm/yyyy hh:mm:ss", "@", "@", "@", "dd/mm/yyyy hh:mm:ss", "dd/mm/yyyy hh:mm:ss", "@"];
  }

  async function preRemoveItem(item: IItemTagValorHistorico): Promise<IResponsePreRemoveItem> {
    try {
      let resultadoTudoCerto: boolean = await ValorApplyUnicoValor("", item.localSaidaDado);
      return {
        error: !resultadoTudoCerto,
        msg: resultadoTudoCerto ? "" : "Falha ao realizar a remoção dos valoes aplicados na planilha",
      };
    } catch (e) {
      return {
        error: true,
        msg: "Falha ao realizar a pre-remoção do item",
      };
    }
  }

  return (
    <TaskPanelFunctionComponent
      nomeWorksheetPersistData={dadosFuncionalidade.tabelaDados.nomeWorksheetPersistData}
      tableName={dadosFuncionalidade.tabelaDados.tableExportName}
      colunas={dadosFuncionalidade.tabelaDados.colunas}
      funcionalidade={dadosFuncionalidade.tabelaDados.funcionalidade}
      nomeFuncionalidade={"valor_historico"}
      nomeFuncionalidadeExibicao={"Valor histórico"}
      mensagemCarregamentoDadosDaFuncionalidade={"Obtendo o valor histórico das tags listadas"}
      mensagemCarregamentoDadosDaFuncionalidadeSelecionado={"Obtendo o valor histórico da tag selecionada"}
      aplicarLabel={"Obter valor histórico todos os itens"}
      aplicarLabelSelecionado={"Obter valor histórico do item selecionado"}
      styleTable={dadosFuncionalidade.tabelaDados.styleTable}
      addressColunas={dadosFuncionalidade.tabelaDados.addressColunas}
      urlApiObtencaoDados={dadosFuncionalidade.tabelaDados.urlApiObtencaoDados}
      validarDadosFuncionalidadeEMapearResultados={dadosFuncionalidade.validarDadosFuncionalidadeEMapearResultados}
      montarStringRegistroTable={montarStringRegistroTableValorHistorico}
      montarNumberFormatRegistroTable={montarNumberFormatRegistroTable}
      valorApply={dadosFuncionalidade.applyValorFuncionalidadePlanilha}
      preRemoveItem={preRemoveItem}
    />
  );
}
