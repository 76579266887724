import { Button, Field, Input, Spinner } from "@fluentui/react-components";
import * as React from "react";
import { useLayoutEffect, useState } from "react";
import { IDadosConexaoHistorian } from "../../../../shared/interfaces/dadosConexaoHistorian";
import { isNotNuloOuUndefined } from "../../../../utils";

interface IProps {
  carregando: boolean;
  setCarregando: React.Dispatch<React.SetStateAction<boolean>>;
  dadosConexaoHistorian: IDadosConexaoHistorian | null;
  testarConexao: (dados: IDadosConexaoHistorian) => void;
}

export default function ConexaoForm({ carregando, setCarregando, dadosConexaoHistorian, testarConexao }: IProps) {
  const [host, setHost] = useState<string>("");
  const [porta, setPorta] = useState<string>("");

  function handleSubmit(event) {
    event.preventDefault();
    const valoresForm: IDadosConexaoHistorian = {
      host,
      porta: parseInt(porta),
    };
    setCarregando(true);
    testarConexao(valoresForm);
  }

  useLayoutEffect(() => {
    if (isNotNuloOuUndefined(dadosConexaoHistorian)) {
      setHost(dadosConexaoHistorian.host);
      setPorta(dadosConexaoHistorian.porta.toString());
    }
  }, [dadosConexaoHistorian]);

  return (
    <form onSubmit={handleSubmit}>
      <Field label="Host" required>
        <Input
          placeholder="192.168.255.6"
          disabled={carregando}
          value={host}
          onChange={(evento) => setHost(evento.target.value)}
        />
      </Field>
      <Field label="Porta" required>
        <Input
          type="number"
          placeholder="8084"
          disabled={carregando}
          value={porta}
          onChange={(evento) => setPorta(evento.target.value)}
        />
      </Field>
      <Button type="submit" style={{ marginTop: "10px", width: "100%" }} appearance="primary">
        {carregando ? <Spinner appearance="inverted" size="tiny" /> : "Salvar"}
      </Button>
    </form>
  );
}
