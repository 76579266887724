import { FuncoesDeAgregacao } from "../enums/funcoesDeAgregacao";
import { UnidadeDeFrequencia } from "../enums/unidadeDeFrequencia";
import { MetodoDeInterpolacao } from "../enums/metodoDeInterpolacao";
import { ISelectOption } from "../../../shared/interfaces/ISelectOption";

export const optionsFuncoesDeAgregacao: ISelectOption[] = [
  { id: 1, name: "Média", value: FuncoesDeAgregacao.AVG },
  { id: 2, name: "Máximo", value: FuncoesDeAgregacao.MAX },
  { id: 3, name: "Minimo", value: FuncoesDeAgregacao.MIN },
  { id: 4, name: "Somatório", value: FuncoesDeAgregacao.SUM },
  { id: 5, name: "Quantidade", value: FuncoesDeAgregacao.COUNT },
  { id: 6, name: "Desvio padrão", value: FuncoesDeAgregacao.STDDEV },
  { id: 7, name: "Variância", value: FuncoesDeAgregacao.VARIANCE },
  { id: 8, name: "Mediana", value: FuncoesDeAgregacao.MEDIAN },
  { id: 9, name: "Moda", value: FuncoesDeAgregacao.MODE },
];

export const optionsUnidadesDeFrequencia: ISelectOption[] = [
  { id: 1, name: "Segundos", value: UnidadeDeFrequencia.SECONDS },
  { id: 2, name: "Minutos", value: UnidadeDeFrequencia.MINUTES },
  { id: 3, name: "Horas", value: UnidadeDeFrequencia.HOURS },
  { id: 4, name: "Dias", value: UnidadeDeFrequencia.DAYS },
  { id: 5, name: "Semanas", value: UnidadeDeFrequencia.WEEKS },
  { id: 6, name: "Meses", value: UnidadeDeFrequencia.MONTHS },
  { id: 7, name: "Anos", value: UnidadeDeFrequencia.YEARS },
];

export const optionsMetodoDeInterpolacao: ISelectOption[] = [
  {
    id: 1,
    name: "Interpolação linear",
    value: MetodoDeInterpolacao.LINEAR,
    descricao: "Preenche os valores ausentes utilizando uma interpolação linear",
  },
  {
    id: 2,
    name: "Valor mais próximo",
    value: MetodoDeInterpolacao.LAST_OBSERVATION,
    descricao: "Preenche os valores ausentes com base no último valor observado adiante.",
  },
];