import TagList from "../TagList/TagList";
import { Button, Field, tokens } from "@fluentui/react-components";
import ModalCarregamento from "../ModalCarregamento/ModalCarregamento";
import * as React from "react";
import { IItemTagValorAtual, IItemTagValoresHistoricos, IItemTagValorHistorico } from "../../interfaces/ItemTag";
import { Carregamento } from "../../interfaces/carregamento";
import { IDadosConexaoHistorian } from "../../interfaces/dadosConexaoHistorian";
import { useLayoutEffect, useState } from "react";
import {
  DocumentSearchRegular,
  DocumentSyncRegular,
  DocumentSaveRegular,
  SelectAllOnRegular,
} from "@fluentui/react-icons";
import ModalConfirmacao from "../ModalConfirmacao/ModalConfirmacao";
import { IResponsePreRemoveItem } from "../../interfaces/IResponsePreRemoveItem";
import { isNuloOuUndefined } from "../../../utils";

interface IProps {
  funcionalidade: string;
  urlApiObtencaoDados: string;
  aplicarLabel?: string;
  aplicarLabelSelecionado?: string;
  dadosConexaoHistorian: IDadosConexaoHistorian;
  setItensTag: React.Dispatch<
    React.SetStateAction<(IItemTagValorAtual | IItemTagValorHistorico | IItemTagValoresHistoricos)[]>
  >;
  selected: IItemTagValorAtual | IItemTagValorHistorico | IItemTagValoresHistoricos | null;
  setSelected: React.Dispatch<
    React.SetStateAction<IItemTagValorAtual | IItemTagValorHistorico | IItemTagValoresHistoricos>
  >;
  exibirReloadDados: boolean;
  itensTag: (IItemTagValorAtual | IItemTagValorHistorico | IItemTagValoresHistoricos)[];
  exibirCarregamentoInicial: Carregamento;
  exibirCarregamentoExecucaoDeFuncionalidade: Carregamento;
  persistirDados: (removerItensTabela: boolean) => void;
  recuperarDadosPlanilha: (notificar: boolean) => void;
  aplicar: () => void;
  aplicarSelecionado: () => void;
  aplicarEmDemanda: () => void;
  preRemoveItem: (
    item: IItemTagValorAtual | IItemTagValorHistorico | IItemTagValoresHistoricos,
    urlApiObtencaoDados?: string,
    dadosConexaoHistorian?: IDadosConexaoHistorian
  ) => Promise<IResponsePreRemoveItem>;
  nomePlanilhaDePersistenciasDosDados: string;
  notificarErro: (e: string) => void;
  mostrarCancelar: boolean,
  cancelar: () => void;
  subtitle: string;
}

export default function BaseCrudComponent({
  funcionalidade,
  urlApiObtencaoDados,
  aplicarLabel = "Aplicar",
  aplicarLabelSelecionado = "Aplicar item selecionado",
  dadosConexaoHistorian,
  setItensTag,
  selected,
  setSelected,
  exibirReloadDados,
  itensTag,
  exibirCarregamentoInicial,
  exibirCarregamentoExecucaoDeFuncionalidade,
  persistirDados,
  recuperarDadosPlanilha,
  aplicar,
  aplicarSelecionado,
  preRemoveItem,
  nomePlanilhaDePersistenciasDosDados,
  notificarErro,
  mostrarCancelar,
  cancelar,
  subtitle
}: IProps) {
  const [isOpenModalConfirmSalvarTemplate, setIsOpenModalConfirmSalvarTemplate] = useState(false);
  const [isOpenModalConfirmSincronizarDados, setIsOpenModalConfirmSincronizarDados] = useState(false);
  const [isCarregamentoAcao, setIsCarregamentoAcao] = useState<Carregamento>({ carregando: false, mensagem: "" });

  useLayoutEffect(() => {
    recuperarDadosPlanilha(true);
  }, []);

  function addTag(tag: IItemTagValorAtual | IItemTagValorHistorico | IItemTagValoresHistoricos) {
    setItensTag([...itensTag, tag]);
  }

  async function delTag(tag: IItemTagValorAtual | IItemTagValorHistorico | IItemTagValoresHistoricos) {
    setIsCarregamentoAcao({
      carregando: true,
      mensagem: "Realizando a remoção dos valores obtidos na planilha para esse item",
    });
    try {
      let resultadoPreremocao = await preRemoveItem(tag, urlApiObtencaoDados, dadosConexaoHistorian);
      if (resultadoPreremocao.error) {
        notificarErro(resultadoPreremocao.msg);
      }
    } catch (e) {
      notificarErro(e);
    }
    setIsCarregamentoAcao({
      carregando: false,
      mensagem: "",
    });
    setItensTag([...itensTag.filter((value) => value.id != tag.id)]);
  }

  function updateTag(tag: IItemTagValorAtual | IItemTagValorHistorico | IItemTagValoresHistoricos) {
    let itens = itensTag.map((item) => {
      if (item.id == tag.id) {
        return tag;
      } else {
        return item;
      }
    });
    setItensTag([...itens]);
  }

  return (
    <>
      {!exibirCarregamentoInicial.carregando && (
        <>
          <div
            style={{
              padding: "0 10px",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <TagList
              funcionalidade={funcionalidade}
              itens={itensTag}
              setItens={setItensTag}
              selected={selected}
              setSelected={setSelected}
              dadosConexao={dadosConexaoHistorian}
              addItem={addTag}
              delItem={delTag}
              updateItem={updateTag}
              nomePlanilhaDePersistenciasDosDados={nomePlanilhaDePersistenciasDosDados}
            />
            <div style={{ paddingBottom: "20px", paddingTop: "20px" }}>
              <Field style={{ width: "100%", margin: "auto", marginBottom: "10px" }}>
                <Button
                  onClick={() => setIsOpenModalConfirmSalvarTemplate(true)}
                  type="button"
                  appearance="primary"
                  style={{
                    width: "100%",
                    margin: "auto",
                    backgroundColor: tokens.colorNeutralForeground4,
                  }}
                  icon={<DocumentSaveRegular />}
                >
                  Salvar template na planilha
                </Button>
                <ModalConfirmacao
                  title={"Salvar dados do template"}
                  contentMessage={
                    "Deseja realmente salvar os dados criados no tamplate da interface na planilha de persistência dos dados?"
                  }
                  contentConfirmMessageButtom={"Sim, salve"}
                  open={isOpenModalConfirmSalvarTemplate}
                  setOpen={setIsOpenModalConfirmSalvarTemplate}
                  confirmFunction={() => {
                    persistirDados(itensTag.length == 0);
                  }}
                />
              </Field>
              {exibirReloadDados && (
                <Field style={{ width: "100%", margin: "auto", marginBottom: "10px" }}>
                  <Button
                    onClick={() => setIsOpenModalConfirmSincronizarDados(true)}
                    type="button"
                    appearance="primary"
                    style={{
                      width: "100%",
                      margin: "auto",
                      background: tokens.colorNeutralForeground2,
                    }}
                    icon={<DocumentSyncRegular />}
                  >
                    Recuperar template da planilha
                  </Button>
                  <ModalConfirmacao
                    title={"Recuperar template da planilha"}
                    contentMessage={
                      "Deseja realmente recuperar os dados de template presentes planilha de persistência?"
                    }
                    contentConfirmMessageButtom={"Sim, recupere"}
                    open={isOpenModalConfirmSincronizarDados}
                    setOpen={setIsOpenModalConfirmSincronizarDados}
                    confirmFunction={() => {
                      recuperarDadosPlanilha(true);
                    }}
                  />
                </Field>
              )}
              <Field style={{ width: "100%", margin: "auto", marginBottom: "10px" }}>
                <Button
                  onClick={() => aplicarSelecionado()}
                  disabled={isNuloOuUndefined(selected)}
                  type="button"
                  appearance="primary"
                  style={{
                    width: "100%",
                    margin: "auto",
                    backgroundColor: "#307550",
                  }}
                  icon={<SelectAllOnRegular />}
                >
                  {aplicarLabelSelecionado}
                </Button>
              </Field>
              <Field style={{ width: "100%", margin: "auto" }}>
                <Button
                  onClick={() => aplicar()}
                  disabled={itensTag.length == 0}
                  type="button"
                  appearance="primary"
                  style={{ width: "100%", margin: "auto" }}
                  icon={<DocumentSearchRegular />}
                >
                  {aplicarLabel}
                </Button>
              </Field>
            </div>
          </div>
        </>
      )}
      <ModalCarregamento 
        open={isCarregamentoAcao.carregando} 
        mensagem={isCarregamentoAcao.mensagem} 
        cancelar={cancelar} 
        mostrarCancelar={mostrarCancelar}
        subtitle={subtitle}
        />
      <ModalCarregamento
        open={exibirCarregamentoExecucaoDeFuncionalidade.carregando}
        mensagem={exibirCarregamentoExecucaoDeFuncionalidade.mensagem}
        cancelar={cancelar} mostrarCancelar={mostrarCancelar}
        subtitle={subtitle}
      />
      <ModalCarregamento 
        mensagem={exibirCarregamentoInicial.mensagem} 
        open={exibirCarregamentoInicial.carregando} 
        cancelar={cancelar} 
        mostrarCancelar={mostrarCancelar}
        subtitle={subtitle}
        />
    </>
  );
}
