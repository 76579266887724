import { IItemTagValorHistorico } from "../../../../shared/interfaces/ItemTag";
import { isNotNuloOuUndefined, obterLabelSelectPorValoresDisponiveis } from "../../../../utils";
import { Checkbox, Field, Input, Radio, RadioGroup } from "@fluentui/react-components";
import * as React from "react";
import { optionsFuncoesDeAgregacao } from "../../../ValoresHistoricos/options/options";

interface IProps {
  item: IItemTagValorHistorico | null;
}

export function ListaFieldsValorHistorico({ item }: IProps) {
  return (
    <>
      {isNotNuloOuUndefined(item) && (
        <>
          <Field>
            <Checkbox className="noOutline" disabled checked={item.valorCalculado} label="Obter valor calculado" />
          </Field>
          {item.valorCalculado && (
            <>
              <Field label={"Timestamp de início da consulta"}>
                <Input type={"datetime-local"} step="1" value={item.timestampInicio} disabled={true}></Input>
              </Field>
              <Field label={"Timestamp de fim da consulta"}>
                <Input type={"datetime-local"} step="1" value={item.timestampFim} disabled={true}></Input>
              </Field>
              <Field label={"Função de agregação"}>
                <Input
                  type={"text"}
                  value={obterLabelSelectPorValoresDisponiveis(item.funcaoDeAgregacao, optionsFuncoesDeAgregacao)}
                  disabled={true}
                ></Input>
              </Field>
              <Field label={"Local de saída do dado"}>
                <Input type={"text"} value={item.localSaidaDado} disabled={true}></Input>
              </Field>
            </>
          )}

          {!item.valorCalculado && (
            <>
              <Field label={"Timestamp da consulta"}>
                <Input type={"datetime-local"} step="1" value={item.timestamp} disabled={true}></Input>
              </Field>
              <Field label={"Local de saída do dado"}>
                <Input type={"text"} value={item.localSaidaDado} disabled={true}></Input>
              </Field>
              <Field label={"Estratégia de retorno do dado"}>
                <RadioGroup value={item.estrategia}>
                  <Radio className="noOutline" value="PERIODO_EXATO" label="Período exato" disabled={true} />
                  <Radio className="noOutline" value="INTERPOLADO" label="Interpolado" disabled={true} />
                  <Radio className="noOutline" value="ULTIMO_VALOR" label="Último valor" disabled={true} />
                </RadioGroup>
              </Field>
              {(item.estrategia == "INTERPOLADO" || item.estrategia == "ULTIMO_VALOR") && (
                <Field label={"Range de tempo em horas"}>
                  <Input type={"number"} min={1} max={48} value={item.rangeEmHoras} disabled={true}></Input>
                </Field>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
