import { Button, Checkbox, Field, Input, Spinner } from "@fluentui/react-components";
import React, { FormEvent, useState } from "react";
import ButtonGetDataBySelectWorkbook from "../../../../shared/components/ButtonGetDataBySelectWorkbook/ButtonGetDataBySelectWorkbook";
import { IItemTagValorAtual } from "../../../../shared/interfaces/ItemTag";
import { v4 as uuidv4 } from "uuid";
import { IValidationField } from "../../../../shared/interfaces/ValidationField";
import { ValidacoesForm } from "../../../../utils/ValidacoesForm";
import { IDadosConexaoHistorian } from "../../../../shared/interfaces/dadosConexaoHistorian";
import { isNotNuloOuUndefined, isNuloOuUndefined } from "../../../../utils";

interface IProps {
  funcionalidade: string;
  itens: IItemTagValorAtual[];
  editItem?: IItemTagValorAtual;
  submitFunction: (item: IItemTagValorAtual) => void;
  dadosConexao: IDadosConexaoHistorian;
  nomePlanilhaDePersistenciasDosDados: string;
}

export default function TagFormValorAtual({
  funcionalidade,
  itens,
  editItem = null,
  submitFunction,
  dadosConexao,
  nomePlanilhaDePersistenciasDosDados,
}: IProps) {
  const [carregando, setCarregando] = useState<boolean>(false);
  const [tag, setTag] = useState<string>(isNotNuloOuUndefined(editItem) ? editItem.tag : "");
  const [localSaidaDado, setLocalSaida] = useState<string>(
    isNotNuloOuUndefined(editItem) ? editItem.localSaidaDado : ""
  );
  const [exportarTimestamp, setExportarTimestamp] = useState<boolean>(
    isNotNuloOuUndefined(editItem) ? editItem.exportarTimestamp : false
  );
  const [localSaidaTimestamp, setLocalSaidaTimestamp] = useState<string | null>(
    isNotNuloOuUndefined(editItem) ? editItem.localSaidaTimestamp : ""
  );
  const [validationTag, setValidationTag] = useState<IValidationField>({
    validationState: "none",
    validationMessage: "",
  });
  const [validationLocalSaida, setValidationLocalSaida] = useState<IValidationField>({
    validationState: "none",
    validationMessage: "",
  });
  const [validationLocalSaidaTimestamp, setValidationLocalSaidaTimestamp] = useState<IValidationField>({
    validationState: "none",
    validationMessage: "",
  });

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setCarregando(true);

    let resultado = await ValidacoesForm(
      exportarTimestamp,
      tag,
      localSaidaDado,
      localSaidaTimestamp,
      itens,
      dadosConexao,
      nomePlanilhaDePersistenciasDosDados,
      editItem
    );
    setValidationTag(resultado.mensagensDeErroTag);
    setValidationLocalSaida(resultado.mensagensDeErroLocalSaida);
    setValidationLocalSaidaTimestamp(resultado.mensagensDeErroLocalSaidaTimestamp);
    if (
      resultado.mensagensDeErroTag.validationState == "none" &&
      resultado.mensagensDeErroLocalSaida.validationState == "none" &&
      resultado.mensagensDeErroLocalSaidaTimestamp.validationState == "none"
    ) {
      if (isNuloOuUndefined(editItem)) {
        submitFunction({
          funcionalidade,
          id: uuidv4(),
          tag,
          localSaidaDado: localSaidaDado,
          exportarTimestamp,
          localSaidaTimestamp,
        });
      } else {
        let item: IItemTagValorAtual = editItem;
        item.tag = tag;
        item.localSaidaDado = localSaidaDado;
        item.exportarTimestamp = exportarTimestamp;
        item.localSaidaTimestamp = localSaidaTimestamp;
        submitFunction(item);
      }
    }
    setCarregando(false);
  }

  function setarLocalSaidaTimestamp(valor: boolean) {
    if (!valor) {
      setLocalSaidaTimestamp("");
    }
    setExportarTimestamp(valor);
  }

  return (
    <form onSubmit={handleSubmit}>
      <Field
        label={"Tag"}
        required
        validationState={validationTag.validationState}
        validationMessage={validationTag.validationMessage}
      >
        <Input
          type={"text"}
          value={tag}
          onChange={(evento) => setTag(evento.target.value)}
          contentAfter={<ButtonGetDataBySelectWorkbook setarValor={setTag} />}
        ></Input>
      </Field>
      <Field
        label={"Local de saída do dado"}
        required
        validationState={validationLocalSaida.validationState}
        validationMessage={validationLocalSaida.validationMessage}
      >
        <Input
          type={"text"}
          value={localSaidaDado}
          onChange={(evento) => setLocalSaida(evento.target.value)}
          contentAfter={<ButtonGetDataBySelectWorkbook setarValor={setLocalSaida} tipoObtencao={"local"} />}
        ></Input>
      </Field>
      <Field>
        <Checkbox
          className="noOutline"
          checked={exportarTimestamp}
          onChange={(_, data) => setarLocalSaidaTimestamp(data.checked as boolean)}
          label="Exportar timestamp do último valor disponível"
        />
      </Field>
      {exportarTimestamp && (
        <Field
          label={"Local de saída do valor do timestamp do último valor disponível"}
          required={exportarTimestamp}
          validationState={validationLocalSaidaTimestamp.validationState}
          validationMessage={validationLocalSaidaTimestamp.validationMessage}
        >
          <Input
            type={"text"}
            value={localSaidaTimestamp}
            onChange={(evento) => setLocalSaidaTimestamp(evento.target.value)}
            contentAfter={<ButtonGetDataBySelectWorkbook setarValor={setLocalSaidaTimestamp} tipoObtencao={"local"} />}
          ></Input>
        </Field>
      )}
      <Field>
        <Button type="submit" style={{ marginTop: "10px", width: "100%" }} appearance="primary">
          {carregando ? (
            <Spinner appearance="inverted" size="tiny" />
          ) : isNuloOuUndefined(editItem) ? (
            "Adicionar"
          ) : (
            "Editar"
          )}
        </Button>
      </Field>
    </form>
  );
}
