import { ErroAddres } from "../../../shared/interfaces/IErroAddres";
import { IDadosConexaoHistorian } from "../../../shared/interfaces/dadosConexaoHistorian";
import { IItemTagValorHistorico } from "../../../shared/interfaces/ItemTag";
import { IItemTagAdressValorHistorico } from "../../ValorHistorico/interfaces/IItemTagAdressValorHistorico";
import {
  checkTagsExist,
  IResultadoTagsExistem,
  obterEnderecosDuplicados,
  verificarCampoDeEstrategia,
  verificarCampoDeRangeEmHoras,
  verificarCampoDeTimestamp,
  verificarOcampoDeEndereco,
  verificarOcampoDeTag,
} from "../../../utils/ValidacoesImportacaoDeDados";
import { IResultadoMapeamento } from "../../../shared/interfaces/IResultadoMapeamento";
import { isNotNuloOuUndefined, mapearFormatStringToISO, obterDadosValidosEInvalidosESeusErros } from "../../../utils";
import { validarDadosPorOpcoes, ValidarTimestampInicioFim } from "../../../utils/ValidacoesForm";
import { IValidationField } from "../../../shared/interfaces/ValidationField";
import { optionsFuncoesDeAgregacao } from "../../ValoresHistoricos/options/options";

function verificarSeUtilizaValorCalculado(item: IItemTagAdressValorHistorico): boolean {
  return (
    (isNotNuloOuUndefined(item.timestampInicio) && item.timestampInicio.valor != "") ||
    (isNotNuloOuUndefined(item.timestampFim) && item.timestampFim.valor != "") ||
    (isNotNuloOuUndefined(item.funcaoDeAgregacao) && item.funcaoDeAgregacao.valor != "")
  );
}

export async function valorHistoricoValidarDadosEMapearResultados(
  dadosConexaoHistorian: IDadosConexaoHistorian,
  valores: IItemTagAdressValorHistorico[],
  nomeWorksheetPersistData: string
): Promise<IResultadoMapeamento> {
  let itens: IItemTagValorHistorico[] = [];
  let erros: ErroAddres[] = [];
  let enderecos: string[] = valores.map((value) => value.localSaidaDado.valor);
  let tags: string[] = valores.map((value) => value.tag.valor);
  let resultadoCheckTagsExist: IResultadoTagsExistem = await checkTagsExist(dadosConexaoHistorian, tags);
  let enderecosDuplicados: string[] = obterEnderecosDuplicados(enderecos);

  for (const valor of valores) {
    itens.push({
      id: valor.id,
      tag: valor.tag.valor,
      localSaidaDado: valor.localSaidaDado.valor,
      funcionalidade: valor.funcionalidade,
      timestamp: mapearFormatStringToISO(valor.timestamp.valor),
      estrategia: valor.estrategia.valor as "PERIODO_EXATO" | "INTERPOLADO" | "ULTIMO_VALOR" | "",
      rangeEmHoras: valor.rangeEmHoras.valor.trim(),
      timestampInicio: mapearFormatStringToISO(valor.timestampInicio.valor),
      timestampFim: mapearFormatStringToISO(valor.timestampFim.valor),
      valorCalculado: verificarSeUtilizaValorCalculado(valor),
      funcaoDeAgregacao: valor.funcaoDeAgregacao.valor,
    });
    verificarOcampoDeTag(
      valor.tag.valor,
      erros,
      valor.id,
      valor.tag.address,
      "tag",
      resultadoCheckTagsExist.errosAoVerificarTagsExistem,
      resultadoCheckTagsExist.tagsDisponiveis
    );
    await verificarOcampoDeEndereco(
      valor.localSaidaDado.valor,
      erros,
      valor.id,
      valor.localSaidaDado.address,
      "localSaidaDado",
      enderecosDuplicados,
      nomeWorksheetPersistData
    );

    if (!verificarSeUtilizaValorCalculado(valor)) {
      await verificarCampoDeTimestamp(
        mapearFormatStringToISO(valor.timestamp.valor),
        erros,
        valor.id,
        valor.timestamp.address,
        "timestamp"
      );
      verificarCampoDeEstrategia(valor.estrategia.valor, erros, valor.id, valor.estrategia.address, "estrategia");
      verificarCampoDeRangeEmHoras(
        valor.rangeEmHoras.valor,
        erros,
        valor.id,
        valor.rangeEmHoras.address,
        "rangeEmHoras",
        valor.estrategia.valor
      );
      if (isNotNuloOuUndefined(valor.timestampInicio.valor) && valor.timestampInicio.valor !== "") {
        erros.push({
          id: valor.id,
          errro: "O valor do campo 'timestampInicio' deve ser vazio quando não for utilizado o valor calculado",
          address: valor.timestampInicio.address,
        });
      }
      if (isNotNuloOuUndefined(valor.timestampFim.valor) && valor.timestampFim.valor !== "") {
        erros.push({
          id: valor.id,
          errro: "O valor do campo 'timestampFim' deve ser vazio quando não for utilizado o valor calculado",
          address: valor.timestampFim.address,
        });
      }
      if (isNotNuloOuUndefined(valor.funcaoDeAgregacao.valor) && valor.funcaoDeAgregacao.valor !== "") {
        erros.push({
          id: valor.id,
          errro: "O valor do campo 'funcaoDeAgregacao' deve ser vazio quando não for utilizado o valor calculado",
          address: valor.funcaoDeAgregacao.address,
        });
      }
    } else {
      if (isNotNuloOuUndefined(valor.timestamp.valor) && valor.timestamp.valor !== "") {
        erros.push({
          id: valor.id,
          errro: "O valor do campo 'timestamp' deve ser vazio quando utilizar o valor calculado",
          address: valor.timestamp.address,
        });
      }
      if (isNotNuloOuUndefined(valor.rangeEmHoras.valor) && valor.rangeEmHoras.valor !== "") {
        erros.push({
          id: valor.id,
          errro: "O valor do campo 'rangeEmHoras' deve ser vazio quando utilizar o valor calculado",
          address: valor.rangeEmHoras.address,
        });
      }
      if (isNotNuloOuUndefined(valor.estrategia.valor) && valor.estrategia.valor !== "") {
        erros.push({
          id: valor.id,
          errro: "O valor do campo 'estrategia' deve ser vazio quando utilizar o valor calculado",
          address: valor.estrategia.address,
        });
      }

      let campoValidoTimestampInicio: boolean = await verificarCampoDeTimestamp(
        mapearFormatStringToISO(valor.timestampInicio.valor),
        erros,
        valor.id,
        valor.timestampInicio.address,
        "timestampInicio"
      );
      let campoValidoTimestampFim: boolean = await verificarCampoDeTimestamp(
        mapearFormatStringToISO(valor.timestampFim.valor),
        erros,
        valor.id,
        valor.timestampFim.address,
        "timestampFim"
      );
      if (campoValidoTimestampInicio && campoValidoTimestampFim) {
        let mensagensDeErroTimestamps = ValidarTimestampInicioFim(
          mapearFormatStringToISO(valor.timestampInicio.valor),
          mapearFormatStringToISO(valor.timestampFim.valor)
        );
        if (mensagensDeErroTimestamps.mensagensDeErroTimestampInicio.validationState !== "none") {
          erros.push({
            id: valor.id,
            errro: mensagensDeErroTimestamps.mensagensDeErroTimestampInicio.validationMessage,
            address: valor.timestampInicio.address,
          });
        }
        if (mensagensDeErroTimestamps.mensagensDeErroTimestampFim.validationState !== "none") {
          erros.push({
            id: valor.id,
            errro: mensagensDeErroTimestamps.mensagensDeErroTimestampFim.validationMessage,
            address: valor.timestampFim.address,
          });
        }
      }
      let mensagensDeErroFuncaoDeAgregacao: IValidationField = validarDadosPorOpcoes(
        valor.funcaoDeAgregacao.valor,
        optionsFuncoesDeAgregacao,
        "O campo de função de agregação é obrigatório",
        "As funções de agregação disponíveis são: "
      );
      if (mensagensDeErroFuncaoDeAgregacao.validationState !== "none") {
        erros.push({
          id: valor.id,
          errro: mensagensDeErroFuncaoDeAgregacao.validationMessage,
          address: valor.funcaoDeAgregacao.address,
        });
      }
    }
  }
  return obterDadosValidosEInvalidosESeusErros(itens, erros);
}
