import { useState } from "react";
import { IDadosConexaoHistorian } from "../../shared/interfaces/dadosConexaoHistorian";
import BaseComponent from "../../shared/components/BaseComponent/BaseComponent";
import * as React from "react";
import CardFuncionalidade from "./components/CardFuncionalidade/CardFuncionalidade";
import { IDadosFuncionalidade } from "../../shared/interfaces/IDadosFuncionalidade";

interface IProps {
  dadosFuncionalidades: IDadosFuncionalidade[];
}

export default function AtualizarPlanilha({ dadosFuncionalidades }: IProps) {
  const [dadosConexaoHistorian, setDadosConexaoHistorian] = useState<null | IDadosConexaoHistorian>(null);

  return (
    <BaseComponent
      setDadosConexaoHistorianOutput={setDadosConexaoHistorian}
      content={
        <CardFuncionalidade dadosFuncionalidades={dadosFuncionalidades} dadosConexaoHistorian={dadosConexaoHistorian} />
      }
    />
  );
}
