import { Caption1, Card, CardHeader, Text, makeStyles, tokens } from "@fluentui/react-components";
import { InfoFilled, WarningFilled, DismissCircleFilled, CheckmarkCircleFilled } from "@fluentui/react-icons";
import * as React from "react";

interface IProps {
  tipo: "warning" | "danger" | "info" | "success";
  titulo: string;
  mensagem: string;
}

const useStyles = makeStyles({
  card: {
    height: "fit-content",
  },

  caption: {
    color: tokens.colorNeutralForeground3,
  },
});

export default function Alert({ tipo, titulo, mensagem }: IProps) {
  const styles = useStyles();

  return (
    <Card className={styles.card} size="small" role="listitem">
      <CardHeader
        image={(() => {
          switch (tipo) {
            case "info":
              return <InfoFilled fontSize={"3em"} primaryFill={tokens.colorNeutralForeground2} />;
            case "warning":
              return <WarningFilled fontSize={"3em"} primaryFill={tokens.colorPaletteYellowForeground2} />;
            case "danger":
              return <DismissCircleFilled fontSize={"3em"} primaryFill={tokens.colorPaletteRedForeground3} />;
            case "success":
              return <CheckmarkCircleFilled fontSize={"3em"} primaryFill={tokens.colorPaletteGreenForeground3} />;
            default:
              return <InfoFilled fontSize={"3em"} primaryFill={tokens.colorNeutralForeground2} />;
          }
        })()}
        header={<Text weight="semibold">{titulo}</Text>}
        description={<Caption1 className={styles.caption}>{mensagem}</Caption1>}
      />
    </Card>
  );
}
