/* global Office, Excel */

import { IValorApplyPlanilha } from "../shared/interfaces/IValorApplyPlanilha";

export async function ValorApply(
  planilha: string,
  dados: IValorApplyPlanilha[],
  texto: boolean,
  erros: string[],
  getCancelar: () => boolean,
): Promise<void> {
  return await Excel.run(async (context) => {
    let sheet: Excel.Worksheet = null;
    let ranges: Excel.RangeAreas = null;
    let enderecos: string = dados.map((value) => value.dadosPlanilha.endereco).join(", ");
    try {
      sheet = context.workbook.worksheets.getItem(planilha);
      ranges = sheet.getRanges(enderecos).load(["address", "ranges"]);
      let rangesValue = ranges.areas.load(["items/address", "items/values", "items/numberFormat"]);
      await context.sync();
      let items = rangesValue.items;
      for (let i = 0; i < items.length; i++) {
        if(getCancelar()) break
        if (texto) {
          items[i].numberFormat = [["@"]];
        } else {
          items[i].numberFormat = [[""]];
        }
        items[i].values = [[dados[i].valor]];
      }
      await context.sync();
      if (Office.context.requirements.isSetSupported("ExcelApi", "1.2")) {
        sheet.getUsedRange().format.autofitColumns();
        sheet.getUsedRange().format.autofitRows();
      }
    } catch (e) {
      console.error(e);
      erros.push(
        `Falha ao aplicar os valores do tipo ${
          texto ? "'Texto'" : "'Geral'"
        } na planilha "${planilha}" para os endereços "${enderecos}"`
      );
    }
  });
}

export async function ValorApplyUnicoValor(valor: string, endereco: string, text: boolean = false): Promise<boolean> {
  const re = new RegExp(/(.*)!([ A-Z]*[0-9]+)$/);
  let validRegex = re.test(endereco);
  if (!validRegex) {
    throw Error("O endereço não esta num formato válido");
  } else {
    const regexSplit = endereco.match(re);
    if (regexSplit.length == 3) {
      let planilha = regexSplit[1].replaceAll("'", "");
      let enderecoPlanilha = regexSplit[2];
      return await Excel.run(async (context) => {
        let sheet: Excel.Worksheet = null;
        let range: Excel.Range = null;

        try {
          sheet = context.workbook.worksheets.getItem(planilha);
          range = sheet.getRange(enderecoPlanilha).load(["address", "values", "numberFormat", "numberFormatLocal"]);
        } catch (e) {
          throw Error(`O endereço "${endereco}" não foi encontrado`);
        }

        try {
          if (text) {
            range.numberFormat = [["@"]];
          } else {
            range.numberFormat = [[""]];
          }

          range.values = [[valor.toString()]];
          await context.sync();
          if (Office.context.requirements.isSetSupported("ExcelApi", "1.2")) {
            sheet.getUsedRange().format.autofitColumns();
            sheet.getUsedRange().format.autofitRows();
          }
          return true;
        } catch (e) {
          throw Error(`Falha ao aplicar o valor no endereço "${endereco}"`);
        }
      });
    } else {
      throw Error(`O endereço "${endereco}" é inválido`);
    }
  }
}
