import { ResponseValorAtual } from "../../../shared/interfaces/responses/responseValorAtual";
import { balanceamentoDeCargaAplicarValorPlanilha, isNotNuloOuUndefined } from "../../../utils";
import moment from "moment";
import { validarEnderecoPlanilha } from "../../../utils/ValidarEnderecoPlaninha";
import { IValorApplyPlanilha } from "../../../shared/interfaces/IValorApplyPlanilha";
import { IDadosEnderecoPlanilha } from "../../../shared/interfaces/IDadosEnderecoPlanilha";

export async function aplicarValorAtual(itens: ResponseValorAtual[], getCancelar: () => boolean): Promise<string[]> {
  let itensMapeadosEndereco: IValorApplyPlanilha[] = [];
  let erros: string[] = [];
  itens.forEach((item) => {
    if (item.exportarTimestamp) {
      let erro: boolean = false;
      let endereco: IDadosEnderecoPlanilha = {
        endereco: "",
        planilha: "",
      };
      try {
        endereco = validarEnderecoPlanilha(item.localSaidaTimestamp);
      } catch (e) {
        erro = true;
        erros.push(e.toString());
      }
      if (!erro) {
        let valorTimestamp = item.timestamp;
        let text: boolean = false;
        if (valorTimestamp != "" && isNotNuloOuUndefined(valorTimestamp) && valorTimestamp != "Nenhum valor obtido") {
          text = true;
          valorTimestamp = moment(item.timestamp).format("DD/MM/YYYY HH:mm:ss,SSS");
        }
        itensMapeadosEndereco.push({
          valor: valorTimestamp,
          texto: text,
          dadosPlanilha: endereco,
        });
      }
    }
    let endereco: IDadosEnderecoPlanilha = {
      endereco: "",
      planilha: "",
    };
    let erro: boolean = false;
    try {
      endereco = validarEnderecoPlanilha(item.localSaidaDado);
    } catch (e) {
      erro = true;
      erros.push(e.toString());
    }
    if (!erro) {
      itensMapeadosEndereco.push({
        valor: item.value,
        texto: false,
        dadosPlanilha: endereco,
      });
    }
  });
  await balanceamentoDeCargaAplicarValorPlanilha(itensMapeadosEndereco, erros, getCancelar);

  return erros;
}
