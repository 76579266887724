import { Button } from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { DrawerBody, DrawerHeader, DrawerHeaderTitle, DrawerOverlay } from "@fluentui/react-components/unstable";
import React from "react";

interface IProps {
  titulo: string;
  content?: React.ReactNode;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function PainelLateral({ titulo, content, isOpen, setIsOpen }: IProps) {
  return (
    <div>
      <DrawerOverlay size="full" position="end" open={isOpen} onOpenChange={(_, { open }) => setIsOpen(open)}>
        <DrawerHeader>
          <DrawerHeaderTitle
            action={
              <Button
                appearance="subtle"
                aria-label="Close"
                icon={<Dismiss24Regular />}
                onClick={() => setIsOpen(false)}
              />
            }
          >
            {titulo}
          </DrawerHeaderTitle>
        </DrawerHeader>

        <DrawerBody>{content}</DrawerBody>
      </DrawerOverlay>
    </div>
  );
}
