import { IDadosConexaoHistorian } from "../shared/interfaces/dadosConexaoHistorian";
import axios from "axios";
import { findDuplicates, get_url_api, isNotNuloOuUndefined, isNuloOuUndefined } from "./index";
import { ErroAddres } from "../shared/interfaces/IErroAddres";
import { ValidarTimestamp, VerificarSeEnderecoExisteNaPlanilha } from "./ValidacoesForm";
import { IResponseApiTagsExistentes } from "../shared/interfaces/responses/IResponseApiTagsExistentes";

export interface IResultadoTagsExistem {
  errosAoVerificarTagsExistem: boolean;
  tagsDisponiveis: string[];
}

export async function checkTagsExist(
  dadosConexaoHistorian: IDadosConexaoHistorian,
  tags: string[]
): Promise<IResultadoTagsExistem> {
  let errosAoVerificarTagsExistem: boolean = false;
  let tagsFiltradas: string[] = Array.from(new Set(tags.filter((value) => value != "")));
  let tagsDisponiveis: string[] = [];
  if (tagsFiltradas.length > 0) {
    tagsDisponiveis = await axios
      .post<IResponseApiTagsExistentes>(`${get_url_api()}/api/check_tags_exist_in_historian`, {
        host: dadosConexaoHistorian.host,
        port: dadosConexaoHistorian.porta,
        tags: JSON.stringify(tagsFiltradas),
      })
      .then((resposta) => {
        return resposta.data.tagsExistentes;
      })
      .catch(() => {
        errosAoVerificarTagsExistem = true;
        return [];
      });
  }
  return { errosAoVerificarTagsExistem, tagsDisponiveis };
}

export function obterEnderecosDuplicados(valores: string[]): string[] {
  let enderecos: string[] = valores.filter((value) => value != "");
  return enderecos.length > 0 ? findDuplicates(enderecos) : [];
}

export function verificarSeOvalorEstaVazio(
  valor: string,
  erros: ErroAddres[],
  id: string,
  endereco: string,
  nomeCampo: string
): boolean {
  if (valor == "") {
    erros.push({
      id: id,
      errro: `O campo de '${nomeCampo}' não deve ser vazio`,
      address: endereco,
    });
  }
  return valor == "";
}

export function verificarCampoDeEstrategia(
  valor: string,
  erros: ErroAddres[],
  id: string,
  endereco: string,
  nomeCampo: string
) {
  const campoVazio: boolean = verificarSeOvalorEstaVazio(valor, erros, id, endereco, nomeCampo);
  if (!campoVazio) {
    if (!["ULTIMO_VALOR", "INTERPOLADO", "PERIODO_EXATO"].includes(valor)) {
      erros.push({
        id: id,
        errro: 'Só é permitido os valores "ULTIMO_VALOR", "INTERPOLADO" e "PERIODO_EXATO"',
        address: endereco,
      });
    }
  }
}

export function verificarCampoDeRangeEmHoras(
  valor: string,
  erros: ErroAddres[],
  id: string,
  endereco: string,
  nomeCampo: string,
  estrategia: string
) {
  if (estrategia == "PERIODO_EXATO" && valor != "") {
    erros.push({
      id: id,
      errro: 'O campo deve ser vazio quando a estratégia for "PERIODO_EXATO"',
      address: endereco,
    });
  } else if (estrategia == "ULTIMO_VALOR" || estrategia == "INTERPOLADO") {
    const campoVazio: boolean = verificarSeOvalorEstaVazio(valor, erros, id, endereco, nomeCampo);
    if (!campoVazio) {
      let intNumber: number = 0;
      try {
        intNumber = parseInt(valor);
      } catch (e) {
        erros.push({
          id: id,
          errro: "O campo deve ser um número inteiro maior ou igual a 1 e menor ou igual a 48",
          address: endereco,
        });
        return;
      }
      if (intNumber <= 0 || intNumber > 48 || isNuloOuUndefined(intNumber) || isNaN(intNumber)) {
        erros.push({
          id: id,
          errro: "O campo deve ser um número inteiro maior ou igual a 1 e menor ou igual a 48",
          address: endereco,
        });
      }
    }
  }
}

export function verificarOcampoDeTag(
  valor: string,
  erros: ErroAddres[],
  id: string,
  endereco: string,
  nomeCampo: string,
  errosAoVerificarTagsExistem: boolean,
  tagsDisponiveis: string[]
) {
  const campoVazio: boolean = verificarSeOvalorEstaVazio(valor, erros, id, endereco, nomeCampo);
  if (!campoVazio) {
    if (errosAoVerificarTagsExistem) {
      erros.push({
        id: id,
        errro: "Não foi possivel verificar se a tag existe no Historian",
        address: endereco,
      });
    } else if (tagsDisponiveis.length == 0) {
      erros.push({
        id: id,
        errro: "Essa tag não está disponivel no Historian",
        address: endereco,
      });
    } else if (tagsDisponiveis.length > 0 && isNuloOuUndefined(tagsDisponiveis.find((value) => value == valor))) {
      erros.push({
        id: id,
        errro: "Essa tag não está disponivel no Historian",
        address: endereco,
      });
    }
  }
}

export async function verificarCampoDeTimestamp(
  valor: string,
  erros: ErroAddres[],
  id: string,
  endereco: string,
  nomeCampo: string
): Promise<boolean> {
  const campoVazio: boolean = verificarSeOvalorEstaVazio(valor, erros, id, endereco, nomeCampo);
  if (!campoVazio) {
    let validacaoTimestamp = ValidarTimestamp(valor);
    if (validacaoTimestamp.validationState != "none") {
      erros.push({
        id: id,
        errro: validacaoTimestamp.validationMessage,
        address: endereco,
      });
      return false;
    }
  } else {
    return false;
  }
  return true;
}

export async function verificarOcampoDeEndereco(
  valor: string,
  erros: ErroAddres[],
  id: string,
  endereco: string,
  nomeCampo: string,
  enderecosDuplicados: string[],
  nomePlanilhaDePersistenciasDosDados: string
) {
  if (valor == "") {
    erros.push({
      id: id,
      errro: `O campo de '${nomeCampo}' não deve ser vazio`,
      address: endereco,
    });
  } else {
    if (valor.toLowerCase().includes(nomePlanilhaDePersistenciasDosDados.toLowerCase())) {
      erros.push({
        id: id,
        errro: `O campo de '${nomeCampo}' não pode utilizar endereços da planilha de persistência do plugin`,
        address: endereco,
      });
    }
    if (enderecosDuplicados.length > 0 && isNotNuloOuUndefined(enderecosDuplicados.find((value) => value == valor))) {
      erros.push({
        id: id,
        errro: `O campo de '${nomeCampo}' não pode ter seu valor repetido`,
        address: endereco,
      });
    }
    try {
      let existeEsseEndereco = await VerificarSeEnderecoExisteNaPlanilha(valor).then(
        (value) => value.validationState == "none"
      );
      if (!existeEsseEndereco) {
        erros.push({
          id: id,
          errro: `O valor presente no campo '${nomeCampo}' não existe`,
          address: endereco,
        });
      }
    } catch (e) {
      erros.push({
        id: id,
        errro: `Não foi possivel verificar se o valor presente em '${nomeCampo}' existe: ${e.toString()}`,
        address: endereco,
      });
    }
  }
}

export async function verificarOcampoDeEnderecoTimestamp(
  valor: string,
  erros: ErroAddres[],
  id: string,
  endereco: string,
  nomeCampo: string,
  enderecos: string[],
  enderecosDuplicadosTimestamps: string[],
  nomePlanilhaDePersistenciasDosDados: string
) {
  if (valor == "" || isNuloOuUndefined(valor)) {
    return;
  } else {
    if (valor.toLowerCase().includes(nomePlanilhaDePersistenciasDosDados.toLowerCase())) {
      erros.push({
        id: id,
        errro: `O campo de '${nomeCampo}' não pode utilizar endereços da planilha de persistência do plugin`,
        address: endereco,
      });
    }
    if (enderecos.length > 0 && isNotNuloOuUndefined(enderecos.find((value) => value == valor))) {
      erros.push({
        id: id,
        errro: `O campo de '${nomeCampo}' não pode ter seu valor igual a outro endereço utilizado por outro item`,
        address: endereco,
      });
    }
    if (
      enderecosDuplicadosTimestamps.length > 0 &&
      isNotNuloOuUndefined(enderecosDuplicadosTimestamps.find((value) => value == valor))
    ) {
      erros.push({
        id: id,
        errro: `O campo de '${nomeCampo}' não pode ter seu valor igual a outro '${nomeCampo}' utilizado por outro item`,
        address: endereco,
      });
    }
    try {
      let existeEsseEndereco = await VerificarSeEnderecoExisteNaPlanilha(valor).then(
        (value) => value.validationState == "none"
      );
      if (!existeEsseEndereco) {
        erros.push({
          id: id,
          errro: `O endereço presente no campo '${nomeCampo}' não existe`,
          address: endereco,
        });
      }
    } catch (e) {
      erros.push({
        id: id,
        errro: `Não foi possivel verificar se o endereço presente em '${nomeCampo}' existe: ${e.toString()}`,
        address: endereco,
      });
    }
  }
}
