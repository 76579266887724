import * as React from "react";
import { IItemTagValorAtual } from "../../shared/interfaces/ItemTag";
import { ValorApplyUnicoValor } from "../../utils/aplicarDadosPlanilha";
import TaskPanelFunctionComponent from "../../shared/components/TaskPanelFunctionComponent/TaskPanelFunctionComponent";
import { IDadosFuncionalidade } from "../../shared/interfaces/IDadosFuncionalidade";
import { IResponsePreRemoveItem } from "../../shared/interfaces/IResponsePreRemoveItem";

interface Iprops {
  dadosFuncionalidade: IDadosFuncionalidade;
}

export default function ValorAtual({ dadosFuncionalidade }: Iprops) {
  function montarStringRegistroTable(registro: IItemTagValorAtual): (string | number)[] {
    return [registro.tag, registro.localSaidaDado, registro.localSaidaTimestamp];
  }

  function montarNumberFormatRegistroTable(): string[] {
    return ["@", "@", "@"];
  }

  async function preRemoveItem(item: IItemTagValorAtual): Promise<IResponsePreRemoveItem> {
    let resultado = true;
    try {
      if (item.exportarTimestamp) {
        resultado = resultado && (await ValorApplyUnicoValor("", item.localSaidaTimestamp));
      }
      resultado = resultado && (await ValorApplyUnicoValor("", item.localSaidaDado));
      return {
        error: !resultado,
        msg: resultado ? "" : "Falha ao realizar a remoção dos valoes aplicados na planilha",
      };
    } catch (e) {
      return {
        error: true,
        msg: "Falha ao realizar a pre-remoção do item",
      };
    }
  }

  return (
    <TaskPanelFunctionComponent
      nomeWorksheetPersistData={dadosFuncionalidade.tabelaDados.nomeWorksheetPersistData}
      tableName={dadosFuncionalidade.tabelaDados.tableExportName}
      colunas={dadosFuncionalidade.tabelaDados.colunas}
      funcionalidade={dadosFuncionalidade.tabelaDados.funcionalidade}
      nomeFuncionalidade={"valor_atual"}
      nomeFuncionalidadeExibicao={"Valor atual"}
      mensagemCarregamentoDadosDaFuncionalidade={"Obtendo o último valor das tags listadas"}
      mensagemCarregamentoDadosDaFuncionalidadeSelecionado={"Obtendo o último valor da tags selecionada"}
      aplicarLabel={"Obter valor atual todos os itens"}
      aplicarLabelSelecionado={"Obter valor atual do item selecionado"}
      styleTable={dadosFuncionalidade.tabelaDados.styleTable}
      addressColunas={dadosFuncionalidade.tabelaDados.addressColunas}
      urlApiObtencaoDados={dadosFuncionalidade.tabelaDados.urlApiObtencaoDados}
      validarDadosFuncionalidadeEMapearResultados={dadosFuncionalidade.validarDadosFuncionalidadeEMapearResultados}
      montarStringRegistroTable={montarStringRegistroTable}
      montarNumberFormatRegistroTable={montarNumberFormatRegistroTable}
      valorApply={dadosFuncionalidade.applyValorFuncionalidadePlanilha}
      preRemoveItem={preRemoveItem}
    />
  );
}
