import { Tree } from "@fluentui/react-components";
import * as React from "react";
import { ItemListaFuncionalidade } from "./ItemListaFuncionalidade";
import { IFuncionalidadeProcessada } from "../../interfaces/IFuncionalidadeProcessada";

interface IProps {
  itens: IFuncionalidadeProcessada[];
}

export default function ListaFuncionalidade({ itens }: IProps) {
  return (
    <Tree aria-label="Aside">
      {itens.map((iten, index) => {
        return <ItemListaFuncionalidade key={index} nome={iten.funcionalidade} erros={iten.erros} />;
      })}
    </Tree>
  );
}
