import { Button, Checkbox, Field, Input, Radio, RadioGroup, Select, Spinner } from "@fluentui/react-components";
import React, { FormEvent, useState } from "react";
import ButtonGetDataBySelectWorkbook from "../../../../shared/components/ButtonGetDataBySelectWorkbook/ButtonGetDataBySelectWorkbook";
import { IItemTagValorHistorico } from "../../../../shared/interfaces/ItemTag";
import { v4 as uuidv4 } from "uuid";
import { IValidationField } from "../../../../shared/interfaces/ValidationField";
import { ValidacoesFormValorHistorico } from "../../../../utils/ValidacoesForm";
import { IDadosConexaoHistorian } from "../../../../shared/interfaces/dadosConexaoHistorian";
import { isNotNuloOuUndefined, isNuloOuUndefined } from "../../../../utils";
import { FuncoesDeAgregacao } from "../../../ValoresHistoricos/enums/funcoesDeAgregacao";
import { optionsFuncoesDeAgregacao } from "../../../ValoresHistoricos/options/options";

interface IProps {
  funcionalidade: string;
  itens: IItemTagValorHistorico[];
  editItem?: IItemTagValorHistorico;
  submitFunction: (item: IItemTagValorHistorico) => void;
  dadosConexao: IDadosConexaoHistorian;
  nomePlanilhaDePersistenciasDosDados: string;
}

export default function TagFormValorHistorico({
  funcionalidade,
  itens,
  editItem = null,
  submitFunction,
  dadosConexao,
  nomePlanilhaDePersistenciasDosDados,
}: IProps) {
  const [carregando, setCarregando] = useState<boolean>(false);
  const [tag, setTag] = useState<string>(isNotNuloOuUndefined(editItem) ? editItem.tag : "");
  const [localSaidaDado, setLocalSaida] = useState<string>(
    isNotNuloOuUndefined(editItem) ? editItem.localSaidaDado : ""
  );
  const [timestamp, setTimestamp] = useState<string>(isNotNuloOuUndefined(editItem) ? editItem.timestamp : "");
  const [estrategia, setEstrategia] = useState<string>(
    isNotNuloOuUndefined(editItem) ? editItem.estrategia : "PERIODO_EXATO"
  );
  const [rangeEmHoras, setRangeEmHoras] = useState<string>(isNotNuloOuUndefined(editItem) ? editItem.rangeEmHoras : "");
  const [valorCalculado, setValorCalculado] = useState<boolean>(
    isNotNuloOuUndefined(editItem) ? editItem.valorCalculado : false
  );
  const [timestampInicio, setTimestampInicio] = useState<string>(
    isNotNuloOuUndefined(editItem) ? editItem.timestampInicio : ""
  );
  const [timestampFim, setTimestampFim] = useState<string>(isNotNuloOuUndefined(editItem) ? editItem.timestampFim : "");
  const [funcaoDeAgregacao, setFuncaoDeAgregacao] = useState<string>(
    isNotNuloOuUndefined(editItem) ? editItem.funcaoDeAgregacao : ""
  );
  const [validationTag, setValidationTag] = useState<IValidationField>({
    validationState: "none",
    validationMessage: "",
  });
  const [validationlocalSaida, setValidationLocalSaida] = useState<IValidationField>({
    validationState: "none",
    validationMessage: "",
  });
  const [validationTimestamp, setValidationTimestamp] = useState<IValidationField>({
    validationState: "none",
    validationMessage: "",
  });
  const [validationFuncaoDeAgregacao, setValidationFuncaoDeAgregacao] = useState<IValidationField>({
    validationState: "none",
    validationMessage: "",
  });
  const [validationTimestampInicio, setValidationTimestampInicio] = useState<IValidationField>({
    validationState: "none",
    validationMessage: "",
  });
  const [validationTimestampFim, setValidationTimestampFim] = useState<IValidationField>({
    validationState: "none",
    validationMessage: "",
  });

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setCarregando(true);

    let resultado = await ValidacoesFormValorHistorico(
      tag,
      localSaidaDado,
      timestamp,
      valorCalculado,
      timestampInicio,
      timestampFim,
      funcaoDeAgregacao,
      itens,
      dadosConexao,
      nomePlanilhaDePersistenciasDosDados,
      editItem
    );
    setValidationTag(resultado.mensagensDeErroTag);
    setValidationLocalSaida(resultado.mensagensDeErroLocalSaida);
    setValidationTimestamp(resultado.mensagensDeErroTimestamp);
    setValidationTimestampInicio(resultado.mensagensDeErroTimestampInicio);
    setValidationTimestampFim(resultado.mensagensDeErroTimestampFim);
    setValidationFuncaoDeAgregacao(resultado.mensagensDeErroFuncaoDeAgregacao);
    if (
      resultado.mensagensDeErroTag.validationState == "none" &&
      resultado.mensagensDeErroLocalSaida.validationState == "none" &&
      resultado.mensagensDeErroTimestamp.validationState == "none" &&
      resultado.mensagensDeErroTimestampInicio.validationState == "none" &&
      resultado.mensagensDeErroTimestampFim.validationState == "none" &&
      resultado.mensagensDeErroFuncaoDeAgregacao.validationState == "none"
    ) {
      if (isNuloOuUndefined(editItem)) {
        submitFunction({
          funcionalidade: funcionalidade,
          id: uuidv4(),
          tag: tag,
          localSaidaDado: localSaidaDado,
          timestamp: valorCalculado ? "" : timestamp,
          estrategia: valorCalculado ? "" : estrategia,
          rangeEmHoras: valorCalculado ? "" : estrategia == "PERIODO_EXATO" ? "" : rangeEmHoras,
          valorCalculado: valorCalculado,
          timestampInicio: valorCalculado ? timestampInicio : "",
          timestampFim: valorCalculado ? timestampFim : "",
          funcaoDeAgregacao: valorCalculado ? funcaoDeAgregacao : "",
        });
      } else {
        let item: IItemTagValorHistorico = editItem;
        item.tag = tag;
        item.localSaidaDado = localSaidaDado;
        item.timestamp = valorCalculado ? "" : timestamp;
        item.estrategia = valorCalculado ? "" : estrategia;
        item.rangeEmHoras = valorCalculado ? "" : estrategia == "PERIODO_EXATO" ? "" : rangeEmHoras;
        item.valorCalculado = valorCalculado;
        item.timestampInicio = valorCalculado ? timestampInicio : "";
        item.timestampFim = valorCalculado ? timestampFim : "";
        item.funcaoDeAgregacao = valorCalculado ? funcaoDeAgregacao : "";
        submitFunction(item);
      }
    }
    setCarregando(false);
  }

  function selecionarEstrategia(valor: "PERIODO_EXATO" | "INTERPOLADO" | "ULTIMO_VALOR") {
    setEstrategia(valor);
    if (valor == "PERIODO_EXATO") {
      setRangeEmHoras("");
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Field
        label={"Tag"}
        required
        validationState={validationTag.validationState}
        validationMessage={validationTag.validationMessage}
      >
        <Input
          type={"text"}
          value={tag}
          onChange={(evento) => setTag(evento.target.value)}
          contentAfter={<ButtonGetDataBySelectWorkbook setarValor={setTag} />}
        ></Input>
      </Field>
      <Field>
        <Checkbox
          className="noOutline"
          checked={valorCalculado}
          label="Obter valor calculado"
          onChange={(_, data) => {
            let valor: boolean = data.checked as boolean;
            setTimestampInicio("");
            setTimestampFim("");
            setFuncaoDeAgregacao(valor ? FuncoesDeAgregacao.AVG : "");
            setValorCalculado(valor);
          }}
        />
      </Field>
      {valorCalculado && (
        <>
          <Field
            label={"Timestamp de início"}
            required
            validationState={validationTimestampInicio.validationState}
            validationMessage={validationTimestampInicio.validationMessage}
          >
            <Input
              type={"datetime-local"}
              step="1"
              required
              value={timestampInicio}
              onChange={(evento) => setTimestampInicio(evento.target.value)}
            ></Input>
          </Field>
          <Field
            label={"Timestamp de fim"}
            required
            validationState={validationTimestampFim.validationState}
            validationMessage={validationTimestampFim.validationMessage}
          >
            <Input
              type={"datetime-local"}
              step="1"
              required
              value={timestampFim}
              onChange={(evento) => setTimestampFim(evento.target.value)}
            ></Input>
          </Field>
          <Field
            label={"Função de agregação"}
            validationState={validationFuncaoDeAgregacao.validationState}
            validationMessage={validationFuncaoDeAgregacao.validationMessage}
            required
          >
            <Select
              onChange={(_event, data) => {
                setFuncaoDeAgregacao(data.value);
              }}
              value={funcaoDeAgregacao}
              required
            >
              {optionsFuncoesDeAgregacao.map((value) => {
                return (
                  <option key={value.id} value={value.value}>
                    {value.name}
                  </option>
                );
              })}
            </Select>
          </Field>
          <Field
            label={"Local de saída do dado"}
            required
            validationState={validationlocalSaida.validationState}
            validationMessage={validationlocalSaida.validationMessage}
          >
            <Input
              type={"text"}
              value={localSaidaDado}
              onChange={(evento) => setLocalSaida(evento.target.value)}
              contentAfter={<ButtonGetDataBySelectWorkbook setarValor={setLocalSaida} tipoObtencao={"local"} />}
            ></Input>
          </Field>
        </>
      )}
      {!valorCalculado && (
        <>
          <Field
            label={"Timestamp da consulta"}
            required
            validationState={validationTimestamp.validationState}
            validationMessage={validationTimestamp.validationMessage}
          >
            <Input
              type={"datetime-local"}
              step="1"
              required
              value={timestamp}
              onChange={(evento) => setTimestamp(evento.target.value)}
            ></Input>
          </Field>
          <Field
            label={"Local de saída do dado"}
            required
            validationState={validationlocalSaida.validationState}
            validationMessage={validationlocalSaida.validationMessage}
          >
            <Input
              type={"text"}
              value={localSaidaDado}
              onChange={(evento) => setLocalSaida(evento.target.value)}
              contentAfter={<ButtonGetDataBySelectWorkbook setarValor={setLocalSaida} tipoObtencao={"local"} />}
            ></Input>
          </Field>
          <Field label={"Estratégia de retorno do dado"} required>
            <RadioGroup
              value={estrategia}
              required
              onChange={(_, data) =>
                selecionarEstrategia(data.value as "PERIODO_EXATO" | "INTERPOLADO" | "ULTIMO_VALOR")
              }
            >
              <Radio className="noOutline" value="PERIODO_EXATO" label="Período exato" />
              <Radio className="noOutline" value="INTERPOLADO" label="Interpolado" />
              <Radio className="noOutline" value="ULTIMO_VALOR" label="Último valor" />
            </RadioGroup>
          </Field>

          {(estrategia == "INTERPOLADO" || estrategia == "ULTIMO_VALOR") && (
            <Field
              label={"Range de tempo em horas"}
              required={estrategia == "INTERPOLADO" || estrategia == "ULTIMO_VALOR"}
            >
              <Input
                type={"number"}
                min={1}
                max={48}
                value={rangeEmHoras}
                onChange={(evento) => setRangeEmHoras(evento.target.value)}
                required
              ></Input>
            </Field>
          )}
        </>
      )}
      <Field>
        <Button type="submit" style={{ marginTop: "10px", width: "100%" }} appearance="primary">
          {carregando ? (
            <Spinner appearance="inverted" size="tiny" />
          ) : isNuloOuUndefined(editItem) ? (
            "Adicionar"
          ) : (
            "Editar"
          )}
        </Button>
      </Field>
    </form>
  );
}
