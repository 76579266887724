import * as React from "react";
import { Dialog, DialogSurface, DialogTitle, DialogBody, Spinner, Button } from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";

interface IProps {
  mensagem: string;
  open: boolean;
  mostrarCancelar: boolean;
  cancelar: () => void;
  subtitle: string;
}

export default function ModalCarregamento({ mensagem, open, mostrarCancelar, cancelar, subtitle }: IProps) {
  return (
    <Dialog open={open}>
      <DialogSurface as={"div"} style={{ maxWidth: "250px" }}>
        <DialogBody>
          <DialogTitle style={{ textAlign: "center" }}>
            <Spinner size="huge" labelPosition="below" label={mensagem} />
              <span style={{
                fontSize: 12
              }}>{subtitle}</span>
             {mostrarCancelar && (
              <Button
                    onClick={() => cancelar()}
                    type="button"
                    appearance="primary"
                    aria-label="close"
                    icon={<Dismiss24Regular />}
                    style={{
                      width: "100%",
                      margin: "auto",
                      marginTop: "20px"
                    }}
                  >
                    Cancelar
                </Button>)
              }
          </DialogTitle>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
}
