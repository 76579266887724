/* global Excel */

export async function addComentario(
  worksheetName: string,
  cellAddress: string,
  content: string,
  funcionalidade: string
) {
  await Excel.run(async (context) => {
    const sheet = context.workbook.worksheets.getItem(worksheetName);
    sheet.load("comments");
    await context.sync();
    sheet.comments.add(cellAddress, funcionalidade + ": " + content);
    await context.sync();
  });
}

export async function removeAllComentariosInWorksheetByFuncionalidade(worksheetName: string, funcionalidade: string) {
  await Excel.run(async (context) => {
    let sheet = context.workbook.worksheets.getItem(worksheetName);
    let comments = sheet.load("comments");
    await context.sync();
    comments.load("items");
    await context.sync();
    sheet.comments.items.forEach((value) => {
      if (value.content.startsWith(funcionalidade + ": ")) {
        value.delete();
      }
    });
    await context.sync();
  });
}
