import { DesktopTowerRegular, ServerRegular } from "@fluentui/react-icons";
import { Badge, Field, ProgressBar } from "@fluentui/react-components";
import * as React from "react";

export default function TesteConexao() {
  return (
    <Field validationMessage="Carregando..." validationState="none" style={{ textAlign: "center" }}>
      <div style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
        <Badge size="extra-large" icon={<DesktopTowerRegular />} />
        <ProgressBar thickness="large" />
        <Badge size="extra-large" icon={<ServerRegular />} />
      </div>
    </Field>
  );
}
