import { IResultadoMapeamento } from "../shared/interfaces/IResultadoMapeamento";
import {
  obterDadosPersistidos,
  verificarExistenciaDaTabelaDeExportacao,
  verificarExistenciaDoWorksheetsDeExportacao,
} from "./obterDadosPersistidos";
import { IItemTagAdressValorAtual } from "../pages/ValorAtual/interfaces/IItemTagAdressValorAtual";
import { IItemTagAdressValorHistorico } from "../pages/ValorHistorico/interfaces/IItemTagAdressValorHistorico";
import { get_url_api, mapeamentoValoresEndereco } from "./index";
import { IDadosConexaoHistorian } from "../shared/interfaces/dadosConexaoHistorian";
import axios from "axios";
import { ResponseValorAtual } from "../shared/interfaces/responses/responseValorAtual";
import { ResponseValorHistorico } from "../shared/interfaces/responses/responseValorHistorico";
import { IItemTagValorAtual, IItemTagValoresHistoricos, IItemTagValorHistorico } from "../shared/interfaces/ItemTag";
import { ResponseValoresHistoricos } from "../shared/interfaces/responses/responseValoresHistoricos";
import { IItemTagAdressValoresHistoricos } from "../pages/ValoresHistoricos/interfaces/IItemTagAdressValoresHistoricos";

export async function obterDadosPlanilha(
  funcionalidade: string,
  dadosConexaoHistorian: IDadosConexaoHistorian,
  nomeWorksheetPersistData: string,
  tableName: string,
  colunasDaTabela: string[],
  addressColunas: string[],
  validarDadosFuncionalidadeEMapearResultados: (
    dadosConexaoHistorian: IDadosConexaoHistorian,
    valores: (IItemTagAdressValorAtual | IItemTagAdressValorHistorico | IItemTagAdressValoresHistoricos)[],
    nomePlanilhaDePersistencia: string
  ) => Promise<IResultadoMapeamento>
): Promise<IResultadoMapeamento> {
  await verificarExistenciaDoWorksheetsDeExportacao(nomeWorksheetPersistData);
  await verificarExistenciaDaTabelaDeExportacao(tableName);
  const resultados: (IItemTagAdressValorAtual | IItemTagAdressValorHistorico | IItemTagAdressValoresHistoricos)[] =
    await obterDadosPersistidos<
      IItemTagAdressValorAtual | IItemTagAdressValorHistorico | IItemTagAdressValoresHistoricos
    >(funcionalidade, nomeWorksheetPersistData, tableName, colunasDaTabela, addressColunas, mapeamentoValoresEndereco);
  return await validarDadosFuncionalidadeEMapearResultados(dadosConexaoHistorian, resultados, nomeWorksheetPersistData);
}

export async function obterDadosHistorianFuncionalidade(
  urlApiObtencaoDados: string,
  dadosConexaoHistorian: IDadosConexaoHistorian,
  itensTag: (IItemTagValorAtual | IItemTagValorHistorico | IItemTagValoresHistoricos)[]
): Promise<(ResponseValorAtual | ResponseValorHistorico | ResponseValoresHistoricos)[]> {
  return await axios
    .post<(ResponseValorAtual | ResponseValorHistorico | ResponseValoresHistoricos)[]>(
      `${get_url_api()}/api/${urlApiObtencaoDados}`,
      {
        host: dadosConexaoHistorian.host,
        port: dadosConexaoHistorian.porta,
        itens: JSON.stringify(itensTag),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }
    )
    .then(async (r) => {
      let resultadosObtidos: (ResponseValorAtual | ResponseValorHistorico | ResponseValoresHistoricos)[] = r.data;
      return resultadosObtidos;
    });
}
