import { IItemTagValorAtual } from "../../../../shared/interfaces/ItemTag";
import { isNotNuloOuUndefined } from "../../../../utils";
import { Field, Input } from "@fluentui/react-components";
import * as React from "react";

interface IProps {
  item: IItemTagValorAtual | null;
}

export default function ListaFieldsValorAtual({ item }: IProps) {
  return (
    <>
      {isNotNuloOuUndefined(item) && (
        <>
          <Field label={"Local de saída do dado"}>
            <Input type={"text"} value={item.localSaidaDado} disabled={true}></Input>
          </Field>
          {item.exportarTimestamp && (
            <Field label={"Local de saída do timestamp do valor"}>
              <Input type={"text"} value={item.localSaidaTimestamp} disabled={true}></Input>
            </Field>
          )}
        </>
      )}
    </>
  );
}
