import { IValorApplyPlanilha } from "../../../shared/interfaces/IValorApplyPlanilha";
import { IDadosEnderecoPlanilha } from "../../../shared/interfaces/IDadosEnderecoPlanilha";
import { validarEnderecoPlanilha } from "../../../utils/ValidarEnderecoPlaninha";
import { balanceamentoDeCargaAplicarValorPlanilha } from "../../../utils";
import { ResponseValorHistorico } from "../../../shared/interfaces/responses/responseValorHistorico";

export async function aplicarValorHistorico(itens: ResponseValorHistorico[], getCancelar: () => boolean): Promise<string[]> {
  let itensMapeadosEndereco: IValorApplyPlanilha[] = [];
  let erros: string[] = [];
  itens.forEach((item) => {
    let endereco: IDadosEnderecoPlanilha = {
      endereco: "",
      planilha: "",
    };
    let erro: boolean = false;
    try {
      endereco = validarEnderecoPlanilha(item.localSaidaDado);
    } catch (e) {
      erro = true;
      erros.push(e.toString());
    }
    if (!erro) {
      itensMapeadosEndereco.push({
        valor: item.value,
        texto: false,
        dadosPlanilha: endereco,
      });
    }
  });
  await balanceamentoDeCargaAplicarValorPlanilha(itensMapeadosEndereco, erros, getCancelar);

  return erros;
}
