import {
  Card,
  Toolbar,
  Button,
  tokens,
  TableCellLayout,
  Table,
  TableHeader,
  TableSelectionCell,
  TableHeaderCell,
  TableRow,
  TableBody,
  TableCell,
  Tooltip,
  Caption1,
} from "@fluentui/react-components";
import * as React from "react";

import {
  AddCircleFilled,
  DismissCircleFilled,
  ArrowCircleUpFilled,
  ArrowCircleDownFilled,
  ClipboardTextEditFilled,
} from "@fluentui/react-icons";
import { useState } from "react";
import { isNotNuloOuUndefined, isNuloOuUndefined } from "../../../utils";
import PainelLateral from "../PainelLateral/PainelLateral";
import ModalConfirmacao from "../ModalConfirmacao/ModalConfirmacao";
import { IDadosConexaoHistorian } from "../../interfaces/dadosConexaoHistorian";

import { IItemTagValorAtual, IItemTagValorHistorico, IItemTagValoresHistoricos } from "../../interfaces/ItemTag";
import TagFormValorAtual from "../../../pages/ValorAtual/components/TagFormValorAtual/TagFormValorAtual";
import ListaFieldsValorAtual from "../../../pages/ValorAtual/components/ListaFieldsValorAtual/ListaFieldsValorAtual";
import ModalInformacao from "../ModalInformacao/ModalInformacao";
import { ListaFieldsValorHistorico } from "../../../pages/ValorHistorico/components/ListaFieldsValorHistorico/ListaFieldsValorHistorico";
import TagFormValorHistorico from "../../../pages/ValorHistorico/components/TagFormValorHistorico/TagFormValorHistorico";
import { ListaFieldsValoresHistoricos } from "../../../pages/ValoresHistoricos/componentes/ListaFieldsValoresHistoricos/ListaFieldsValoresHistoricos";
import TagFormValoresHistoricos from "../../../pages/ValoresHistoricos/componentes/TagFormValoresHistoricos/TagFormValoresHistoricos";

interface IProps {
  funcionalidade: string;
  itens: (IItemTagValorAtual | IItemTagValorHistorico | IItemTagValoresHistoricos)[];
  setItens: React.Dispatch<
    React.SetStateAction<(IItemTagValorAtual | IItemTagValorHistorico | IItemTagValoresHistoricos)[]>
  >;
  selected: IItemTagValorAtual | IItemTagValorHistorico | IItemTagValoresHistoricos | null;
  setSelected: React.Dispatch<
    React.SetStateAction<IItemTagValorAtual | IItemTagValorHistorico | IItemTagValoresHistoricos>
  >;
  dadosConexao: IDadosConexaoHistorian;
  addItem: (item: IItemTagValorAtual | IItemTagValorHistorico | IItemTagValoresHistoricos) => void;
  delItem: (item: IItemTagValorAtual | IItemTagValorHistorico | IItemTagValoresHistoricos) => Promise<void>;
  updateItem: (item: IItemTagValorAtual | IItemTagValorHistorico | IItemTagValoresHistoricos) => void;
  nomePlanilhaDePersistenciasDosDados: string;
}

export default function TagList({
  funcionalidade,
  itens,
  setItens,
  selected,
  setSelected,
  dadosConexao,
  addItem,
  delItem,
  updateItem,
  nomePlanilhaDePersistenciasDosDados,
}: IProps) {
  const [isOpenPainelAdd, setIsOpenPainelAdd] = useState(false);
  const [isOpenPainelEdit, setIsOpenPainelEdit] = useState(false);
  const [isOpenModalConfirmDeleteItem, setIsOpenModalConfirmDeleteItem] = useState(false);

  function addItemAndClosePanel(item: IItemTagValorAtual | IItemTagValorHistorico | IItemTagValoresHistoricos) {
    addItem(item);
    if (isNuloOuUndefined(selected)) {
      setSelected(item);
    }
    setIsOpenPainelAdd(false);
  }

  function updateItemAndClosePanel(item: IItemTagValorAtual | IItemTagValorHistorico | IItemTagValoresHistoricos) {
    updateItem(item);
    setIsOpenPainelEdit(false);
  }

  function selectItem(item: IItemTagValorAtual | IItemTagValorHistorico | IItemTagValoresHistoricos) {
    setSelected(item);
  }

  function moveUp(item: IItemTagValorAtual | IItemTagValorHistorico | IItemTagValoresHistoricos) {
    let arr = itens;
    let index = arr.findIndex((e) => e.id == item.id);
    if (index > 0) {
      let el = arr[index];
      arr[index] = arr[index - 1];
      arr[index - 1] = el;
      setItens([...arr]);
    }
  }

  function moveDown(item: IItemTagValorAtual | IItemTagValorHistorico | IItemTagValoresHistoricos) {
    let arr = itens;
    let index = arr.findIndex((e) => e.id == item.id);
    if (index !== -1 && index < arr.length - 1) {
      let el = arr[index];
      arr[index] = arr[index + 1];
      arr[index + 1] = el;
      setItens([...arr]);
    }
  }

  async function deletarItemEdeselect(item: IItemTagValorAtual | IItemTagValorHistorico | IItemTagValoresHistoricos) {
    await delItem(item);
    const tagsDisponiveis = itens.filter((valor) => valor.id != item.id);
    const index = itens.findIndex((value) => value.id == item.id);
    if (tagsDisponiveis.length > 0) {
      if (index != -1 && index - 1 >= 0) {
        selectItem(tagsDisponiveis[index - 1]);
      } else {
        selectItem(tagsDisponiveis[0]);
      }
    } else {
      selectItem(null);
    }
  }

  function mostrarRecursoIndisponivel() {
    return (
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <div>
              <ModalInformacao
                open={true}
                tipo="warning"
                tipoBoldPrefixoMensagem="Aviso: "
                mensagem="Recurso indisponível."
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Card
        appearance={"outline"}
        style={{ maxHeight: "300px", marginBottom: "20px", marginTop: "10px", width: "fit-content" }}
      >
        <Toolbar
          aria-label="Small"
          size="small"
          style={{
            marginLeft: "auto",
          }}
        >
          <Tooltip withArrow showDelay={1000} appearance={"inverted"} content="Adicionar tag" relationship="label">
            <Button
              size={"small"}
              aria-label="Adicionar"
              icon={<AddCircleFilled primaryFill={tokens.colorBrandBackground} />}
              onClick={() => setIsOpenPainelAdd(true)}
            />
          </Tooltip>
          <Tooltip withArrow showDelay={1000} appearance={"inverted"} content="Editar tag" relationship="label">
            <Button
              size={"small"}
              aria-label="Editar"
              disabled={isNuloOuUndefined(selected)}
              icon={<ClipboardTextEditFilled primaryFill={tokens.colorPaletteBlueForeground2} />}
              onClick={() => setIsOpenPainelEdit(true)}
            />
          </Tooltip>
          <Tooltip
            withArrow
            showDelay={1000}
            appearance={"inverted"}
            content="Remover tag selecionada"
            relationship="label"
          >
            <Button
              size={"small"}
              aria-label="Remover"
              disabled={isNuloOuUndefined(selected)}
              icon={<DismissCircleFilled primaryFill={tokens.colorPaletteRedForeground3} />}
              onClick={() => setIsOpenModalConfirmDeleteItem(true)}
            />
          </Tooltip>
          <Tooltip
            withArrow
            showDelay={1000}
            appearance={"inverted"}
            content="Mover tag selecionada para uma posição acima"
            relationship="label"
          >
            <Button
              disabled={isNuloOuUndefined(selected) || itens.length <= 1}
              size={"small"}
              aria-label="Subir"
              icon={<ArrowCircleUpFilled />}
              onClick={() => moveUp(selected)}
            />
          </Tooltip>
          <Tooltip
            withArrow
            showDelay={1000}
            appearance={"inverted"}
            content="Mover tag selecionada para uma posição abaixo"
            relationship="label"
          >
            <Button
              disabled={isNuloOuUndefined(selected) || itens.length <= 1}
              size={"small"}
              aria-label="Descer"
              icon={<ArrowCircleDownFilled />}
              onClick={() => moveDown(selected)}
            />
          </Tooltip>
        </Toolbar>
        <div style={{ overflow: "auto" }}>
          <Table
            as={"table"}
            size={"small"}
            style={{
              minWidth: "200px",
              maxHeight: "400px",
              alignContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <TableHeader>
              <TableRow>
                <TableSelectionCell type="radio" hidden />
                <TableHeaderCell>
                  <b>Tag</b>
                </TableHeaderCell>
              </TableRow>
            </TableHeader>
            <TableBody as={"tbody"}>
              {itens.length == 0 && (
                <TableRow key={-1} style={{ overflow: "auto" }}>
                  <TableCell colSpan={2}>
                    <TableCellLayout truncate={true}>
                      <p>Nenhuma tag adicionada</p>
                    </TableCellLayout>
                  </TableCell>
                </TableRow>
              )}
              {itens.map((item) => (
                <TableRow
                  key={item.id}
                  onClick={() => selectItem(item)}
                  aria-selected={isNotNuloOuUndefined(selected) && selected.id == item.id}
                  style={{ overflow: "auto" }}
                >
                  <TableSelectionCell
                    checked={isNotNuloOuUndefined(selected) && selected.id == item.id}
                    type="radio"
                    radioIndicator={{ "aria-label": "Select row" }}
                  />
                  <TableCell>
                    <TableCellLayout truncate={true}>{item.tag}</TableCellLayout>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>

        <p style={{ textAlign: "end", marginBottom: "0", marginTop: "0" }}>
          <Caption1>
            Quantidade de itens: <b>{itens.length}</b>
          </Caption1>
        </p>
      </Card>
      <PainelLateral
        titulo={"Adicionar tag"}
        isOpen={isOpenPainelAdd}
        setIsOpen={setIsOpenPainelAdd}
        content={(() => {
          switch (funcionalidade) {
            case "VALOR_ATUAL":
              return (
                <TagFormValorAtual
                  funcionalidade={funcionalidade}
                  itens={itens as IItemTagValorAtual[]}
                  dadosConexao={dadosConexao}
                  submitFunction={addItemAndClosePanel}
                  nomePlanilhaDePersistenciasDosDados={nomePlanilhaDePersistenciasDosDados}
                />
              );
            case "VALOR_HISTORICO":
              return (
                <TagFormValorHistorico
                  funcionalidade={funcionalidade}
                  itens={itens as IItemTagValorHistorico[]}
                  submitFunction={addItemAndClosePanel}
                  dadosConexao={dadosConexao}
                  nomePlanilhaDePersistenciasDosDados={nomePlanilhaDePersistenciasDosDados}
                />
              );
            case "VALORES_HISTORICOS":
              return (
                <TagFormValoresHistoricos
                  funcionalidade={funcionalidade}
                  itens={itens as IItemTagValoresHistoricos[]}
                  submitFunction={addItemAndClosePanel}
                  dadosConexao={dadosConexao}
                  nomePlanilhaDePersistenciasDosDados={nomePlanilhaDePersistenciasDosDados}
                />
              );
            default:
              return mostrarRecursoIndisponivel();
          }
        })()}
      />
      <PainelLateral
        titulo={"Editar tag"}
        isOpen={isOpenPainelEdit}
        setIsOpen={setIsOpenPainelEdit}
        content={(() => {
          switch (funcionalidade) {
            case "VALOR_ATUAL":
              return (
                <TagFormValorAtual
                  funcionalidade={funcionalidade}
                  itens={itens as IItemTagValorAtual[]}
                  editItem={selected as IItemTagValorAtual}
                  dadosConexao={dadosConexao}
                  submitFunction={updateItemAndClosePanel}
                  nomePlanilhaDePersistenciasDosDados={nomePlanilhaDePersistenciasDosDados}
                />
              );
            case "VALOR_HISTORICO":
              return (
                <TagFormValorHistorico
                  funcionalidade={funcionalidade}
                  itens={itens as IItemTagValorHistorico[]}
                  editItem={selected as IItemTagValorHistorico}
                  submitFunction={updateItemAndClosePanel}
                  dadosConexao={dadosConexao}
                  nomePlanilhaDePersistenciasDosDados={nomePlanilhaDePersistenciasDosDados}
                />
              );
            case "VALORES_HISTORICOS":
              return (
                <TagFormValoresHistoricos
                  funcionalidade={funcionalidade}
                  itens={itens as IItemTagValoresHistoricos[]}
                  editItem={selected as IItemTagValoresHistoricos}
                  submitFunction={updateItemAndClosePanel}
                  dadosConexao={dadosConexao}
                  nomePlanilhaDePersistenciasDosDados={nomePlanilhaDePersistenciasDosDados}
                />
              );
            default:
              return mostrarRecursoIndisponivel();
          }
        })()}
      />
      <ModalConfirmacao
        title={"Remover item"}
        contentMessage={"Deseja realmente remover o item selecionado?"}
        contentConfirmMessageButtom={"Sim, remova"}
        open={isOpenModalConfirmDeleteItem}
        setOpen={setIsOpenModalConfirmDeleteItem}
        confirmFunction={() => {
          deletarItemEdeselect(selected).then();
        }}
      />
      <div style={{ padding: "10px", overflow: "auto", maxHeight: "200px", marginBottom: "10px" }}>
        {(() => {
          switch (funcionalidade) {
            case "VALOR_ATUAL":
              return <ListaFieldsValorAtual item={selected as IItemTagValorAtual} />;
            case "VALOR_HISTORICO":
              return <ListaFieldsValorHistorico item={selected as IItemTagValorHistorico} />;
            case "VALORES_HISTORICOS":
              return <ListaFieldsValoresHistoricos item={selected as IItemTagValoresHistoricos} />;
            default:
              return mostrarRecursoIndisponivel();
          }
        })()}
      </div>
    </>
  );
}
