import { IDadosEnderecoPlanilha } from "../shared/interfaces/IDadosEnderecoPlanilha";

export function validarEnderecoPlanilha(endereco: string): IDadosEnderecoPlanilha {
  const re = new RegExp(/(.*)!([ A-Z]*[0-9]+)$/);
  let validRegex = re.test(endereco);
  if (!validRegex) {
    throw Error("O endereço não esta num formato válido");
  } else {
    const regexSplit = endereco.match(re);
    if (regexSplit.length == 3) {
      let planilha = regexSplit[1].replaceAll("'", "");
      let enderecoPlanilha = regexSplit[2];
      let regexEndereco = enderecoPlanilha.match(/[a-zA-Z]+|[0-9]+/g);
      if (regexEndereco.length == 2) {
        let coluna: string = regexEndereco[0] as string;
        let linha: string = regexEndereco[1];
        try {
          return {
            planilha: planilha,
            endereco: enderecoPlanilha,
            enderecoColuna: coluna,
            enderecoLinha: parseInt(linha),
          };
        } catch (e) {
          return { planilha: planilha, endereco: enderecoPlanilha };
        }
      } else {
        return { planilha: planilha, endereco: enderecoPlanilha };
      }
    } else {
      throw Error(`O endereço "${endereco}" é inválido`);
    }
  }
}
