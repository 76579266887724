import { ClipboardSearchFilled, DismissCircleFilled } from "@fluentui/react-icons";
import {
  Button,
  Link,
  Toast,
  Toaster,
  ToastTitle,
  ToastTrigger,
  Tooltip,
  useId,
  useToastController,
} from "@fluentui/react-components";
import React from "react";

/* global Excel  */

interface IProps {
  tipoObtencao?: "local" | "valor";
  setarValor: React.Dispatch<React.SetStateAction<string>>;
}

export default function ButtonGetDataBySelectWorkbook({ tipoObtencao = "valor", setarValor }: IProps) {
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);

  async function getSelect() {
    await Excel.run(async (context) => {
      let activeCell = context.workbook.getActiveCell();
      await context.sync();
      const loadedCell = activeCell.load(["address", "values"]);
      await context.sync();
      if (tipoObtencao == "local") {
        setarValor(loadedCell.address);
        return;
      } else {
        let valores: string[][] = loadedCell.values;
        if (valores.length > 0) {
          setarValor(valores[0][0]);
          return;
        }
      }
      setarValor("");
    }).catch((error) => {
      dispatchToast(
        <Toast>
          <ToastTitle
            action={
              <ToastTrigger>
                <Link>
                  <DismissCircleFilled />
                </Link>
              </ToastTrigger>
            }
          >
            {error.toString()}
          </ToastTitle>
        </Toast>,
        { intent: "error" }
      );
    });
  }

  return (
    <>
      <Toaster toasterId={toasterId} position={"bottom-end"} />
      <Tooltip
        withArrow
        showDelay={1000}
        appearance={"inverted"}
        content={tipoObtencao == "valor" ? "Obter valor da célula selecionada" : "Obter endereço da célula selecionada"}
        relationship="label"
      >
        <Button onClick={() => getSelect()} appearance="transparent" icon={<ClipboardSearchFilled />} size="small" />
      </Tooltip>
    </>
  );
}
