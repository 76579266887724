import { Button, Card, CardHeader, Field, ProgressBar, tokens } from "@fluentui/react-components";
import { ArrowSyncCircleFilled } from "@fluentui/react-icons";
import * as React from "react";
import { useEffect, useState } from "react";
import ListaFuncionalidade from "../ListaFuncionalidade/ListaFuncionalidade";
import { IFuncionalidadeProcessada } from "../../interfaces/IFuncionalidadeProcessada";
import { IDadosFuncionalidade } from "../../../../shared/interfaces/IDadosFuncionalidade";
import { obterDadosPlanilha } from "../../../../utils/planilha";
import { IDadosConexaoHistorian } from "../../../../shared/interfaces/dadosConexaoHistorian";
import { IResultadoMapeamento } from "../../../../shared/interfaces/IResultadoMapeamento";
import { isNotNuloOuUndefined } from "../../../../utils";
import { Dismiss24Regular } from "@fluentui/react-icons";
import {
  addComentario,
  removeAllComentariosInWorksheetByFuncionalidade,
} from "../../../../utils/adicionarComentarioExcel";
import { ResponseValorAtual } from "../../../../shared/interfaces/responses/responseValorAtual";
import { ResponseValorHistorico } from "../../../../shared/interfaces/responses/responseValorHistorico";
import {
  IItemTagValorAtual,
  IItemTagValoresHistoricos,
  IItemTagValorHistorico,
} from "../../../../shared/interfaces/ItemTag";
import { ResponseValoresHistoricos } from "../../../../shared/interfaces/responses/responseValoresHistoricos";
import { DadoTagChannel } from "../../../../utils/CanalDados";
let roomSocket: Map<string, DadoTagChannel> = new Map();

let cancelarRequisicao = false;

const setCancelarRequisicao = (value: boolean) => (cancelarRequisicao = value);
const getCancelarRequisicao = () => cancelarRequisicao;
let funcionalidadeProcessada: IFuncionalidadeProcessada[] = new Array<IFuncionalidadeProcessada>();
let contagemFuncionalidade = 0;
interface IProps {
  dadosConexaoHistorian: IDadosConexaoHistorian;
  dadosFuncionalidades: IDadosFuncionalidade[];
}

interface IProcessamento {
  processando: boolean;
  mensagem: string;
}
interface ICancelamento {
  processando: boolean;
  funcionalidade: string;
  nomeFuncionalidade: string;
}

export default function CardFuncionalidade({ dadosConexaoHistorian, dadosFuncionalidades }: IProps) {
  const [cancelar, setCancelar] = useState<ICancelamento>({
    processando: false,
    funcionalidade: "",
    nomeFuncionalidade: "",
  });
  const [processamento, setProcessamento] = useState<IProcessamento>({
    processando: false,
    mensagem: "",
  });

  useEffect(() => {
    const atualizar = async () => {
      await atualizarDadosPlanilha().then();
    };
    atualizar().then();
  }, []);

  async function addComentariosPlanilhaErros(
    erros: string[],
    dadosPresentaNaPlanilha: IResultadoMapeamento,
    nomeWorksheetPersistData: string,
    funcionalidade: string,
    nomeFuncionalidade: string
  ) {
    try {
      await removeAllComentariosInWorksheetByFuncionalidade(nomeWorksheetPersistData, funcionalidade);
    } catch (e) {
      erros.push(`Falha ao remover todos os comentários já existentes na funcionalidade '${nomeFuncionalidade}'`);
    }
    let errosAoComentar = 0;
    try {
      for (const value of dadosPresentaNaPlanilha.erros) {
        await addComentario(nomeWorksheetPersistData, value.address, value.errro, funcionalidade);
      }
    } catch (e) {
      errosAoComentar += 1;
    }
    if (errosAoComentar > 0) {
      erros.push(
        `Não foi possivel adicionar o cometário de '${errosAoComentar}' de erro da funcionalidade '${nomeFuncionalidade}'`
      );
    }
  }

  async function aplicarFuncionalidade(
    erros: string[],
    nomeFuncionalidade: string,
    itens: (ResponseValorAtual | ResponseValorHistorico | ResponseValoresHistoricos)[],
    valorApply: (
      item: (ResponseValorAtual | ResponseValorHistorico | ResponseValoresHistoricos)[],
      getCancelar: () => boolean
    ) => Promise<string[]>
  ) {
    setProcessamento({
      processando: true,
      mensagem: `Atualizando na planilha os valores referentes a funcionalidade '${nomeFuncionalidade}'`,
    });
    let errosAplicacaoValores = await valorApply(itens, getCancelarRequisicao);
    if (errosAplicacaoValores.length > 0) {
      errosAplicacaoValores.forEach((value) => erros.push(value));
    }
  }

  async function aplicarValoresNaPlanilha(
    erros: string[],
    nomeFuncionalidade: string,
    _: string,
    dadosConexaoHistorian: IDadosConexaoHistorian,
    itensTag: (IItemTagValorAtual | IItemTagValorHistorico | IItemTagValoresHistoricos)[],
    valorApply: (
      itens: (ResponseValorAtual | ResponseValorHistorico | ResponseValoresHistoricos)[],
      getCancelar: () => boolean
    ) => Promise<string[]>
  ) {
    let funcionalidade = nomeFuncionalidade.toLowerCase().replaceAll(" ", "_");

    if (contagemFuncionalidade === 0) {
      funcionalidadeProcessada = [];
      setCancelarRequisicao(false);
    }

    if (cancelarRequisicao) {
      return;
    }

    roomSocket[funcionalidade] = new DadoTagChannel({
      funcionalidade: funcionalidade,
      onopen: () => {
        contagemFuncionalidade += 1;
        if (cancelarRequisicao) {
          setCancelar({
            processando: false,
            funcionalidade: "",
            nomeFuncionalidade: "",
          });

          setProcessamento({
            processando: false,
            mensagem: ``,
          });

          return;
        }

        setCancelar({
          processando: true,
          funcionalidade: funcionalidade,
          nomeFuncionalidade: nomeFuncionalidade,
        });

        setProcessamento({
          processando: true,
          mensagem: `Obtendo os valores no Historian referentes a funcionalidade '${nomeFuncionalidade}'`,
        });

        roomSocket[funcionalidade].buscar({
          funcionalidade: funcionalidade,
          host: dadosConexaoHistorian.host,
          porta: dadosConexaoHistorian.porta,
          items: itensTag,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });
      },
      onerror: () => {
        setCancelarRequisicao(true);

        setCancelar({
          processando: false,
          funcionalidade: "",
          nomeFuncionalidade: "",
        });

        setProcessamento({
          processando: false,
          mensagem: ``,
        });

        erros.push(`Falha ao obter dados do Historian para a funcionalidade '${nomeFuncionalidade}'`);
        return;
      },
      oncancel: () => {
        setCancelar({
          processando: false,
          funcionalidade: "",
          nomeFuncionalidade: "",
        });
        setProcessamento({
          processando: false,
          mensagem: ``,
        });
        return;
      },

      onmessage: (message) => {
        if (cancelarRequisicao) {
          return;
        }

        let data = message.data;

        setTimeout(() => {
          aplicarFuncionalidade(erros, nomeFuncionalidade, data, valorApply).then(() => {
            if (cancelarRequisicao) {
              setProcessamento({
                processando: false,
                mensagem: ``,
              });
              return;
            }

            if (!message.hasData) {
              let idx = funcionalidadeProcessada.find((x) => x.funcionalidade == nomeFuncionalidade);
              if (!idx && !cancelarRequisicao) {
                funcionalidadeProcessada.push({
                  erros: erros,
                  funcionalidade: nomeFuncionalidade,
                });
              }
              if (funcionalidadeProcessada.length === contagemFuncionalidade) {
                setCancelar({
                  processando: false,
                  funcionalidade: "",
                  nomeFuncionalidade: "",
                });
                setProcessamento({
                  processando: false,
                  mensagem: ``,
                });
              }
            }
          });
        }, 500);
      },
    });
  }

  async function processarFuncionalidade(
    dadosFuncionalidade: IDadosFuncionalidade,
    dadosConexaoHistorian: IDadosConexaoHistorian,
    nomeFuncionalidade: string
  ): Promise<IFuncionalidadeProcessada> {
    let erros: string[] = [];
    let dadosPlanilha: IResultadoMapeamento = null;
    try {
      dadosPlanilha = await obterDadosPlanilha(
        dadosFuncionalidade.tabelaDados.funcionalidade,
        dadosConexaoHistorian,
        dadosFuncionalidade.tabelaDados.nomeWorksheetPersistData,
        dadosFuncionalidade.tabelaDados.tableExportName,
        dadosFuncionalidade.tabelaDados.colunas,
        dadosFuncionalidade.tabelaDados.addressColunas,
        dadosFuncionalidade.validarDadosFuncionalidadeEMapearResultados
      );
    } catch (e) {
      erros.push(
        "Falha ao obter dados da planilha da funcionalidade " + dadosFuncionalidade.tabelaDados.funcionalidade
      );
      return { erros: erros, funcionalidade: nomeFuncionalidade };
    }

    if (dadosPlanilha.erros.length > 0) {
      erros = [...erros, ...dadosPlanilha.erros.map((value) => value.errro)];
    }
    await addComentariosPlanilhaErros(
      erros,
      dadosPlanilha,
      dadosFuncionalidade.tabelaDados.nomeWorksheetPersistData,
      dadosFuncionalidade.tabelaDados.funcionalidade,
      nomeFuncionalidade
    );

    if (isNotNuloOuUndefined(dadosPlanilha) && dadosPlanilha.itensValidos.length > 0) {
      await aplicarValoresNaPlanilha(
        erros,
        nomeFuncionalidade,
        dadosFuncionalidade.tabelaDados.urlApiObtencaoDados,
        dadosConexaoHistorian,
        dadosPlanilha.itensValidos,
        dadosFuncionalidade.applyValorFuncionalidadePlanilha
      );
    }
    return { erros: erros, funcionalidade: nomeFuncionalidade };
  }

  function cancelarAtualizacao() {
    if (cancelarRequisicao) return;
    setCancelarRequisicao(true);
    roomSocket.forEach((channel) => channel.cancelar());
    setProcessamento({
      processando: false,
      mensagem: "",
    });
    setCancelar({
      processando: false,
      funcionalidade: "",
      nomeFuncionalidade: "",
    });

    funcionalidadeProcessada = [];

    let idx = funcionalidadeProcessada.find((x) => x.funcionalidade == cancelar.funcionalidade);

    if (!idx) {
      funcionalidadeProcessada.push({
        erros: ["Atualização cancelada"],
        funcionalidade: cancelar.nomeFuncionalidade,
      });
    }
  }

  async function atualizarDadosPlanilha() {
    contagemFuncionalidade = 0;
    setProcessamento({
      processando: true,
      mensagem: "Iniciando atualização",
    });
    for (const funcionalidade of dadosFuncionalidades) {
      let nomeFuncionalidade = funcionalidade.tabelaDados.funcionalidade.replaceAll("_", " ");
      await processarFuncionalidade(funcionalidade, dadosConexaoHistorian, nomeFuncionalidade);
    }

    if (contagemFuncionalidade === funcionalidadeProcessada.length) {
      setProcessamento({
        processando: false,
        mensagem: "",
      });
    }
  }

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        display: "flex",
        overflow: "auto",
      }}
    >
      <Card style={{ margin: "auto", width: "90%" }}>
        <CardHeader
          header={
            <ArrowSyncCircleFilled
              fontSize={"4em"}
              style={{
                display: "flex",
                margin: "0 auto",
                color: tokens.colorBrandBackground,
                overflow: "auto",
              }}
            />
          }
        />
        <div style={{ maxHeight: "250px", overflow: "auto" }}>
          <ListaFuncionalidade itens={funcionalidadeProcessada} />
        </div>
        <div>
          {processamento.processando ? (
            <div>
              <Field validationMessage={processamento.mensagem} validationState="none">
                <ProgressBar thickness="large" />
              </Field>
              {cancelar.processando && (
                <Button
                  onClick={() => cancelarAtualizacao()}
                  type="button"
                  appearance="primary"
                  aria-label="close"
                  icon={<Dismiss24Regular />}
                  style={{
                    width: "100%",
                    margin: "auto",
                    marginTop: "20px",
                  }}
                >
                  Cancelar
                </Button>
              )}
            </div>
          ) : (
            <div>
              <Button
                onClick={() => atualizarDadosPlanilha()}
                type="button"
                appearance="primary"
                style={{
                  width: "100%",
                }}
                icon={<ArrowSyncCircleFilled />}
              >
                Atualizar dados da planilha
              </Button>
            </div>
          )}
        </div>
      </Card>
    </div>
  );
}
