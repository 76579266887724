import * as React from "react";
import { useLayoutEffect, useState } from "react";
import { isNuloOuUndefined, obterDadosDeConexaoHistorian } from "../../../utils";
import ModalInformacao from "../ModalInformacao/ModalInformacao";
import { IDadosConexaoHistorian } from "../../interfaces/dadosConexaoHistorian";

/* global window */

interface IProps {
  content?: React.ReactNode;
  setDadosConexaoHistorianOutput: React.Dispatch<React.SetStateAction<IDadosConexaoHistorian>>;
}

export default function BaseComponent({ content, setDadosConexaoHistorianOutput }: IProps) {
  const [dadosConexaoHistorian, setDadosConexaoHistorian] = useState<null | IDadosConexaoHistorian>(null);

  useLayoutEffect(() => {
    obterDadosDeConexaoHistorian(setDadosConexaoHistorian);
    obterDadosDeConexaoHistorian(setDadosConexaoHistorianOutput);
    window.onstorage = () => {
      obterDadosDeConexaoHistorian(setDadosConexaoHistorian);
      obterDadosDeConexaoHistorian(setDadosConexaoHistorianOutput);
    };
  }, []);

  return (
    <div style={{ height: "100%", width: "100%" }}>
      {isNuloOuUndefined(dadosConexaoHistorian) ? (
        <ModalInformacao
          open={isNuloOuUndefined(dadosConexaoHistorian)}
          tipo="warning"
          tipoBoldPrefixoMensagem="Aviso: "
          mensagem="Sem configurações de conexão definidas. Adicione as configurações de conexão para utilizar esse recurso."
        />
      ) : (
        content
      )}
    </div>
  );
}
