import { IItemTagAdressValorAtual } from "../../ValorAtual/interfaces/IItemTagAdressValorAtual";
import { IItemTagValorAtual } from "../../../shared/interfaces/ItemTag";
import { ErroAddres } from "../../../shared/interfaces/IErroAddres";
import { IDadosConexaoHistorian } from "../../../shared/interfaces/dadosConexaoHistorian";
import {
  checkTagsExist,
  IResultadoTagsExistem,
  obterEnderecosDuplicados,
  verificarOcampoDeEndereco,
  verificarOcampoDeEnderecoTimestamp,
  verificarOcampoDeTag,
} from "../../../utils/ValidacoesImportacaoDeDados";
import { IResultadoMapeamento } from "../../../shared/interfaces/IResultadoMapeamento";
import { isNotNuloOuUndefined, obterDadosValidosEInvalidosESeusErros } from "../../../utils";

export async function valorAtualValidarDadosEMapearResultados(
  dadosConexaoHistorian: IDadosConexaoHistorian,
  valores: IItemTagAdressValorAtual[],
  nomePlanilhaDePersistencia: string
): Promise<IResultadoMapeamento> {
  let itens: IItemTagValorAtual[] = [];
  let erros: ErroAddres[] = [];
  let enderecos: string[] = valores.map((value) => value.localSaidaDado.valor);
  let enderecosTimestamps: string[] = valores
    .map((value) => value.localSaidaTimestamp.valor)
    .filter((value) => value != "");
  let tags: string[] = valores.map((value) => value.tag.valor);
  let resultadoCheckTagsExist: IResultadoTagsExistem = await checkTagsExist(dadosConexaoHistorian, tags);
  let enderecosDuplicados: string[] = obterEnderecosDuplicados(enderecos);
  let enderecosDuplicadosTimestamps: string[] = obterEnderecosDuplicados(enderecosTimestamps);

  for (const valor of valores) {
    itens.push({
      id: valor.id,
      tag: valor.tag.valor,
      localSaidaDado: valor.localSaidaDado.valor,
      funcionalidade: valor.funcionalidade,
      exportarTimestamp: isNotNuloOuUndefined(valor.localSaidaTimestamp.valor) && valor.localSaidaTimestamp.valor != "",
      localSaidaTimestamp: valor.localSaidaTimestamp.valor,
    });
    verificarOcampoDeTag(
      valor.tag.valor,
      erros,
      valor.id,
      valor.tag.address,
      "tag",
      resultadoCheckTagsExist.errosAoVerificarTagsExistem,
      resultadoCheckTagsExist.tagsDisponiveis
    );
    await verificarOcampoDeEndereco(
      valor.localSaidaDado.valor,
      erros,
      valor.id,
      valor.localSaidaDado.address,
      "localSaidaDado",
      enderecosDuplicados,
      nomePlanilhaDePersistencia
    );
    await verificarOcampoDeEnderecoTimestamp(
      valor.localSaidaTimestamp.valor,
      erros,
      valor.id,
      valor.localSaidaTimestamp.address,
      "localSaidaTimestamp",
      Array.from(new Set(enderecos)),
      enderecosDuplicadosTimestamps,
      nomePlanilhaDePersistencia
    );
  }
  return obterDadosValidosEInvalidosESeusErros(itens, erros);
}
